import React, { useEffect, useState, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Alert,
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import DeleteModal from "components/Common/DeleteModal"
import { useSelector, useDispatch } from "react-redux"
import { toast, ToastContainer } from "react-toastify"
import moment from "moment"
import TableContainer from "components/Common/helper/TableContainer"
import {
  BulkDeleteDbIntegrationAPI,
  deleteDbIntegrationAPI,
  fetchAllDbIntegrationList,
  GetDbIntegrationIdAPI,
} from "store/actions/DBIntegrationActions"
import BulkDeleteModal from "components/Common/BulkDeleteModal"
import { setEditDbIntegration } from "store/Slices/DbIntegrationSlice"
import {
  DeleteAgent,
  FetchAllAgentsCreation,
} from "store/actions/AgentCreationActions"
import NoPermission from "components/Common/NoPermission"

const AgentCreation = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const Permission = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission =
    Permission?.permissions[0]?.menu_item?.TestLab?.Testautomation

  const [data, setData] = useState([])

  const handleCreateClick = () => {
    if (rolePermission.create) {
      navigate("/TestAutomation/CreateAgent")
    } else {
      toast.warn("You don't have the permission to create agent", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }
  const [bulkDeleteModal, setBulkDeleteModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)
  const [isDeleteId, setIsDeleteId] = useState("")

  // const handleSingleEditClick = async id => {
  //   try {
  //     const res = await dispatch(GetDbIntegrationIdAPI(id))
  //     if (res) {
  //       dispatch(setEditDbIntegration({ isEdit: true, data: res }))
  //       navigate("/TestAutomation/DbIntegration/EditDbIntegration")
  //     } else {
  //       console.error("Empty or invalid response:", res)
  //     }
  //   } catch (error) {
  //     console.error("Error fetching data", error)
  //   }
  // }

  const onClickDelete = data => {
    if (rolePermission.delete) {
      setIsDeleteId(data?.id)
      setDeleteModal(true)
    } else {
      toast.warn("You don't have the permission to delete agents", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }
  const [selectedRows, setSelectedRows] = useState([])
  // const handleCheckboxChange = id => {
  //   setSelectedRows(prevValues =>
  //     prevValues.includes(id)
  //       ? prevValues.filter(val => val !== id)
  //       : [...prevValues, id]
  //   )
  // }
  // const handleSelectAll = e => {
  //   const isChecked = e.target.checked
  //   if (isChecked) {
  //     const newSelectedRows = data.map(item => item.id)
  //     setSelectedRows(newSelectedRows)
  //   } else {
  //     setSelectedRows([])
  //   }
  // }
  const renderIcon = value => {
    switch (value) {
      case "free":
        return <i className="fas fa-check-circle text-success font-size-17"></i>
      case "busy":
        return <i className="fas fa-clock text-warning font-size-17"></i>
      case "undefined":
        return (
          <i className="fas fa-minus-circle text-secondary font-size-17"></i>
        )
      case "offline":
        return <i className="bx bxs-x-circle text-danger font-size-20"></i>
      default:
        return "-"
    }
  }
  const columns = useMemo(
    () => [
      // {
      //   header: (
      //     <input
      //       type="checkbox"
      //       onChange={handleSelectAll}
      //       style={{ cursor: "pointer" }}
      //       title="Select All"
      //       checked={
      //         Array.isArray(selectedRows) &&
      //         selectedRows.length === data?.length &&
      //         data?.length > 0
      //       }
      //     />
      //   ),
      //   accessorKey: "checkbox",
      //   cell: cellProps => {
      //     const jobData = cellProps.row.original.id
      //     const isChecked = () => {
      //       if (!Array.isArray(selectedRows)) {
      //         console.error("selectedRows is not an array")
      //         return false
      //       }
      //       return selectedRows.includes(jobData)
      //     }

      //     const handleCheckboxClick = () => {
      //       try {
      //         if (!Array.isArray(selectedRows)) {
      //           throw new Error("selectedRows is not an array")
      //         }
      //         handleCheckboxChange(jobData)
      //       } catch (error) {
      //         console.error(error.message)
      //       }
      //     }

      //     return (
      //       <input
      //         type="checkbox"
      //         style={{ cursor: "pointer" }}
      //         checked={isChecked()}
      //         onChange={handleCheckboxClick}
      //         onClick={handleCheckboxClick}
      //       />
      //     )
      //   },
      //   enableColumnFilter: false,
      //   enableSorting: false,
      // },
      {
        header: "Tool",
        accessorKey: "tool_name",
        cell: cell => <div>{cell.getValue()}</div>,
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return (
            <p
              className="font-size-14 mb-1"
              style={{
                wordWrap: "break-word",
                overflowWrap: "break-word",
                whiteSpace: "normal",
                maxWidth: "200px", // Set the max-width to control the wrapping
              }}
            >
              {cell.getValue()}
            </p>
          )
        },
      },
      {
        header: "Agent Name",
        accessorKey: "agent_name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const value = cell.getValue()
          return (
            <div
              style={{
                wordWrap: "break-word",
                overflowWrap: "break-word",
                whiteSpace: "normal",
                maxWidth: "200px",
              }}
            >
              {value ? value : "-"}
            </div>
          )
        },
      },
      {
        header: "Agent Status",
        accessorKey: "agent_status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const value = cell.getValue()
          return <div title={value}>{renderIcon(value)}</div>
        },
      },
      {
        header: "Created On",
        accessorKey: "created_at",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return <>{moment(cell.getValue()).format("DD-MM-YYYY")}</>
        },
      },
      {
        header: "Created By",
        accessorKey: "created_by_name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const value = cell.getValue()
          return <>{value?.username ? value?.username : "-"}</>
        },
      },

      {
        header: "Action",
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li>
                <span
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const userData = cellProps.row.original
                    onClickDelete(userData)
                  }}
                  id={`deletetooltip-${cellProps.row.original.id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`deletetooltip-${cellProps.row.original.id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </span>
              </li>
            </ul>
          )
        },
      },
    ],
    [data, selectedRows]
  )

  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [globalFilter, setGlobalFilter] = useState("")
  const [totalCount, setTotalCount] = useState(null)

  const fetchTableData = async (page, size, search) => {
    let bodyoption = {
      page: page + 1,
      page_size: size,
      search: search,
      project_id: globalProject?.value || selectedProject?.value,
    }
    try {
      const data = await dispatch(FetchAllAgentsCreation(bodyoption))
      setData(data.Data)

      setTotalCount(data.TotalCount)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  useEffect(() => {
    fetchTableData(pageIndex, pageSize, globalFilter)
  }, [pageIndex, pageSize, globalFilter, globalProject?.value])

  const handleDelete = async () => {
    try {
      await dispatch(DeleteAgent(isDeleteId))
      setDeleteModal(false)
      await fetchTableData(pageIndex, pageSize, globalFilter)
      setIsDeleteId("")
    } catch (error) {
      console.error("Error deleting Db Integration:", error)
    }
  }
  // const handleBulkDelete = async () => {
  //   const bodyoption = {
  //     project_id: globalProject?.value || selectedProject?.value,
  //     pkid_list: selectedRows,
  //   }
  //   try {
  //     await dispatch(BulkDeleteDbIntegrationAPI(bodyoption))
  //     setSelectedRows([])
  //     setBulkDeleteModal(false)
  //     await fetchTableData(pageIndex, pageSize, globalFilter)
  //   } catch (error) {
  //     console.error("Error deleting Db Integration:", error)
  //   }
  // }
  const [visible, setVisible] = useState(true)

  const handleDeleteClick = async () => {
    try {
      if (selectedRows?.length > 0) {
        setBulkDeleteModal(true)
      } else {
        toast.warn(
          "Please select a db Integrations before attempting to bulk delete.",
          {
            position: "top-right",
            id: "selectBeforeDelete",
            autoClose: 3000,
          }
        )
      }
    } catch (error) {
      console.error("Error deleting Db Integration:", error)
    }
  }
  return (
    <React.Fragment>
      {rolePermission.view ? (
        <>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDelete}
            onCloseClick={() => setDeleteModal(false)}
            message="Agent"
          />
          {/* <BulkDeleteModal
        show={bulkDeleteModal}
        onDeleteClick={handleBulkDelete}
        onCloseClick={() => {
          setBulkDeleteModal(false)
          setSelectedRows([])
        }}
        message="Db Integrations"
      /> */}

          <Container fluid>
            <Row>
              <Col lg="12">
                <Card
                  style={{
                    borderTopLeftRadius: "0px",
                    borderTopRightRadius: "0px",
                  }}
                >
                  <CardBody className="pt-2">
                    <Alert
                      color="info"
                      role="alert"
                      className="d-flex align-items-center"
                      toggle={() => setVisible(false)}
                      isOpen={visible} // You need to manage this state
                    >
                      <strong className="me-2">Agent Status:</strong>
                      {/* Available (Success - Green) */}
                      <span
                        style={{
                          backgroundColor: "#28a745", // Green for success
                          display: "inline-block",
                          width: "12px",
                          height: "12px",
                          borderRadius: "50%",
                          marginRight: "5px",
                        }}
                      ></span>{" "}
                      Available,
                      {/* Offline (Danger - Red) */}
                      <span
                        style={{
                          backgroundColor: "#dc3545", // Red for danger
                          display: "inline-block",
                          width: "12px",
                          height: "12px",
                          borderRadius: "50%",
                          margin: "0 5px",
                        }}
                      ></span>{" "}
                      Offline,
                      {/* Busy (Warning - Yellow) */}
                      <span
                        style={{
                          backgroundColor: "#ffc107", // Yellow for warning
                          display: "inline-block",
                          width: "12px",
                          height: "12px",
                          borderRadius: "50%",
                          margin: "0 5px",
                        }}
                      ></span>{" "}
                      Busy,
                      {/* Undefined (Secondary - Grey) */}
                      <span
                        style={{
                          backgroundColor: "#6c757d", // Grey for secondary
                          display: "inline-block",
                          width: "12px",
                          height: "12px",
                          borderRadius: "50%",
                          marginLeft: "5px",
                          marginRight: "5px",
                        }}
                      ></span>{" "}
                      Undefined.
                    </Alert>

                    <div className="table-container">
                      <TableContainer
                        columns={columns}
                        data={data}
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        totalCount={totalCount}
                        setPageIndex={setPageIndex}
                        setPageSize={setPageSize}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        isGlobalFilter={true}
                        isPagination={true}
                        SearchPlaceholder="Search..."
                        isCustomPageSize={true}
                        isAddButton={true}
                        isAddDelete={false}
                        handleAddClick={handleCreateClick}
                        handleDeleteClick={handleDeleteClick}
                        buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal ms-2 mb-2"
                        buttonName="Create"
                        tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                        theadClass="table-light"
                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                        pagination="pagination"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>

          <ToastContainer />
        </>
      ) : (
        <NoPermission />
      )}
    </React.Fragment>
  )
}

export default withRouter(AgentCreation)
