import { toast } from "react-toastify"
import BASE_URL from "./apiConfig"
import { getToken } from "helpers/helper"

const GetAPI = async (url, headersObj = {}) => {
  url = `${BASE_URL}/api/${url}`

  // Ensure token is not null or undefined
  let tokenid = getToken()

  // Add Authorization header if token exists
  const headers = tokenid
    ? { ...headersObj, Authorization: `Bearer ${tokenid}` }
    : headersObj

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: headers,
    })

    if (response.status === 401) {
      toast.error("Your session has expired. Please log in again.")
      window.location.href = "/logout"
      return
    }

    if (!response.ok) {
      const errorData = await response.json()

      // Add handling for nested error structure
      const ErrorMessage =
        errorData?.messages?.[0]?.message ||
        errorData?.detail ||
        errorData?.error ||
        errorData.message ||
        "An unknown error occurred"

      // Throw error with nested message
      throw new Error(ErrorMessage)
    }
    return await response.json()
  } catch (error) {
    // Handle error appropriately with fallback to nested message
    const errorMessage =
      error.message || error.messages[0].message || "An unknown error occurred"
    toast.error(errorMessage)
    return { error: errorMessage }
  }
}

export default { GetAPI }
