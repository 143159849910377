import { call, put, takeEvery, takeLatest } from "redux-saga/effects"
import httpInjectorService from "services/http-Injector.Service"

// Login Redux States
import {
  AD_LOGIN_USER,
  LOGIN_USER,
  LOGOUT_USER,
  SOCIAL_LOGIN,
} from "./actionTypes"
import {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  setLoading,
} from "./actions"

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import { toast } from "react-toastify"
import CryptoJS from "crypto-js"
const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {
    // Dispatch setLoading(true) before API call
    yield put(setLoading(true))

    const response = yield call(httpInjectorService.login, user)
    yield put(loginSuccess(response))
    console.log(response)
    if (response && response.status) {
      const encryptedAccessToken = CryptoJS.AES.encrypt(
        response.access_token,
        process.env.REACT_APP_ENCRYPTION_KEY
      ).toString()
      const encryptedRefreshToken = CryptoJS.AES.encrypt(
        response.refresh_token,
        process.env.REACT_APP_ENCRYPTION_KEY
      ).toString()

      // Store encrypted tokens in localStorage
      localStorage.setItem(
        "authUser",
        JSON.stringify({
          ...response,
          access_token: encryptedAccessToken,
          refresh_token: encryptedRefreshToken,
        })
      )
      localStorage.setItem("sessionExpired", "false")
      history("/dashboard")
      yield put(loginSuccess(response))
      toast.success("Login successful!", { autoClose: 3000 })
    } else {
      const errorMessage =
        response.errors?.non_field_errors?.[0] ||
        response.error ||
        response.password[0] ||
        "Login failed. Please try again."
      toast.error(errorMessage, { autoClose: 3000 })
    }
    yield put(setLoading(false))
  } catch (error) {
    const errorMessage =
      error.errors?.non_field_errors?.[0] ||
      error.message ||
      response.password[0] ||
      "Login failed. Please try again."
    toast.error(errorMessage, { autoClose: 3000 })

    yield put(apiError(error))
  } finally {
    // Dispatch setLoading(false) after the response is received
    yield put(setLoading(false))
  }
}

function* adloginUser({ payload: { user, history } }) {
  try {
    // Dispatch setLoading(true) before API call
    yield put(setLoading(true))

    const response = yield call(httpInjectorService.login, user)
    yield put(loginSuccess(response))
    if (response && response.status) {
      const encryptedAccessToken = CryptoJS.AES.encrypt(
        response.access_token,
        process.env.REACT_APP_ENCRYPTION_KEY
      ).toString()
      const encryptedRefreshToken = CryptoJS.AES.encrypt(
        response.refresh_token,
        process.env.REACT_APP_ENCRYPTION_KEY
      ).toString()

      // Store encrypted tokens in localStorage
      localStorage.setItem(
        "authUser",
        JSON.stringify({
          ...response,
          access_token: encryptedAccessToken,
          refresh_token: encryptedRefreshToken,
        })
      )
      localStorage.setItem("sessionExpired", "false")
      history("/dashboard")
      toast.success("Login successful!", { autoClose: 3000 })
      yield put(loginSuccess(response))
    } else {
      const errorMessage =
        response.errors?.non_field_errors?.[0] ||
        response.error ||
        response.password[0] ||
        "Login failed. Please try again."
      toast.error(errorMessage, { autoClose: 3000 })
      yield put(setLoading(false))
    }
    yield put(setLoading(false))
  } catch (error) {
    const errorMessage =
      error.errors?.non_field_errors?.[0] ||
      error.message ||
      "Login failed. Please try again."
    toast.error(errorMessage, { autoClose: 3000 })

    yield put(apiError(error))
  } finally {
    // Dispatch setLoading(false) after the response is received
    yield put(setLoading(false))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { type, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(fireBaseBackend.socialLoginUser, type)
      if (response) {
        history("/dashboard")
      } else {
        history("/login")
      }
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    if (response) history("/dashboard")
  } catch (error) {
    console.log("error", error)
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(AD_LOGIN_USER, adloginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
