import React, { useEffect, useMemo, useState } from "react"
import { UncontrolledTooltip } from "reactstrap"
import { Link, useNavigate } from "react-router-dom"
import { useDispatch } from "react-redux"
import TableContainer from "components/Common/TableContainer"
import {
  BulkDeleteModule,
  deleteModuleAPI,
  fetchModuleDetails,
  setProjEditModuleData,
} from "store/actions/ModuleActions"
import DeleteModal from "components/Common/DeleteModal"
import { toast } from "react-toastify"
import moment from "moment"
import NoPermission from "components/Common/NoPermission"

const ProjectModule = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [deleteModal, setDeleteModal] = useState(false)
  const [bulkdeleteModal, setBulkDeleteModal] = useState(false)
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [totalCount, setTotalCount] = useState(null)
  const [globalFilter, setGlobalFilter] = useState("")
  const [moduleData, setmoduleData] = useState("")
  const currentUrl = window.location.href
  const parts = currentUrl.split("/")
  const id = parts[parts.length - 1]
  const [selectedRows, setSelectedRows] = useState([])
  const [moduleID, setModuleTd] = useState()

  const permission = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission =
    permission?.permissions?.[0]?.menu_item?.ProjectManagement

  useEffect(() => {
    fetchModuleData(pageIndex, pageSize, globalFilter)
  }, [pageIndex, pageSize, globalFilter])

  const fetchModuleData = async (page, size, search) => {
    let bodyoption = {
      page: page + 1,
      page_size: size,
      project_id: id,
      search: search,
      _timestamp: new Date().getTime(),
    }
    try {
      const data = await dispatch(fetchModuleDetails(bodyoption))
      setmoduleData(data.Data)
      setTotalCount(data.TotalCount)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  // Handle checkbox change for individual rows
  const handleCheckboxChange = module_id => {
    console.log(module_id)
    setSelectedRows(prevValues =>
      prevValues.includes(module_id)
        ? prevValues.filter(val => val !== module_id)
        : [...prevValues, module_id]
    )
  }

  // Handle select/deselect all checkboxes
  const handleSelectAll = e => {
    const isChecked = e.target.checked
    if (isChecked) {
      const newSelectedRows = moduleData.map(item => item.module_id)
      setSelectedRows(newSelectedRows)
    } else {
      setSelectedRows([])
    }
  }

  const onClickEdit = module => {
    if (rolePermission?.module?.edit) {
      dispatch(setProjEditModuleData(module))
      navigate(`/ProjectManagement/Edit-Module/${id}`, {
        state: { data: module },
      })
    } else {
      toast.warn("You don't have the permission to edit module", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const onClickDelete = users => {
    if (rolePermission?.module?.delete) {
      setModuleTd(users.id)
      setDeleteModal(true)
    } else {
      toast.warn("You don't have the permission to delete module", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const handleDeleteUser = () => {
    dispatch(deleteModuleAPI(moduleID))
      .then(() => {
        fetchModuleData(pageIndex, pageSize, globalFilter)
      })
      .catch(error => {
        console.error("Failed to delete module:", error)
      })
    setDeleteModal(false)
  }

  const handleBulkDelete = async () => {
    const bodyoption = {
      project_id: id,
      module_ids: selectedRows,
    }
    try {
      dispatch(BulkDeleteModule(bodyoption))
      setSelectedRows([])
      setBulkDeleteModal(false)
      setTimeout(() => {
        fetchModuleData(pageIndex, pageSize, globalFilter)
      }, 1000)
    } catch (error) {
      console.error("Error deleting Requirement:", error)
    }
  }

  const handleDeleteClick = async () => {
    if (rolePermission?.module?.delete) {
      try {
        if (selectedRows?.length > 0) {
          setBulkDeleteModal(true)
        } else {
          toast.warn(
            "Please select a project module before attempting to delete.",
            {
              position: "top-right",
              id: "selectBeforeDelete",
              autoClose: 3000,
            }
          )
        }
      } catch (error) {
        console.error("Error deleting project module:", error)
      }
    } else {
      toast.warn("You don't have the permission to delete module's", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const handleAddClick = () => {
    if (rolePermission?.module?.create) {
      navigate(`/ProjectManagement/Create-Module/${id}`)
    } else {
      toast.warn("You don't have the permission to create module", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const columns = useMemo(
    () => [
      {
        header: (
          <input
            type="checkbox"
            onChange={handleSelectAll}
            style={{ cursor: "pointer" }}
            title="Select All"
            checked={
              selectedRows.length === moduleData?.length &&
              moduleData?.length > 0
            }
          />
        ),
        accessorKey: "checkbox",
        cell: cellProps => (
          <input
            type="checkbox"
            style={{ cursor: "pointer" }}
            checked={selectedRows.includes(cellProps.row.original.module_id)}
            onChange={() => {
              const jobData = cellProps.row.original.module_id
              handleCheckboxChange(jobData)
            }}
            onClick={() => {
              const jobData = cellProps.row.original.module_id
              handleCheckboxChange(jobData)
            }}
          />
        ),
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Module Id",
        accessorKey: "module_id",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: "Module Title",
        accessorKey: "module_name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const module_name = cell.getValue()
          return (
            <>
              <p
                className="font-size-14 mb-1"
                style={{
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  whiteSpace: "normal",
                  maxWidth: "480px", // Set the max-width to control the wrapping
                }}
              >
                {module_name ? module_name : "-"}
              </p>
            </>
          )
        },
      },
      {
        header: "Build",
        accessorKey: "build",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const build = cell.getValue()
          return <>{build ? build : "-"}</>
        },
      },
      {
        header: "Created Date",
        accessorKey: "created_at",
        enableColumnFilter: false,
        enableSorting: true,
        cell: ({ getValue }) => {
          const value = getValue()
          return value ? moment(value).format("DD-MM-YYYY") : "-"
        },
      },
      {
        header: "Action",
        enableColumnFilter: false,
        enableSorting: false,
        cell: cellProps => (
          <ul className="list-unstyled hstack gap-1 mb-0">
            <li>
              <span
                className="btn btn-sm btn-soft-info"
                onClick={() => {
                  const jobData = cellProps.row.original
                  onClickEdit(jobData)
                }}
                id={"edittooltip"}
              >
                <i className="mdi mdi-pencil-outline" />
                <UncontrolledTooltip placement="top" target={"edittooltip"}>
                  Edit
                </UncontrolledTooltip>
              </span>
            </li>

            <li>
              <Link
                to="#"
                className="btn btn-sm btn-soft-danger"
                onClick={() => {
                  const jobData = cellProps.row.original
                  onClickDelete(jobData)
                }}
                id={"deletetooltip"}
              >
                <i className="mdi mdi-delete-outline" />
                <UncontrolledTooltip placement="top" target={"deletetooltip"}>
                  Delete
                </UncontrolledTooltip>
              </Link>
            </li>
          </ul>
        ),
      },
    ],
    [selectedRows, moduleData]
  )

  const onCancelBulkDelete = () => {
    setSelectedRows([])
    setBulkDeleteModal(false)
  }

  return (
    <React.Fragment>
      {rolePermission?.module?.view ? (
        <>
          <TableContainer
            columns={columns}
            data={moduleData || []}
            pageIndex={pageIndex}
            pageSize={pageSize}
            totalCount={totalCount}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            isCustomPageSize={true}
            isGlobalFilter={true}
            isJobListGlobalFilter={false}
            globalFilter={globalFilter}
            isPagination={true}
            isAddDelete={true}
            isAddEdit={false}
            isAddFilter={false}
            isAddButton={true}
            setGlobalFilter={setGlobalFilter}
            handleDeleteClick={handleDeleteClick}
            handleAddClick={handleAddClick}
            // handleEditClick={handleEditClick}
            SearchPlaceholder="Search..."
            tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
            theadClass="table-light"
            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
            pagination="pagination"
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
          />

          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDeleteUser}
            onCloseClick={() => setDeleteModal(false)}
            message={"Module"}
          />

          <DeleteModal
            show={bulkdeleteModal}
            onDeleteClick={handleBulkDelete}
            onCloseClick={onCancelBulkDelete}
            message={"Modules"}
          />
        </>
      ) : (
        <NoPermission />
      )}
    </React.Fragment>
  )
}

export default ProjectModule
