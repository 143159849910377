import { createSlice } from "@reduxjs/toolkit"

const TestScenarioSlice = createSlice({
  name: "TestScenario",
  initialState: {
    allTestScenario: "",
    nextTestScenarioId: null,

    testScenarioFilterFrom: {
      build_id: null,
      module_id: null,
      requirement_id: null,
      created_by: null,
    },

    singleTestScenarioData: null,

    editTestScenario: {
      isEdit: false,
      data: null,
    },
  },

  reducers: {
    setAllTestScenario: (state, action) => ({
      ...state,
      allTestScenario: action.payload,
    }),
    setSingleTestScenarioData: (state, action) => ({
      ...state,
      singleTestScenarioData: action.payload,
    }),
    setEditTestScenario: (state, action) => ({
      ...state,
      editTestScenario: action.payload,
    }),
    setNextTestScenarioId: (state, action) => ({
      ...state,
      nextTestScenarioId: action.payload,
    }),
    setTestScenarioFilterFrom: (state, action) => {
      state.testScenarioFilterFrom = action.payload
    },
    clearFilters: state => {
      state.testScenarioFilterFrom = {
        build_id: null,
        module_id: null,
        requirement_id: null,
        created_by: null,
      }
    },
  },
})

export const {
  setAllTestScenario,
  setNextTestScenarioId,
  setTestScenarioFilterFrom,
  clearFilters,
  setSingleTestScenarioData,
  setEditTestScenario,
} = TestScenarioSlice.actions

export default TestScenarioSlice.reducer
