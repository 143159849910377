import React, { useEffect, useState } from "react"
import {
  Col,
  Button,
  Label,
  FormFeedback,
  Form,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { useFormik } from "formik"
import { createOptions } from "helpers/helper"
import {
  clearFilters,
  setRequirementFilterFrom,
} from "store/Slices/RequirementSlice"
import { fetchRequirementDetails } from "store/actions/RequirementActions"

const RequirementFilter = props => {
  const dispatch = useDispatch()
  const { isFilterFormOpen, toggle, onSubmitFilterFrom, onCancelFilterFrom } =
    props
  const { requirementFilterFrom } = useSelector(state => state.Requirement)
  const [buildValues, setBuildValues] = useState([])
  const [moduleValues, setModuleValues] = useState([])
  const [sourceRequirement, setSourceRequirement] = useState([])
  const [assignTo, setAssignTo] = useState([])
  const [requirementType, setRequirementType] = useState([])
  const [reqTypeOptions, setReqTypeOptions] = useState([])
  const tagDetails = useSelector(state => state.Project?.tagStatus.data)
  const buildDetails = useSelector(state => state.Project?.buildDetails)
  const moduleDetails = useSelector(state => state.Project?.moduleDetails)
  const teamDetails = useSelector(state => state.Project?.teamDetails)
  const requirementDetails = useSelector(
    state => state.Project?.requirementDetails
  )
  const currentUrl = window.location.href
  const parts = currentUrl.split("/")
  const id = parts[parts.length - 1]

  useEffect(() => {
    setRequirementType(createOptions(reqTypeOptions))
  }, [reqTypeOptions])

  useEffect(() => {
    const buldData = []
    const teamsData = []
    const requirementData = []
    const moduleData = []

    buildDetails.forEach(entry => {
      buldData.push({
        value: Math.round(entry.id),
        label: entry.build_id,
      })
    })

    teamDetails.forEach(entry => {
      teamsData.push({
        value: entry.id,
        label: entry.user_name,
      })
    })

    moduleDetails.forEach(entry => {
      moduleData.push({
        value: entry.id,
        label: entry.module_name,
      })
    })

    requirementDetails.forEach(entry => {
      if (entry.requirement_source) {
        // Check if requirement_source is not empty
        requirementData.push({
          value: entry.id,
          label: entry.requirement_source,
        })
      }
    })

    setBuildValues(buldData)
    setModuleValues(moduleData)
    setSourceRequirement(requirementData)
    setAssignTo(teamsData)
    setReqTypeOptions(tagDetails?.Requirement_Type)
  }, [buildDetails, teamDetails, requirementDetails, moduleDetails, tagDetails])

  const formik = useFormik({
    initialValues: requirementFilterFrom,
    enableReinitialize: true,
    onSubmit: async values => {
      try {
        if (onSubmitFilterFrom) {
          await onSubmitFilterFrom(values)
        }
        dispatch(setRequirementFilterFrom(values))
      } catch (error) {
        console.error("Error during form submission:", error)
      }
    },
  })

  const layoutModeType = useSelector(state => state.Layout.layoutModeType)
  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888",
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Ensure input text is visible
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150, // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  }

  const handleClearFilters = () => {
    formik.resetForm()
    dispatch(clearFilters())
    let bodyoption = {
      page: 1,
      page_size: 10,
      project_id: id,
    }
    dispatch(fetchRequirementDetails(bodyoption))
    // toggle()
    onCancelFilterFrom()
  }

  const renderSelect = (
    name,
    label,
    placeholder,
    options,
    isRequired = true,
    customProps = {}
  ) => (
    <div className="mb-3">
      <Label className="form-label">
        {label} {isRequired && <span className="text-danger">*</span>}
      </Label>
      <Select
        name={name}
        placeholder={placeholder}
        value={
          options.find(option => option.value === formik.values[name]) || null
        }
        onChange={option => {
          formik.setFieldValue(name, option?.value)
          // dispatch(
          //   setRequirementFilterFrom({
          //     ...requirementFilterFrom,
          //     [name]: option?.value,
          //   })
          // )
        }}
        onBlur={() => formik.setFieldTouched(name)}
        options={options}
        className={`select2-selection ${
          formik.touched[name] && formik.errors[name] ? "is-invalid" : ""
        }`}
        {...customProps}
        styles={customStyles}
        maxMenuHeight={150}
        isClearable={true}
      />
      {formik.touched[name] && formik.errors[name] && (
        <FormFeedback className="custom-error-message">
          {formik.errors[name]}
        </FormFeedback>
      )}
    </div>
  )
  useEffect(() => {
    if (isFilterFormOpen) {
      formik.resetForm({ values: requirementFilterFrom })
    }
  }, [isFilterFormOpen, requirementFilterFrom])

  // Dynamically update form values when `isFilterFormOpen` is true
  useEffect(() => {
    if (isFilterFormOpen) {
      dispatch(setRequirementFilterFrom(requirementFilterFrom))
    }
  }, [isFilterFormOpen, dispatch, requirementFilterFrom])
  return (
    <Offcanvas isOpen={isFilterFormOpen} direction="end" toggle={toggle}>
      <OffcanvasHeader toggle={toggle}>Filter Requirements</OffcanvasHeader>
      <OffcanvasBody>
        <Form onSubmit={formik.handleSubmit}>
          {renderSelect(
            "filter_build_id",
            "Build",
            "Select Build",
            buildValues,
            false
          )}
          {renderSelect(
            "filter_module_id",
            "Module",
            "Select Module",
            moduleValues,
            false
          )}
          {renderSelect(
            "filter_Requirement_Source",
            "Requirement Source",
            "Select Requirement Source",
            sourceRequirement,
            false
          )}
          {renderSelect(
            "filter_requirement_type",
            "Requirement Type",
            "Select Requirement Type",
            requirementType,
            false
          )}
          {renderSelect(
            "filter_assign_to",
            "Assign To",
            "Select Assignee",
            assignTo,
            false
          )}
          <div className="d-flex justify-content-between mt-3">
            <Button type="reset" color="secondary" onClick={handleClearFilters}>
              Clear All
            </Button>
            <Button type="submit" color="primary">
              Apply
            </Button>
          </div>
        </Form>
      </OffcanvasBody>
    </Offcanvas>
  )
}

export default RequirementFilter
