import React, { useEffect, useMemo, useState } from "react"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Table,
} from "reactstrap"
import {
  getStatusBackGroundColor,
  getStatusBorderColor,
  getStatusColor,
} from "../../helpers/helper"
import { useSelector } from "react-redux"
import moment from "moment"
import Breadcrumb from "components/Common/Breadcrumb"
import { Link, useNavigate } from "react-router-dom"
import TableContainer from "./TableContainer"
import { teamDetails } from "store/actions/ProjectActions"
import { useDispatch } from "react-redux"
import DeleteModal from "components/Common/DeleteModal"
import { toast } from "react-toastify"
import httpInjectorService from "services/http-Injector.Service"

const ViewProject = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const projectData = useSelector(state => state?.Project?.ProjectDetailsView)
  const loading = useSelector(state => state.Loading.isLoading)
  console.log(projectData)
  const userData = useSelector(state => state.Project.teamsUserList?.Data)
  const teamSize = useSelector(state => state.Project?.teamsUserList.TotalCount)
  const [TeampageSize, setTeamPageSize] = useState(10)
  const [pageIndex, setPageIndex] = useState(0)
  const [totalCount, setTotalCount] = useState(null)
  const [searchQuery, setSearchQuery] = useState("")
  const [deleteModal, setDeleteModal] = useState(false)

  const breadcrumbItems = [
    { label: "Projects", path: "/ProjectManagement" },
    { label: "Project view", path: "/" },
  ]

  useEffect(() => {
    // Check if loading is complete and no project data is available
    if (!loading && projectData.length === 0) {
      navigate("/ProjectManagement")
    }
  }, [loading, projectData, navigate])

  useEffect(() => {
    fetchTeamDetails(pageIndex, TeampageSize, projectData, searchQuery)
  }, [pageIndex, TeampageSize, projectData, searchQuery])

  const fetchTeamDetails = async (page, size, projectData, searchQuery) => {
    let bodyoption = {
      page: page + 1,
      page_size: size,
      project_id: projectData.id,
      search: searchQuery,
    }
    try {
      const data = await dispatch(teamDetails(bodyoption))
      setTotalCount(data.TotalCount)
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  const columns = useMemo(
    () => [
      {
        header: "",
        accessorKey: " ",
        cell: ({ row }) => (
          <i className="mdi mdi-account-circle text-primary h1" />
        ),
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "  ",
        accessorKey: "user_name",
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: " ",
        accessorKey: "role_name",
        cell: cell => {
          const role_name = cell.getValue()
          return (
            <>
              <p
                className="font-size-14 mb-1"
                style={{
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  whiteSpace: "normal",
                  maxWidth: "200px", // Set the max-width to control the wrapping
                }}
              >
                {role_name ? role_name : "-"}
              </p>
            </>
          )
        },
        enableColumnFilter: false,
        enableSorting: true,
      },
    ],
    [userData]
  )

  const handleSearchChange = e => {
    setSearchQuery(e.target.value)
  }

  const handleDeleteProject = () => {
    if (projectData.id) {
      deleteProject(projectData.id)
      setDeleteModal(false)
    }
  }

  const onClickDelete = () => {
    setDeleteModal(true)
  }

  const deleteProject = async id => {
    try {
      const response = await httpInjectorService.deleteProject(id)
      console.log(response)
      if (response.status) {
        const errorMessage =
          typeof response.message === "string"
            ? response.message
            : response.message.join(", ")
        toast.success(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
        navigate("/ProjectManagement")
      }
    } catch (err) {
      toast.error(err, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      })
    }
  }

  const onClickEdit = () => {
    navigate("/ProjectManagement/EditProject")
  }

  const onClickBack = () => {
    navigate("/ProjectManagement")
  }

  const Project_Status = useSelector(
    state => state.ProjectManagement?.tagStatus?.data?.Project_Status
  )
  const mapStatusToCategory = status => {
    for (let category in Project_Status) {
      if (Project_Status[category].includes(status)) {
        return category
      }
    }
    return status
  }

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumb title="Projects view" breadcrumbItems={breadcrumbItems} />
        <div className="d-flex justify-content-end mb-2">
          <Button
            type="button"
            color="primary"
            className="btn-label"
            onClick={onClickBack}
          >
            <i className="bx bx-arrow-back label-icon"></i> Go Back
          </Button>
        </div>
        <Row>
          <Col lg={7}>
            <Card style={{ minHeight: "400px" }}>
              <CardBody>
                <div className="d-flex justify-content-between">
                  <div className="d-flex">
                    <div className="avatar-xs me-4">
                      <span
                        className={
                          "avatar-title bg-" +
                          `${getStatusBackGroundColor(
                            mapStatusToCategory(projectData.project_status)
                          )}` +
                          "-subtle text-" +
                          `${getStatusBackGroundColor(
                            mapStatusToCategory(projectData.project_status)
                          )}`
                        }
                        style={{ fontSize: "20px", borderRadius: "8px" }}
                      >
                        {projectData?.project_name?.slice(0, 2).toUpperCase()}
                      </span>
                    </div>
                    <div className="flex-grow-1 overflow-hidden">
                      <h5 className="font-size-15 d-flex align-items-center">
                        {/* <span style={{ maxWidth: "135px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}> */}
                        <span>
                          <Link className="text-dark">
                            {projectData.project_name || "-"}
                          </Link>
                        </span>
                        {projectData.project_short_code && (
                          <span className="ms-2">
                            ({projectData.project_short_code})
                          </span>
                        )}
                      </h5>
                      <p className="text-muted mb-4">
                        {projectData.project_type}
                      </p>
                    </div>
                    <div>
                      <Badge
                        className={
                          "badge-soft-" +
                          `${getStatusBackGroundColor(
                            mapStatusToCategory(projectData.project_status)
                          )}`
                        }
                      >
                        {projectData.project_status}
                      </Badge>
                    </div>
                  </div>
                </div>

                <div className="mb-4">
                  <h6 style={{ fontWeight: "550" }}>Project Description</h6>
                  <p
                    className="text-muted mb-4"
                    dangerouslySetInnerHTML={{
                      __html: projectData.project_description
                        ? projectData.project_description
                        : "-",
                    }}
                  ></p>
                </div>
                <div>
                  <Row>
                    <Col sm={6}>
                      <h6 style={{ fontWeight: "550" }}>
                        <i className="mdi mdi-calendar text-primary  h5" />{" "}
                        Planned Start Date
                      </h6>
                      <p className="text-muted " style={{ marginLeft: "20px" }}>
                        {projectData.planned_start_date
                          ? moment(projectData.planned_start_date).format(
                              "DD-MM-YYYY"
                            )
                          : "-"}
                      </p>
                    </Col>
                    <Col sm={6}>
                      <h6 style={{ fontWeight: "550" }}>
                        <i className="mdi mdi-calendar-check text-primary  h5" />{" "}
                        Planned End Date
                      </h6>
                      <p className="text-muted " style={{ marginLeft: "20px" }}>
                        {projectData.planned_end_date
                          ? moment(projectData.planned_end_date).format(
                              "DD-MM-YYYY"
                            )
                          : "-"}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <h6 style={{ fontWeight: "550" }}>
                        <i className="mdi mdi-calendar text-primary  h5" />{" "}
                        Actual Start Date
                      </h6>
                      <p className="text-muted " style={{ marginLeft: "20px" }}>
                        {projectData.actual_start_date
                          ? moment(projectData.actual_start_date).format(
                              "DD-MM-YYYY"
                            )
                          : "-"}
                      </p>
                    </Col>
                    <Col sm={6}>
                      <h6 style={{ fontWeight: "550" }}>
                        <i className="mdi mdi-calendar-check text-primary  h5" />{" "}
                        Actual End Date
                      </h6>
                      <p className="text-muted " style={{ marginLeft: "20px" }}>
                        {projectData.actual_end_date
                          ? moment(projectData.actual_end_date).format(
                              "DD-MM-YYYY"
                            )
                          : "-"}{" "}
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6}>
                      <h6 style={{ fontWeight: "550" }}>
                        <i className="mdi mdi-alarm text-primary h5" /> Planned
                        Effort
                      </h6>
                      <p className="text-muted " style={{ marginLeft: "20px" }}>
                        {projectData.planned_effort
                          ? projectData.planned_effort
                          : "-"}
                      </p>
                    </Col>
                    <Col sm={6}>
                      <h6 style={{ fontWeight: "550" }}>
                        <i className="mdi mdi-currency-usd-circle-outline text-primary h5" />{" "}
                        Planned Cost
                      </h6>
                      <p className="text-muted " style={{ marginLeft: "20px" }}>
                        {projectData.planned_cost
                          ? projectData.planned_cost
                          : "-"}
                      </p>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={5}>
            <Card style={{ height: "400px" }}>
              <div className="d-flex justify-content-between m-3 ">
                <h5 className="">Team members</h5>
                <div className="me-3">
                  <i className="bx bx-group me-1" />
                  {teamSize || 0}
                </div>
              </div>
              <CardBody style={{ height: "200px", overflow: "auto" }}>
                <div>
                  <InputGroup style={{ height: "38px" }}>
                    <InputGroupText style={{ height: "100%" }}>
                      <i className="bx bx-search-alt search-icon"></i>
                    </InputGroupText>
                    <Input
                      type="search"
                      placeholder="Search..."
                      aria-label="Search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </InputGroup>
                </div>
                <TableContainer
                  columns={columns}
                  data={userData || []}
                  pageIndex={pageIndex}
                  pageSize={TeampageSize}
                  totalCount={totalCount}
                  setPageIndex={setPageIndex}
                  setPageSize={setTeamPageSize}
                  isCustomPageSize={true}
                  isGlobalFilter={false}
                  isJobListGlobalFilter={false}
                  isPagination={true}
                  isAddDelete={false}
                  isAddEdit={false}
                  isAddFilter={false}
                  SearchPlaceholder="Search..."
                  tableClass="align-middle table-nowrap dt-responsive nowrap w-100 no-footer  "
                  pagination="pagination"
                  paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDeleteProject}
          onCloseClick={() => setDeleteModal(false)}
          message={"Project"}
        />
      </Container>
    </div>
  )
}

export default ViewProject
