import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import "flatpickr/dist/themes/material_blue.css"
import JoditEditor from "jodit-react"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import {
  createOptions,
  joditConfig,
  createOptionsDefectType,
} from "../../../helpers/helper"
import { toast } from "react-toastify"
import { useLocation, useNavigate } from "react-router-dom"
import Breadcrumb from "components/Common/Breadcrumb"
import "./CreateDefectFrom.scss"
import { fetchAllBuildsList } from "store/actions/BuildsActions"
import { fetchAllModulesList } from "store/actions/ModuleActions"
import { fetchAllRequirementsList } from "store/actions/RequirementActions"
import { setEditDefect, setSyncPopup } from "store/Slices/DefectsSlice"
import Dropzone from "react-dropzone"
import { getTagsStautusAPI } from "store/actions/TagsAction"
import {
  fetchAllTeamsDetailsUserAssigneeList,
  fetchAllUsersDetailsList,
  fetchUsersList,
} from "store/actions/UserActions"
import { TestCaseDetails } from "store/actions/TestCaseAction"
import {
  CreateDefectAPI,
  EditDefectAPI,
  FetchJiraUser,
  fetchNextDefectID,
  MapDefectPriority,
  MapDefectStatus,
} from "store/actions/DefectsActions"
import BASE_URL from "services/apiConfig"
import { getAllMapProject } from "store/actions/IntegrationAction"

const CreateDefectFrom = () => {
  const [buildOptionValue, setBuildOptionValues] = useState([])
  const [moduleOptionValue, setModuleOptionValues] = useState([])
  const [assignToOptionValue, setAssignToOptionValues] = useState([])
  const [DefectViewerOptionValue, setDefectViewerOptionValues] = useState([])
  const [requirementOptionValue, setRequirementOptionValues] = useState([])
  const [testCaseOptionValue, setTestCaseOptionValues] = useState([])
  const [jiraUserOptionValue, setJiraUserOptionValues] = useState([])
  const [nextTestScenarioId, setNextTestScenarioId] = useState("")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const loading = useSelector(state => state?.Loading?.isLoading)
  const mapProjectList = useSelector(state => state?.Integration?.mapList)
  const [HasJiraId, setHasJiraId] = useState(false)
  const [HasPlaneId, setHasPlaneId] = useState(false)
  const currentUrl = window.location.href
  const parts = currentUrl.split("/")
  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )
  const location = useLocation()
  const { isEdit, data } = location.state || {}

  const editDefect = useSelector(state => state?.Defects?.editDefect)

  const isEditMode = editDefect?.isEdit || isEdit
  const isEditModeData = editDefect?.data || data

  const [steps_to_reproduce, setDescription] = useState(
    isEditMode ? isEditModeData?.steps_to_reproduce : ""
  )
  const [actual_result_description, setActualDescription] = useState(
    isEditMode ? isEditModeData?.actual_result_description : ""
  )
  const [expected_result_description, setExpectedDescription] = useState(
    isEditMode ? isEditModeData?.expected_result_description : ""
  )
  const [comments, setComments] = useState(
    isEditMode ? isEditModeData?.comments : ""
  )
  const [isDescriptionInvalid, setIsDescriptionInvalid] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])
  const [selectedDeleteFiles, setSelectedDeleteFiles] = useState([])
  const [jira_user, setJiraUser] = useState(
    isEditMode ? isEditModeData?.jira_user : ""
  )
  const [jiraError, setJiraError] = useState(false)

  const breadcrumbItems = [
    { label: "Defect", path: "/DefectManagement" },
    {
      label: isEditMode ? `Edit Defect` : "Create Defect",
      path: "/",
    },
  ]

  const validationSchema = Yup.object().shape({
    defect_name: Yup.string()
      .trim()
      .matches(/^.*\S.*$/, "Defect Name cannot contain only spaces")
      .required("Defect Name is required"),
    defect_status: Yup.string().required("Status is required"),
    defect_priority: Yup.string().required("Priority is required"),
  })

  const stripHtmlTags = html => {
    const div = document?.createElement("div")
    div.innerHTML = html
    return div.textContent || div.innerText || ""
  }

  const formatFilePath = path => {
    if (path?.includes("/media/media")) {
      return path.replace("media/media", "media")
    }
    return path
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      project_id: globalProject?.value,
      defect_id: isEditModeData
        ? isEditModeData?.defect_id
        : nextTestScenarioId,
      build_id: isEditMode ? isEditModeData?.build_id || null : null,
      module_id: isEditMode ? isEditModeData?.module_id || null : null,
      requirement_id: isEditModeData
        ? isEditModeData?.requirement_id || null
        : null,
      defect_name: isEditModeData ? isEditModeData?.defect_name || null : null,
      defect_status: isEditModeData
        ? isEditModeData?.defect_status || null
        : null,
      defect_priority: isEditModeData
        ? isEditModeData?.defect_priority || null
        : null,
      severity: isEditModeData ? isEditModeData?.severity || null : null,
      steps_to_reproduce: isEditMode
        ? isEditModeData?.steps_to_reproduce || null
        : null,
      test_case_id: isEditMode ? isEditModeData?.test_case_id || null : null,
      defect_type: isEditMode ? isEditModeData?.defect_type || null : null,
      assign_to: isEditMode ? isEditModeData?.assign_to || null : null,
      browsers: isEditMode ? isEditModeData?.browsers || null : null,
      devices: isEditMode ? isEditModeData?.devices || null : null,
      os: isEditMode ? isEditModeData?.os || null : null,
      defect_viewers: isEditMode
        ? isEditModeData?.defect_viewers || null
        : null,
    },
    validationSchema,
    onSubmit: values => {
      const descriptionText = stripHtmlTags(steps_to_reproduce)
      if (HasJiraId && (!jira_user || descriptionText?.trim().length < 1)) {
        if (descriptionText?.trim().length < 1) {
          setIsDescriptionInvalid(true)
        }
        if (!jira_user) {
          setJiraError(true)
        }
        return
      }

      // Check if description is invalid without Jira requirement
      if (descriptionText?.trim().length < 1) {
        setIsDescriptionInvalid(true)
        return // Exit early if description is invalid
      }

      const formData = {
        ...values,
        steps_to_reproduce,
        comments,
        actual_result_description,
        expected_result_description,
        jira_user,
        attachment: selectedFiles[0],
        clear_attachments: selectedFiles?.length === 0 ? true : false,
      }
      createEditDefect(formData) // Call function to handle form data
    },
  })

  useEffect(() => {
    const filteredProjectList = mapProjectList?.filter(
      project => project.product === "Jira"
    )

    const hasJiraId = filteredProjectList?.some(
      defect => defect?.frothtestops_project === globalProject?.label
    )
    setHasJiraId(hasJiraId)

    const filteredPlaneList = mapProjectList?.filter(
      project => project.product === "Plane"
    )
    const hasPlanId = filteredPlaneList?.some(
      defect => defect?.frothtestops_project === globalProject?.label
    )
    setHasPlaneId(hasPlanId)
  }, [mapProjectList])

  useEffect(() => {
    if (HasJiraId || HasPlaneId) {
      fetchMapStatus()
    }
  }, [HasJiraId, HasPlaneId])

  useEffect(() => {
    let bodyOption = {
      integration_id: null,
    }
    dispatch(getAllMapProject(bodyOption))
  }, [])

  const [defectTypeValue, setDefectTypeValue] = useState([])
  const [severityOptionValues, setSeverityOptionValues] = useState([])
  const [statusOptionValues, setStatusOptionValues] = useState([])
  const [priorityOptionValues, setPriorityOptionValues] = useState([])
  const [osValue, setOsValue] = useState([])
  const [browserValue, setBrowserValue] = useState([])
  const [deviceValue, setDeviceValue] = useState([])
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const bodyOption = { project_id: globalProject?.value }
        const metaDataResponse = await dispatch(getTagsStautusAPI(bodyOption))

        const {
          Defect_Status,
          Severity,
          Device,
          OS,
          Browser,
          Defect_Type,
          Defect_Priority,
        } = metaDataResponse?.data || {}

        setDefectTypeValue(createOptionsDefectType(Defect_Type || []))
        setSeverityOptionValues(createOptions(Severity || []))
        setStatusOptionValues(createOptions(Defect_Status || []))
        setPriorityOptionValues(createOptions(Defect_Priority || []))
        setOsValue(createOptions(OS || []))
        setBrowserValue(createOptions(Browser || []))
        setDeviceValue(createOptions(Device || []))
      } catch (error) {
        console.log(error)
      }
    }

    fetchOptions()
  }, [globalProject])

  const fetchMapStatus = async () => {
    try {
      const responses = await Promise.all([
        dispatch(MapDefectStatus({ project_id: globalProject?.value })),
        dispatch(MapDefectPriority({ project_id: globalProject?.value })),
      ])

      const [statusResponse, priorityResponse] = responses

      setStatusOptionValues(
        statusResponse?.statuses?.map(entry => ({
          value: entry,
          label: entry,
        }))
      )

      setPriorityOptionValues(
        priorityResponse?.priorities?.map(entry => ({
          value: entry,
          label: entry,
        }))
      )
    } catch (error) {
      console.error("Failed to fetch options", error)
      toast.error("Failed to fetch options.")
    }
  }

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const responses = await Promise.all([
          dispatch(
            fetchAllBuildsList({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          dispatch(
            fetchAllModulesList({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          dispatch(
            fetchAllRequirementsList({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          dispatch(
            fetchNextDefectID({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          // dispatch(
          //   fetchAllUsersDetailsList({ project_id: globalProject?.value || selectedProject?.value })
          // ),
          // dispatch(fetchUsersList({ project_id: globalProject?.value })),
          dispatch(
            fetchAllTeamsDetailsUserAssigneeList({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          dispatch(
            TestCaseDetails({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
        ])

        const [
          buildResponse,
          moduleResponse,
          reqResponse,
          nextIdResponse,
          // userDetailsListResponse,
          userListResponse,
          testCaseResponse,
        ] = responses

        setBuildOptionValues(
          buildResponse?.map(entry => ({
            value: Math.round(entry.id),
            label: entry.build_id,
          }))
        )

        setModuleOptionValues(
          moduleResponse?.map(entry => ({
            value: entry.id,
            label: entry.module_name,
          }))
        )

        setRequirementOptionValues(
          reqResponse?.map(entry => ({
            value: entry.id,
            label: entry.requirement_title,
          }))
        )

        setAssignToOptionValues(
          userListResponse?.results?.map(entry => ({
            value: entry.id,
            label: entry.user_name,
          }))
        )

        setDefectViewerOptionValues(
          userListResponse?.results?.map(entry => ({
            value: entry.id,
            label: entry.user_name,
          }))
        )

        setTestCaseOptionValues(
          testCaseResponse?.map(entry => ({
            value: entry.id,
            label: `${entry.test_case_id} - ${entry.test_case_name}`,
          }))
        )
        await setNextTestScenarioId(nextIdResponse?.next_defect_id)
      } catch (error) {
        console.error("Failed to fetch options", error)
        toast.error("Failed to fetch options.")
      }
    }
    if (globalProject) {
      fetchOptions()
    }
  }, [globalProject, dispatch])

  const fetchJiraUserList = async () => {
    if (HasJiraId) {
      const jiraRes = await dispatch(
        FetchJiraUser({ project_id: globalProject.value })
      )
      setJiraUserOptionValues(
        jiraRes?.users?.map(entry => ({
          value: entry,
          label: entry,
        }))
      )
    }
  }
  useEffect(() => {
    if (HasJiraId) {
      fetchJiraUserList()
    }
  }, [HasJiraId])

  const submitData = () => {
    const cleanDescription = stripHtmlTags(steps_to_reproduce).trim()
    if (!cleanDescription) {
      setIsDescriptionInvalid(true)
    } else {
      setIsDescriptionInvalid(false)
    }

    if (HasJiraId && !jira_user) {
      setJiraError(true)
    } else {
      setJiraError(false)
    }
  }

  useEffect(() => {
    if (isEditMode && isEditModeData) {
      // Initialize selectedFiles with existing attachments
      if (
        isEditModeData.attachment?.length > 0 &&
        !(
          isEditModeData.attachment.length === 1 &&
          isEditModeData.attachment[0] === ""
        )
      ) {
        const existingFiles = isEditModeData.attachment.map((file, index) => ({
          name: `Existing File ${index + 1}`,
          path: file, // Assuming this is the file path
          isExisting: true,
        }))
        setSelectedDeleteFiles(existingFiles)
      }
    }
  }, [isEditMode, isEditModeData])

  const handleAcceptedFiles = files => {
    const newImages = files?.map(file => {
      return Object.assign(file, {
        priview: URL.createObjectURL(file),
      })
    })
    setSelectedFiles([...selectedFiles, ...newImages])
    setSelectedDeleteFiles([...selectedFiles, ...newImages])
  }

  const createEditDefect = async formData => {
    const editFromValues = {
      ...formData,
      id: isEditModeData?.id,
    }

    if (!isEditModeData) {
      try {
        const data = await dispatch(CreateDefectAPI(formData))

        if (HasJiraId || HasPlaneId) {
          handlesyncViewPopup(data)
        }
        navigate(-1)
      } catch (error) {
        console.error("Error creating defect:", error)
        toast.error(
          error.message || "An error occurred while creating Defect",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          }
        )
      }
    } else {
      try {
        await dispatch(EditDefectAPI(editFromValues))
        navigate(-1) // Navigate back or handle success
        dispatch(setEditDefect({ isEdit: false, data: null }))
      } catch (error) {
        console.error("Error updating defect:", error)
        toast.error(
          error.message || "An error occurred while updating Defect",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          }
        )
      }
    }
  }

  const handlesyncViewPopup = data => {
    dispatch(setSyncPopup({ popupOpen: true, data: data }))
  }
  const layoutModeType = useSelector(state => state.Layout.layoutModeType)

  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888",
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Ensure input text is visible
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150, // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  }

  return (
    <React.Fragment>
      <div className={parts.includes("DefectManagement") ? "" : "page-content"}>
        <Container fluid>
          <Breadcrumb title="Defect" breadcrumbItems={breadcrumbItems} />

          <div className="mt-2 mx-3 d-flex justify-content-between">
            <div>
              <h3>
                {!isEditMode
                  ? "Create New Defect"
                  : `Edit Defect - (${isEditModeData?.defect_id})`}
              </h3>
              <p className="text-muted">
                Fill the form to {!isEditMode ? "create" : "edit"} a defect
              </p>
            </div>
            <div>
              <Button
                type="button"
                color="primary"
                className="btn-label"
                onClick={() => {
                  navigate(-1)
                  dispatch(setEditDefect({ isEdit: false, data: null }))
                }}
              >
                <i className="bx bx-arrow-back label-icon"></i> Go Back
              </Button>
            </div>
          </div>

          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <div className="mb-3">
                      <Label className="form-label">Project</Label>
                      <Input
                        name="project_id"
                        type="text"
                        placeholder="Project"
                        value={globalProject?.label}
                        className="custom-input"
                        readOnly
                        disabled
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Defect Id</Label>
                      <Input
                        name="defect_id"
                        type="text"
                        placeholder="Defect Id"
                        value={formik.values.defect_id}
                        className="custom-input"
                        readOnly
                        disabled
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">
                        Defect Name <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="defect_name"
                        type="text"
                        placeholder="Defect Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.defect_name}
                        invalid={
                          formik.touched.defect_name &&
                          !!formik.errors.defect_name
                        }
                        className="custom-input"
                      />
                      {formik.touched.defect_name &&
                        formik.errors.defect_name && (
                          <FormFeedback>
                            {formik.errors.defect_name}
                          </FormFeedback>
                        )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <div className="mb-3">
                      <Label className="form-label">
                        Status <span className="text-danger">*</span>
                      </Label>
                      <Select
                        name="defect_status"
                        className="select2-selection"
                        options={statusOptionValues}
                        styles={customStyles}
                        onChange={option =>
                          formik.setFieldValue("defect_status", option?.value)
                        }
                        onBlur={() => formik.setFieldTouched("defect_status")}
                        value={statusOptionValues?.find(
                          option => option.value === formik.values.defect_status
                        )}
                      />
                      {formik.touched.defect_status &&
                        formik.errors.defect_status && (
                          <div className="text-danger required-field">
                            {formik.errors.defect_status}
                          </div>
                        )}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">
                        Priority <span className="text-danger">*</span>
                      </Label>
                      <Select
                        name="defect_priority"
                        className="select2-selection"
                        styles={customStyles}
                        options={priorityOptionValues}
                        onChange={option =>
                          formik.setFieldValue("defect_priority", option?.value)
                        }
                        onBlur={() => formik.setFieldTouched("defect_priority")}
                        value={priorityOptionValues?.find(
                          option =>
                            option.value === formik.values.defect_priority
                        )}
                      />
                      {formik.touched.defect_priority &&
                        formik.errors.defect_priority && (
                          <div className="text-danger required-field">
                            {formik.errors.defect_priority}
                          </div>
                        )}
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Severity </Label>
                      <Select
                        name="severity"
                        styles={customStyles}
                        className="select2-selection"
                        isClearable
                        options={severityOptionValues}
                        onChange={option =>
                          formik.setFieldValue("severity", option?.value || "")
                        }
                        onBlur={() => formik.setFieldTouched("severity")}
                        value={severityOptionValues?.find(
                          option => option.value === formik.values.severity
                        )}
                      />
                      {formik.touched.severity && formik.errors.severity && (
                        <FormFeedback>{formik.errors.severity}</FormFeedback>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                {isEditModeData?.jira_id || isEditModeData?.plane_id ? (
                  <Card>
                    <CardBody>
                      <Col md="12">
                        {isEditModeData?.jira_id &&
                          isEditModeData?.jira_url && (
                            <Row>
                              <Col sm={6}>
                                <h6 style={{ fontWeight: "550" }}>Jira ID</h6>
                                <a
                                  href={isEditModeData?.jira_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {isEditModeData?.jira_id || "-"}
                                </a>
                              </Col>
                              <Col sm={6}></Col>
                            </Row>
                          )}
                        {isEditModeData?.plane_id &&
                          isEditModeData?.plane_url && (
                            <Row>
                              <Col sm={6}>
                                <h6 style={{ fontWeight: "550" }}>Plane ID</h6>
                                <a
                                  href={isEditModeData?.plane_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {isEditModeData?.plane_id || "-"}
                                </a>
                              </Col>
                              <Col sm={6}></Col>
                            </Row>
                          )}
                      </Col>
                    </CardBody>
                  </Card>
                ) : null}
              </Col>

              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Col md="12">
                      <Label>
                        Steps To Reproduce{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <JoditEditor
                        config={joditConfig()}
                        value={steps_to_reproduce}
                        onBlur={newContent => setDescription(newContent)}
                      />
                      {isDescriptionInvalid && (
                        <FormFeedback className="d-block">
                          Steps to Reproduce is required
                        </FormFeedback>
                      )}
                    </Col>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="mb-3 col-12 d-flex flex-column flex-md-row gap-1">
                      <div className="mb-3 col-12 col-md-4">
                        <Label className="form-label">Build</Label>
                        <Select
                          styles={customStyles}
                          name="build_id"
                          className="select2-selection"
                          isClearable
                          options={buildOptionValue}
                          onChange={option =>
                            formik.setFieldValue(
                              "build_id",
                              option?.value || ""
                            )
                          }
                          onBlur={() => formik.setFieldTouched("build_id")}
                          value={buildOptionValue?.find(
                            option => option.value === formik.values.build_id
                          )}
                        />
                        {formik.touched.build_id && formik.errors.build_id && (
                          <FormFeedback>{formik.errors.build_id}</FormFeedback>
                        )}
                      </div>
                      <div className="mb-3 col-12 col-md-4">
                        <Label className="form-label">Module</Label>
                        <Select
                          name="module_id"
                          styles={customStyles}
                          className="select2-selection"
                          isClearable
                          options={moduleOptionValue}
                          onChange={option =>
                            formik.setFieldValue(
                              "module_id",
                              option?.value || ""
                            )
                          }
                          onBlur={() => formik.setFieldTouched("module_id")}
                          value={moduleOptionValue?.find(
                            option => option.value === formik.values.module_id
                          )}
                        />
                        {formik.touched.module_id &&
                          formik.errors.module_id && (
                            <FormFeedback>
                              {formik.errors.module_id}
                            </FormFeedback>
                          )}
                      </div>
                      <div className="mb-3 col-12 col-md-4">
                        <Label className="form-label">Requirement</Label>
                        <Select
                          name="requirement_id"
                          styles={customStyles}
                          className="select2-selection"
                          isClearable
                          options={requirementOptionValue}
                          onChange={option =>
                            formik.setFieldValue(
                              "requirement_id",
                              option?.value || ""
                            )
                          }
                          onBlur={() =>
                            formik.setFieldTouched("requirement_id")
                          }
                          value={requirementOptionValue?.find(
                            option =>
                              option.value === formik.values.requirement_id
                          )}
                        />
                        {formik.touched.requirement_id &&
                          formik.errors.requirement_id && (
                            <FormFeedback>
                              {formik.errors.requirement_id}
                            </FormFeedback>
                          )}
                      </div>
                    </div>

                    <div className="mb-3 col-12 d-flex flex-column flex-md-row gap-1">
                      <div className="mb-3 col-12 col-md-4">
                        <Label className="form-label">Test Case</Label>
                        <Select
                          name="test_case_id"
                          styles={customStyles}
                          className="select2-selection"
                          isClearable
                          options={testCaseOptionValue}
                          onChange={option =>
                            formik.setFieldValue(
                              "test_case_id",
                              option?.value || ""
                            )
                          }
                          onBlur={() => formik.setFieldTouched("test_case_id")}
                          value={testCaseOptionValue?.find(
                            option =>
                              option.value === formik.values.test_case_id
                          )}
                        />
                        {formik.touched.test_case_id &&
                          formik.errors.test_case_id && (
                            <FormFeedback>
                              {formik.errors.test_case_id}
                            </FormFeedback>
                          )}
                      </div>
                      <div className="mb-3 col-12 col-md-4">
                        <Label className="form-label">Defect Type</Label>
                        <Select
                          name="defect_type"
                          styles={customStyles}
                          className="select2-selection"
                          isClearable={formik.values.defect_type && true}
                          options={defectTypeValue}
                          onChange={option =>
                            formik.setFieldValue(
                              "defect_type",
                              option?.value || ""
                            )
                          }
                          onBlur={() => formik.setFieldTouched("defect_type")}
                          value={
                            formik.values.defect_type
                              ? {
                                  value: formik.values.defect_type,
                                  label: formik.values.defect_type,
                                }
                              : {
                                  value: "",
                                  label: (
                                    <span className="text-muted mt-2">
                                      Select..
                                    </span>
                                  ),
                                }
                          }
                        />
                        {/* {formik.touched.defect_type &&
                          formik.errors.defect_type && (
                            <div>{formik.errors.defect_type}</div>
                          )} */}
                        <div className="text-danger required-field">
                          {formik.errors.defect_type}
                        </div>
                      </div>
                      <div className="mb-3 col-12 col-md-4">
                        <Label className="form-label">Assign To</Label>
                        <Select
                          name="assign_to"
                          className="select2-selection"
                          isClearable
                          styles={customStyles}
                          options={assignToOptionValue}
                          onChange={option =>
                            formik.setFieldValue(
                              "assign_to",
                              option?.value || ""
                            )
                          }
                          onBlur={() => formik.setFieldTouched("assign_to")}
                          value={assignToOptionValue?.find(
                            option => option.value === formik.values.assign_to
                          )}
                        />
                        {formik.touched.assign_to &&
                          formik.errors.assign_to && (
                            <FormFeedback>
                              {formik.errors.assign_to}
                            </FormFeedback>
                          )}
                      </div>
                    </div>

                    <div className="mb-3 col-12 d-flex flex-column flex-md-row gap-1">
                      <div className="mb-3 col-12 col-md-4">
                        <Label className="form-label">OS</Label>
                        <Select
                          name="os"
                          className="select2-selection"
                          isClearable
                          styles={customStyles}
                          options={osValue}
                          onChange={option =>
                            formik.setFieldValue("os", option?.value || "")
                          }
                          onBlur={() => formik.setFieldTouched("os")}
                          value={osValue?.find(
                            option => option.value === formik.values.os
                          )}
                        />
                        {formik.touched.os && formik.errors.os && (
                          <FormFeedback>{formik.errors.os}</FormFeedback>
                        )}
                      </div>
                      <div className="mb-3 col-12 col-md-4">
                        <Label className="form-label">Browsers</Label>
                        <Select
                          name="browsers"
                          options={browserValue}
                          styles={customStyles}
                          className="select2-selection"
                          isClearable
                          onChange={option =>
                            formik.setFieldValue(
                              "browsers",
                              option?.value || ""
                            )
                          }
                          onBlur={() => formik.setFieldTouched("browsers")}
                          value={browserValue?.find(
                            option => option.value === formik.values.browsers
                          )}
                        />
                        {formik.touched.browsers && formik.errors.browsers && (
                          <FormFeedback>{formik.errors.browsers}</FormFeedback>
                        )}
                      </div>
                      <div className="mb-3 col-12 col-md-4">
                        <Label className="form-label">Devices</Label>
                        <Select
                          name="devices"
                          styles={customStyles}
                          className="select2-selection"
                          isClearable
                          options={deviceValue}
                          onChange={option =>
                            formik.setFieldValue("devices", option?.value || "")
                          }
                          onBlur={() => formik.setFieldTouched("devices")}
                          value={deviceValue?.find(
                            option => option.value === formik.values.devices
                          )}
                        />
                        {formik.touched.devices && formik.errors.devices && (
                          <FormFeedback>{formik.errors.devices}</FormFeedback>
                        )}
                      </div>
                    </div>

                    <div className="mb-3 col-12 d-flex flex-column flex-md-row gap-1">
                      <div className="mb-3 col-12 col-md-4">
                        <Label className="form-label">Defect Viewers</Label>
                        <Select
                          name="defect_viewers"
                          styles={customStyles}
                          className="select2-selection"
                          isClearable
                          options={DefectViewerOptionValue}
                          onChange={option =>
                            formik.setFieldValue(
                              "defect_viewers",
                              option?.value || ""
                            )
                          }
                          onBlur={() =>
                            formik.setFieldTouched("defect_viewers")
                          }
                          value={DefectViewerOptionValue?.find(
                            option =>
                              option.value === formik.values.defect_viewers
                          )}
                        />
                        {formik.touched.defect_viewers &&
                          formik.errors.defect_viewers && (
                            <FormFeedback>
                              {formik.errors.defect_viewers}
                            </FormFeedback>
                          )}
                      </div>
                      {HasJiraId && (
                        <div className="mb-3 col-12 col-md-4">
                          <Label className="form-label">
                            Jira Users <span className="text-danger">*</span>
                          </Label>
                          <Select
                            name="jira_user"
                            styles={customStyles}
                            className="select2-selection"
                            options={jiraUserOptionValue}
                            onChange={e => setJiraUser(e?.value)}
                            onBlur={() => setJiraError(!jira_user)}
                            value={jiraUserOptionValue?.find(
                              option => option.value === jira_user
                            )}
                          />
                          {jiraError && (
                            <FormFeedback className="d-block">
                              Jira User is required
                            </FormFeedback>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="mb-3 col-12">
                      <div className="mb-3">
                        <Label>Actual Result Description</Label>
                        <JoditEditor
                          config={joditConfig()}
                          value={actual_result_description}
                          onBlur={newContent =>
                            setActualDescription(newContent)
                          }
                        />
                      </div>
                    </div>
                    <div className="mb-3 col-12">
                      <div className="mb-3">
                        <Label>Expected Result Description</Label>
                        <JoditEditor
                          config={joditConfig()}
                          value={expected_result_description}
                          onBlur={newContent =>
                            setExpectedDescription(newContent)
                          }
                        />
                      </div>
                    </div>
                    <div className="mb-3 col-12">
                      <div className="mb-3">
                        <Label>Comments</Label>
                        <JoditEditor
                          config={joditConfig()}
                          value={comments}
                          onBlur={newContent => setComments(newContent)}
                        />
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="mt-3">
                      <Label>Attached Files</Label>
                      <Dropzone
                        onDrop={acceptedFiles => {
                          handleAcceptedFiles(acceptedFiles)
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone">
                            <div
                              className="dz-message needsclick"
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="dz-message needsclick">
                                <div className="mb-3">
                                  <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4>Drop files here or click to upload.</h4>
                              </div>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {selectedDeleteFiles.map((file, index) => {
                          return (
                            <div className="border rounded" key={index}>
                              <div className="d-flex flex-justify-content-between gap-2 p-2">
                                <div className="flex-shrink-0 me-3">
                                  <div className="avatar-xs bg-light rounded p-2">
                                    <i
                                      className="bx bx-file"
                                      style={{ fontSize: "23px" }}
                                    ></i>
                                  </div>
                                </div>
                                <div className="flex-grow-1">
                                  <div className="pt-2">
                                    <p className="fs-md mb-1" data-dz-name>
                                      {" "}
                                      <a
                                        style={{
                                          textDecoration: "none",
                                          color: "black",
                                        }}
                                        href={`${BASE_URL}/${formatFilePath(
                                          file.path
                                        )}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        {file.path}
                                      </a>
                                    </p>
                                    <strong
                                      className="error text-danger"
                                      data-dz-errormessage
                                    ></strong>
                                  </div>
                                </div>
                                <div className="flex-shrink-0 ms-3 mt-1">
                                  <Button
                                    variant="danger"
                                    size="sm"
                                    onClick={() => {
                                      const newImages = [...selectedFiles]
                                      newImages.splice(index, 1)
                                      setSelectedFiles(newImages)
                                      setSelectedDeleteFiles(newImages)
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col md="12" className="text-end mb-2 mt-3">
                <Button
                  color="primary"
                  type="submit"
                  onClick={submitData}
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Submit"}
                </Button>
                <Button
                  type="button"
                  color="secondary"
                  onClick={() => {
                    formik.resetForm()
                    navigate(-1)
                    dispatch(setEditDefect({ isEdit: false, data: null }))
                  }}
                  className="ms-2"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreateDefectFrom
