import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  InputGroup,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Breadcrumbs from "../../Common/Breadcrumb"
import "flatpickr/dist/themes/material_blue.css"
import Flatpickr from "react-flatpickr"
import { useSelector, useDispatch } from "react-redux"
import { joditConfig } from "../../../helpers/helper"
import JoditEditor from "jodit-react"
import {
  fetchCountries,
  fetchProjectForSelectProject,
} from "../../../store/ProjectManagement/actions"
import { toast } from "react-toastify"
import httpInjectorService from "services/http-Injector.Service"
import { useLocation, useNavigate } from "react-router-dom"
import "./CreateProject.scss"
import Select, { components } from "react-select"
import { EditProject, TagStatusData } from "store/actions/ProjectActions"

const CreateProject = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { data } = location.state || {}
  const tagStatusData = useSelector(state => state.Project.tagStatus)
  const countries = useSelector(state => state.ProjectManagement.countries)
  const editPrrojectData =
    data || useSelector(state => state?.Project?.projectEditData)
  const layoutModeType = useSelector(state => state.Layout.layoutModeType)
  const [description, setDescription] = useState("")
  const [isdescription, setIsDescription] = useState(false)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [isPlannedStartDate, setIsPlannedStartDate] = useState(false)
  const [isPlannedEndDate, setisPlannedEndDate] = useState(false)
  const [isPlannedEndDateGreater, setisPlannedEndDateGreater] = useState(false)
  const [isActualEndDateGreater, setisActualEndDateGreater] = useState(false)
  const currentUrl = window.location.href
  const parts = currentUrl.split("/")
  const id = parts[parts.length - 1]
  const isCreateMode = currentUrl.includes("CreateProject")
  const isEditMode = currentUrl.includes("EditProject")
  const breadcrumbItems = [
    { label: "Projects", path: "/ProjectManagement" },
    { label: isCreateMode ? "Create Project" : "Edit Project", path: "/" },
  ]
  const stripHtmlTags = html => {
    const doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent || ""
  }

  let config = joditConfig()
  if (editPrrojectData?.planned_cost && isEditMode) {
    let costData = editPrrojectData?.planned_cost.split(" ")
    var plannedCosts = costData[0]
    var currency = costData[1]
  }

  useEffect(() => {
    if (isEditMode) {
      const bodyOption = { project_id: editPrrojectData?.id }
      dispatch(TagStatusData(bodyOption))
    } else {
      const bodyOption = { project_id: null }
      dispatch(TagStatusData(bodyOption))
    }
    dispatch(fetchCountries())
    if (description) {
      setIsDescription(false)
    }
    if (startDate) {
      setIsPlannedStartDate(false)
    }
    if (endDate) {
      setisPlannedEndDate(false)
    }
  }, [dispatch, description, startDate, endDate])

  const project_Status = tagStatusData?.Project_Status
    ? Object.values(tagStatusData.Project_Status)
        .flatMap(item => item)
        .map(value => ({
          value: value,
          label: value,
        }))
    : []

  const Project_Type = tagStatusData?.Project_Type
    ? Object.values(tagStatusData.Project_Type)
        .flatMap(item => item)
        .map(value => ({
          value: value,
          label: value,
        }))
    : []

  const formatDate = date => {
    if (!date) return ""
    const [year, month, day] = [
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate(),
    ]
    return `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`
  }

  const getUniqueOptions = options => {
    const uniqueOptions = []
    const labels = new Set()

    options.forEach(option => {
      if (!labels.has(option.label)) {
        uniqueOptions.push(option)
        labels.add(option.label)
      }
    })

    return uniqueOptions
  }

  let uniqueCountries
  if (countries) {
    uniqueCountries = getUniqueOptions(countries)
  }

  useEffect(() => {
    if (isEditMode && editPrrojectData) {
      validationType.setValues({
        projectName: editPrrojectData.project_name,
        projShortCode: editPrrojectData.project_short_code,
        projectType: editPrrojectData.project_type,
        teamSize: editPrrojectData.team_size,
        plannedEffort: editPrrojectData.planned_effort,
        plannedCost: plannedCosts,
        projectStatus: editPrrojectData.project_status,
        currency: currency,
        actualStartDate: editPrrojectData.actual_start_date,
        actualEndDate: editPrrojectData.actual_end_date,
      })
      setDescription(editPrrojectData.project_description)
      setStartDate(editPrrojectData.planned_start_date)
      setEndDate(editPrrojectData.planned_end_date)
    }
  }, [isEditMode, editPrrojectData])

  const validationType = useFormik({
    enableReinitialize: true,
    initialValues: {
      projectName: isEditMode ? editPrrojectData?.project_name : "",
      projShortCode: isEditMode ? editPrrojectData?.project_short_code : "",
      projectType: isEditMode ? editPrrojectData?.project_type : "",
      teamSize: isEditMode ? editPrrojectData?.team_size : "",
      plannedEffort: isEditMode ? editPrrojectData?.planned_effort : "",
      plannedCost: isEditMode ? plannedCosts : "",
      projectStatus: isEditMode ? editPrrojectData?.project_status : "",
      currency: isEditMode ? currency : "",
      actualStartDate: isEditMode ? editPrrojectData?.actual_start_date : "",
      actualEndDate: isEditMode ? editPrrojectData?.actual_end_date : "",
    },
    validationSchema: Yup.object().shape({
      projectName: Yup.string()
        .trim()
        .matches(/^.*\S.*$/, "Project name cannot contain only spaces")
        .required("Project name is required"),
      projShortCode: Yup.string()
        .matches(
          "^[A-Z]{2,5}$",
          "Project short code should contain 2 to 5 uppercase characters"
        )
        .required("Project short code is required"),
      projectType: Yup.string().required("Project type is required"),
      teamSize: Yup.number()
        .positive("Team size must be a positive number")
        .required("Team size is required"),
      plannedEffort: Yup.string()
        .trim()
        .matches(/^.*\S.*$/, "Planned effort cannot contain only spaces")
        .required("Planned effort is required"),
      projectStatus: Yup.string().required("Project status is required"),
    }),

    onSubmit: async values => {
      let isValid = true
      const cleanDescription = stripHtmlTags(description).trim()

      if (!cleanDescription) {
        setIsDescription(true)
        isValid = false
      }
      if (!startDate) {
        setIsPlannedStartDate(true)
        isValid = false
      }
      if (!endDate) {
        setisPlannedEndDate(true)
        isValid = false
      }

      // Compare startDate and endDate only if both are present
      if (startDate && endDate && new Date(endDate) <= new Date(startDate)) {
        setisPlannedEndDateGreater(true)
        isValid = false
      } else {
        setisPlannedEndDateGreater(false)
      }

      if (values.actualStartDate && values.actualEndDate) {
        if (
          new Date(values.actualEndDate) <= new Date(values.actualStartDate)
        ) {
          setisActualEndDateGreater(true)
          isValid = false
        } else {
          setisActualEndDateGreater(false)
        }
      }

      if (isValid) {
        let tempDiv = document.createElement("div")
        tempDiv.innerHTML = description
        let extractedText = tempDiv.innerText
        const descriptionData = extractedText

        const formattedValues = {
          ...values,
          actualStartDate: values.actualStartDate
            ? formatDate(new Date(values.actualStartDate))
            : "",
          actualEndDate: values.actualEndDate
            ? formatDate(new Date(values.actualEndDate))
            : "",
        }

        const formData = new FormData()
        formData.append("project_type", formattedValues.projectType)
        formData.append("project_status", formattedValues.projectStatus)
        formData.append("project_name", formattedValues.projectName)
        formData.append("planned_effort", formattedValues.plannedEffort)
        formData.append(
          "planned_cost",
          `${formattedValues?.plannedCost} ${
            formattedValues?.currency ? formattedValues?.currency : ""
          }`
        )
        formData.append("project_short_code", formattedValues.projShortCode)
        formData.append("team_size", formattedValues.teamSize)
        formData.append("planned_start_date", formatDate(new Date(startDate)))
        formData.append("planned_end_date", formatDate(new Date(endDate)))
        formData.append("actual_start_date", formattedValues.actualStartDate)
        formData.append("actual_end_date", formattedValues.actualEndDate)
        formData.append("project_description", description)

        if (isEditMode && editPrrojectData) {
          try {
            const bodyoptions = {
              id: editPrrojectData?.id,
              project_type: formattedValues.projectType,
              project_status: formattedValues.projectStatus,
              project_name: formattedValues.projectName,
              planned_effort: formattedValues.plannedEffort,
              planned_cost: `${formattedValues?.plannedCost} ${
                formattedValues?.currency ? formattedValues?.currency : ""
              }`,
              project_short_code: formattedValues.projShortCode,
              team_size: formattedValues.teamSize,
              planned_start_date: formatDate(new Date(startDate)),
              planned_end_date: formatDate(new Date(endDate)),
              actual_start_date: formattedValues.actualStartDate,
              actual_end_date: formattedValues.actualEndDate,
              project_description: description,
            }

            const response = await dispatch(
              EditProject(editPrrojectData?.id, bodyoptions)
            )
            if (response?.status) {
              navigate("/ProjectManagement")
              dispatch(fetchProjectForSelectProject())
            }
          } catch (error) {
            console.error("Failed to submit form:", error.message)
          }
        } else {
          createProject(formData)
        }
      }
    },
  })

  const onCancel = () => {
    validationType.resetForm()
    setIsDescription(false)
    setIsPlannedStartDate(false)
    setisPlannedEndDate(false)
    navigate("/ProjectManagement")
  }

  const renderInput = (
    name,
    label,
    type = "text",
    placeholder = "",
    isInvalid,
    isRequired = true,
    isEditMode
  ) => (
    <div className="mb-3">
      <Label className="form-label">
        {label} {isRequired && <span className="text-danger">*</span>}
      </Label>
      <Input
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={e => {
          let value = e.target.value
          if (name === "projShortCode") {
            value = value.toUpperCase() // Convert to uppercase
          }
          validationType.setFieldValue(name, value) // Set the value in form state
        }}
        onBlur={validationType.handleBlur}
        value={validationType.values[name] || ""}
        invalid={isInvalid(validationType, name)}
        className="custom-input"
        disabled={isEditMode} // Use the correct prop here
      />
      {validationType.touched[name] && validationType.errors[name] && (
        <span className="custom-error-message">
          {validationType.errors[name]}
        </span>
      )}
    </div>
  )

  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888",
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Ensure input text is visible
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150, // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  }

  const renderSelect = (
    name,
    label,
    placeholder,
    options,
    isInvalid,
    isRequired = true,
    customProps = {}
  ) => (
    <div className="mb-3">
      <Label className="form-label">
        {label} {isRequired && <span className="text-danger">*</span>}
      </Label>
      <Select
        name={name}
        placeholder={placeholder}
        onChange={option => validationType.setFieldValue(name, option?.value)}
        onBlur={() => validationType.setFieldTouched(name)}
        options={options}
        className={`select2-selection ${
          isInvalid(validationType, name) ? "is-invalid" : ""
        }`}
        {...customProps}
        maxMenuHeight={200}
        isClearable={true}
        value={options.find(
          option => option.value === validationType.values[name]
        )}
        styles={customStyles}
      />
      {validationType.touched[name] && validationType.errors[name] && (
        <FormFeedback className="custom-error-message">
          {validationType.errors[name]}
        </FormFeedback>
      )}
    </div>
  )

  const renderDatePicker = (
    name,
    label,
    isInvalid,
    showError,
    isRequired = true
  ) => (
    <div className="mb-3">
      <Label>{label}</Label>
      <InputGroup>
        <Flatpickr
          className={`form-control d-block ${
            isInvalid(validationType, name) ? "is-invalid" : ""
          }`}
          name={name}
          value={validationType.values[name]}
          onChange={date =>
            validationType.setFieldValue(name, formatDate(date[0]))
          }
          onBlur={() => validationType.setFieldTouched(name)}
          options={{ altInput: true, altFormat: "d/m/Y", dateFormat: "Y-m-d" }}
          placeholder="dd/mm/yyyy"
        />
      </InputGroup>
      {isActualEndDateGreater && name === "actualEndDate" && (
        <span className="custom-error-message">
          Actual end date should be greater than Actual start date.
        </span>
      )}
    </div>
  )

  const isInvalid = (validationType, name) =>
    validationType.touched[name] && validationType.errors[name]

  const createProject = async formData => {
    try {
      const response = await httpInjectorService.createProject(formData)
      if (response.non_field_errors) {
        const errorMessage = response.non_field_errors.join(", ")
        toast.warning(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
      } else if (response.status) {
        toast.success("Project created successfully", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
        navigate("/ProjectManagement")
        setTimeout(() => {
          dispatch(fetchProjectForSelectProject())
        }, 1000)
      }
    } catch (err) {
      toast.err(response.err, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      })
    }
  }

  const onSubmit = () => {
    if (!startDate) {
      setIsPlannedStartDate(true)
    }
    if (!endDate) {
      setisPlannedEndDate(true)
    }
    if (!description) {
      setIsDescription(true)
    } else {
      setIsDescription(false)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={
              currentUrl.includes("CreateProject")
                ? "Create Project"
                : "Edit Project"
            }
            breadcrumbItems={breadcrumbItems}
          />

          <div className="mt-2 mx-1 col-12 d-flex justify-content-between">
            <div>
              <h3>{!isEditMode ? "Create Project" : "Edit Project"}</h3>
              <p className="text-muted">
                Fill the form to {!isEditMode ? "create" : "edit"} a project
              </p>
            </div>
            <div>
              <Button
                type="button"
                color="primary"
                className="btn-label"
                onClick={() => {
                  navigate("/ProjectManagement")
                }}
              >
                <i className="bx bx-arrow-back label-icon"></i> Go Back
              </Button>
            </div>
          </div>

          <Form
            onSubmit={e => {
              e.preventDefault()
              validationType.handleSubmit()
            }}
          >
            <Row>
              <Col lg={8}>
                <Card>
                  <CardBody>
                    {renderInput(
                      "projectName",
                      "Project Name",
                      "text",
                      "Project",
                      isInvalid,
                      true,
                      isEditMode
                    )}
                    {renderInput(
                      "projShortCode",
                      "Project Short Code",
                      "text",
                      "Short Code",
                      isInvalid,
                      true,
                      isEditMode
                    )}
                    {renderSelect(
                      "projectType",
                      "Project Type",
                      "Select Project Type",
                      Project_Type,
                      isInvalid
                    )}
                    {renderSelect(
                      "projectStatus",
                      "Project Status",
                      "Select Project Status",
                      project_Status,
                      isInvalid
                    )}
                    <Label>
                      Project Description <span className="text-danger">*</span>
                    </Label>
                    <JoditEditor
                      value={description}
                      config={config}
                      onBlur={newContent => {
                        setDescription(newContent)
                      }}
                    />
                    {isdescription && (
                      <div className="custom-error-message">
                        Project description is required
                      </div>
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col lg={4}>
                <Card>
                  <CardBody>
                    <div className="mb-3">
                      <Label>
                        Planned Start Date<span className="text-danger">*</span>
                      </Label>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block "
                          placeholder="dd/mm/yyyy"
                          options={{
                            altInput: true,
                            altFormat: "d/m/Y",
                            dateFormat: "Y-m-d",
                          }}
                          onChange={date => {
                            setStartDate(date)
                          }}
                          value={startDate}
                        />
                      </InputGroup>
                      {isPlannedStartDate && (
                        <span className="custom-error-message">
                          Project planned start date is required
                        </span>
                      )}
                    </div>
                    <div className="mb-3">
                      <Label>
                        Planned End Date
                        <span className="text-danger mt-2">*</span>
                      </Label>
                      <InputGroup>
                        <Flatpickr
                          className="form-control d-block"
                          placeholder="dd/mm/yyyy"
                          options={{
                            altInput: true,
                            altFormat: "d/m/Y",
                            dateFormat: "Y-m-d",
                          }}
                          onChange={date => {
                            setEndDate(date)
                          }}
                          value={endDate}
                        />
                      </InputGroup>
                      {isPlannedEndDate && (
                        <span className="custom-error-message">
                          Project planned end date is required
                        </span>
                      )}
                      {isPlannedEndDateGreater && (
                        <span className="custom-error-message">
                          Planned end date should be greater than Planned start
                          date.
                        </span>
                      )}
                    </div>
                    {renderDatePicker(
                      "actualStartDate",
                      "Actual Start Date",
                      isInvalid,
                      false
                    )}
                    {renderDatePicker(
                      "actualEndDate",
                      "Actual End Date",
                      isInvalid,
                      false
                    )}
                    {renderInput(
                      "plannedEffort",
                      "Planned Effort",
                      "text",
                      "Enter Planned Effort",
                      isInvalid
                    )}
                    <Row>
                      <Col>
                        {renderSelect(
                          "currency",
                          "Planned Cost",
                          "Currency",
                          uniqueCountries,
                          isInvalid,
                          false,
                          {
                            options: uniqueCountries,
                            placeholder: "Currency",
                            isClearable: true,
                          }
                        )}
                      </Col>
                      <Col style={{ marginTop: "8px" }}>
                        {renderInput(
                          "plannedCost",
                          " ",
                          "number",
                          "0",
                          isInvalid,
                          false
                        )}
                      </Col>
                    </Row>
                    {renderInput(
                      "teamSize",
                      "Team Size",
                      "number",
                      "Enter Team Size",
                      isInvalid
                    )}
                  </CardBody>
                </Card>
                <CardBody>
                  <div className="d-flex justify-content-end mb-2 flex-wrap gap-2 mt-5">
                    <Button type="submit" color="primary" onClick={onSubmit}>
                      Submit
                    </Button>
                    <Button type="reset" color="secondary" onClick={onCancel}>
                      Cancel
                    </Button>
                  </div>
                </CardBody>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreateProject
