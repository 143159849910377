import { useMemo } from "react"
import { useSelector } from "react-redux"

const getStatusColor = data => {
  let color = null

  switch (data) {
    case "In Progress":
    case "NOTRUN":
      color = "rgba(181, 71, 8, 1)"
      break
    case "To do":
      color = "#000C"
      break
    case "Done":
      color = "#1a0736"
      break
    case "QA":
      color = "#70123e"
      break
    case "Open":
      color = "#1a5723"
      break
    case "Fixed":
      color = "#0f486b"
      break
    case "Close":
      color = "#1221a3"
      break
    case "Reopen":
      color = "#214008"
      break
    case "Resolved":
      color = "#1b1b66"
      break
    case "Not A Bug":
      color = "#780707"
      break
    case "On Hold":
      color = "rgba(52, 64, 84, 1)"
      break
    case "Completed":
      color = "rgba(6, 118, 71, 1)"
      break
    case "Cancelled":
    case "Canceled":
    case "Rejected":
    case "FAILED":
    case "On-Hold":
      color = "rgba(180, 35, 24, 1)"
      break
    case "Deferred":
      color = "rgba(54, 63, 114, 1)"
      break
    case "New":
      color = "rgba(2, 106, 162, 1)"
      break

    case "Closed":
      color = "rgb(209 68 57 / 67%)"
      break
    case "Pending":
    case "In-Progress":
    case "Pending Approval":
      color = "rgb(181 71 8 / 68%)"
      break
    case "Approved":
    case "PASSED":
      color = "#067647"
      break

    case "Passed":
      color = "rgba(6, 118, 71, 1)"
      break
    case "Achieved":
      color = "rgb(6, 118, 71)"
      break
    case "TO Do":
      color = "rgb(2, 106, 162)"
      break
    case "To Do":
      color = "rgb(2, 106, 162)"
      break
    default:
      color = "rgba(18, 155, 47, 1)"
      break
  }
  return color
}

const getStatusBackGroundColor = data => {
  let color = null

  switch (data) {
    case "In Progress":
    case "In-Progress":
    case "NOTRUN":
      color = "warning"
      break
    case "On Hold":
      color = "secondary"
      break
    case "Cancelled":
    case "Canceled":
    case "Rejected":
    case "SignOff Rejected":
    case "Rejected":
    case "FAILED":
    case "On-Hold":
      color = "danger"
      break
    case "Deferred":
      color = "secondary"
      break
    case "Closed":
    case "Pending Approval":
      color = "warning"
      break
    case "Completed":
    case "PASSED":
    case "Approved":
    case "SignOff Approved":
      color = "success"
      break
    case "New":
      color = "info"
      break
    case "Achieved":
      color = "primary"
      break
    case "To Do":
      color = "primary"
      break
    case "TO Do":
      color = "primary"
      break

    case "To do":
      color = "primary"
      break
    case "Done":
      color = "success"
      break
    case "QA":
      color = "#fae8f0"
      break
    case "Open":
      color = "primary"
      break
    case "Fixed":
      color = "success"
      break
    case "Close":
      color = "success"
      break
    case "Reopen":
      color = "#d8ebca"
      break
    case "Resolved":
      color = "success"
      break
    case "Not A Bug":
      color = "#e8cccc"
      break
    default:
      color = "secondary"
      break
  }
  return color
}

const getStatusBorderColor = data => {
  let color = null

  switch (data) {
    case "In Progress":
    case "In-Progress":
    case "NOTRUN":
    case "Pending Approval":
    case "on-going":
      color = "rgba(254, 223, 137, 1)"
      break
    case "On Hold":
      color = "rgba(234, 236, 240, 1)"
      break
    case "Cancelled":
    case "Canceled":
    case "Rejected":
    case "FAILED":
    case "On-Hold":
      color = "rgba(254, 205, 202, 1)"
      break
    case "Deferred":
      color = "rgba(213, 217, 235, 1)"
      break
    case "Completed":
    case "Approved":
    case "PASSED":
      color = "rgba(171, 239, 198, 1)"
      break
    case "New":
      color = "rgba(185, 230, 254, 1)"
      break
    case "Achieved":
    case "TO Do":
      color = "rgb(185, 230, 254)"
      break
    case "To Do":
      color = "rgb(185, 230, 254)"
      break
    case "To do":
      color = "#000C"
      break
    case "Done":
      color = "#1a0736"
      break
    case "QA":
      color = "#70123e"
      break
    case "Open":
      color = "#1a5723"
      break
    case "Fixed":
      color = "#0f486b"
      break
    case "Close":
      color = "#1221a3"
      break
    case "Reopen":
      color = "#214008"
      break
    case "Resolved":
      color = "#1b1b66"
      break
    case "Not A Bug":
      color = "#780707"
      break
    default:
      color = "rgba(171, 239, 198, 1)"
      break
  }
  return color
}

// const joditConfig = () => {
//   const layoutModeType = useSelector(state => state.Layout.layoutModeType)

//   return useMemo(
//     () => ({
//       readonly: false,
//       license: "A599E-1GG61-CDN5N-C86DX", // Add your license key here
//       uploader: {
//         url: "https://xdsoft.net/jodit/finder/?action=fileUpload",
//       },
//       filebrowser: {
//         ajax: {
//           url: "https://xdsoft.net/jodit/finder/",
//         },
//         height: 580,
//       },
//       toolbarAdaptive: false,
//       toolbarButtonSize: "medium",
//       showCharsCounter: false,
//       showWordsCounter: false,
//       showXPathInStatusbar: false,
//       disablePlugins: "add-new-line",
//       theme: layoutModeType === "dark" ? "dark" : "default",
//       style: {
//         ".jodit-ui-text-area__input": {
//           background:
//             layoutModeType === "dark"
//               ? "#2e2e2e !important"
//               : "#fff !important", // Text area background
//           color:
//             layoutModeType === "dark" ? "#fff !important" : "#000 !important", // Text color
//         },
//         ".jodit-toolbar": {
//           background: layoutModeType === "dark" ? "#3e3e3e" : "#fff", // Toolbar background
//         },
//         ".jodit-container": {
//           background: layoutModeType === "dark" ? "#2e2e2e" : "#fff", // Editor container background
//         },
//       },
//       buttons:
//         "bold,italic,underline,brush,strikethrough,eraser,ul,ol,font,fontsize,indent,outdent,left,undo,redo,paragraph,lineHeight,superscript,subscript,cut,ai-commands,ai-assistant,spellcheck,selectall",
//       // extraButtons: ['ai-assistant'], // Ensure AI Assistant is included
//       aiAssistant: {
//         apiKey: "sk-proj-3FE1IBc7saUnGkhedL3UT3BlbkFJp2E0HfOXcdfVj9XNRxHo",
//         model: "gpt-3.5-turbo", // Example model, adjust as needed
//         aiAssistantCallback: (prompt, htmlFragment) => {
//           // Make API call to OpenAI
//           return fetch("https://api.openai.com/v1/chat/completions", {
//             method: "POST",
//             headers: {
//               "Content-Type": "application/json",
//               Authorization:
//                 "Bearer " +
//                 "sk-proj-3FE1IBc7saUnGkhedL3UT3BlbkFJp2E0HfOXcdfVj9XNRxHo", // Ensure the API key is correct
//             },
//             body: JSON.stringify({
//               model: "gpt-3.5-turbo",
//               messages: [
//                 {
//                   role: "system",
//                   content: prompt,
//                 },
//                 {
//                   role: "user",
//                   content: htmlFragment,
//                 },
//               ],
//             }),
//           })
//             .then(response => response.json())
//             .then(data => {
//               if (data.error) {
//                 throw new Error(data.error.message)
//               }

//               return data.choices[0].message.content ?? ""
//             })
//         },
//       },
//       placeholder: "",
//     }),
//     [layoutModeType]
//   )
// }
const joditConfig = () => {
  const layoutModeType = useSelector(state => state.Layout.layoutModeType)

  return useMemo(
    () => ({
      readonly: false,
      license: process.env.REACT_APP_JODIT_KEY, // Add your license key here
      uploader: {
        url: process.env.REACT_APP_JODIT_UPLOADER_URL,
      },
      filebrowser: {
        ajax: {
          url: process.env.REACT_APP_JODIT_FILE_BROWSER_URL,
        },
        height: 580,
      },
      toolbarAdaptive: false,
      toolbarButtonSize: "medium",
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      disablePlugins: "add-new-line",
      theme: layoutModeType === "dark" ? "dark" : "default",
      style: {
        ".jodit-ui-text-area__input": {
          backgroundColor:
            layoutModeType === "dark"
              ? "#2e2e2e !important"
              : "#fff !important", // Text area background
          color:
            layoutModeType === "dark" ? "#fff !important" : "#000 !important", // Text color
          border:
            layoutModeType === "dark" ? "1px solid #555" : "1px solid #ddd", // Border for better visibility in dark mode
        },
        ".jodit-ui-text-area__wrapper": {
          backgroundColor:
            layoutModeType === "dark"
              ? "#2e2e2e !important"
              : "#fff !important", // Wrapper background
        },
        ".jodit-toolbar": {
          background: layoutModeType === "dark" ? "#3e3e3e" : "#fff", // Toolbar background
        },
        ".jodit-container": {
          background: layoutModeType === "dark" ? "#2e2e2e" : "#fff", // Editor container background
        },
      },
      buttons:
        "bold,italic,underline,brush,strikethrough,eraser,ul,ol,font,fontsize,indent,outdent,left,undo,redo,paragraph,lineHeight,superscript,subscript,cut,ai-commands,ai-assistant,spellcheck,selectall",
      aiAssistant: {
        apiKey: process.env.REACT_APP_JODIT_API_KEY,
        model: "gpt-3.5-turbo", // Example model, adjust as needed
        aiAssistantCallback: (prompt, htmlFragment) => {
          // Make API call to OpenAI
          return fetch("https://api.openai.com/v1/chat/completions", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + process.env.REACT_APP_JODIT_API_KEY, // Ensure the API key is correct
            },
            body: JSON.stringify({
              model: "gpt-3.5-turbo",
              messages: [
                {
                  role: "system",
                  content: prompt,
                },
                {
                  role: "user",
                  content: htmlFragment,
                },
              ],
            }),
          })
            .then(response => response.json())
            .then(data => {
              if (data.error) {
                throw new Error(data.error.message)
              }

              return data.choices[0].message.content ?? ""
            })
        },
      },
      placeholder: "",
    }),
    [layoutModeType]
  )
}

// const createTagsOptions = response => {
//   if (!response) {
//     return []
//   }

//   const data = response
//   let options = []

//   for (const Items of Object.values(data)) {
//     // Map Items to the desired format
//     const formattedOptions = Items.map(value => ({
//       value: value,
//       label: value,
//     }))

//     // Combine the formatted options into the options array
//     options = options.concat(formattedOptions)
//   }

//   return options
// }
const createTagsOptions = response => {
  if (!response) {
    return []
  }

  // Get the keys of the response object and create options from them
  return Object.keys(response).map(key => ({
    value: key,
    label: key,
  }))
}
const createOptions = response => {
  if (!response) {
    return []
  }

  const data = response
  let options = []

  for (const Items of Object.values(data)) {
    // Map Items to the desired format
    const formattedOptions = Items.map(value => ({
      value: value,
      label: value,
    }))

    // Combine the formatted options into the options array
    options = options.concat(formattedOptions)
  }

  return options
}
const createOptionsDefectType = defectTypesResponse => {
  if (!defectTypesResponse) {
    console.log("Defect_Type data is not available")
    return []
  }

  const defectTypes = defectTypesResponse
  const options = []
  for (const [label, defects] of Object.entries(defectTypes)) {
    // Skip the "meta_tag" key
    // if (label === "meta_tag") continue;

    // Map defects to the desired format
    const formattedOptions = defects.map(defect => ({
      value: defect,
      label: defect,
    }))

    // Add the formatted section to the options array
    options.push({
      label: `--- ${label} ---`,
      options: formattedOptions,
    })
  }

  return options
}

const createOptionsForReports = response => {
  if (!response) {
    return []
  }

  const data = response
  let options = []

  // Add the default "All" option
  options.push({ value: "", label: "All" })

  for (const Items of Object.values(data)) {
    // Map Items to the desired format
    const formattedOptions = Items.map(value => ({
      value: value,
      label: value,
    }))

    // Combine the formatted options into the options array
    options = options.concat(formattedOptions)
  }

  return options
}

import CryptoJS from "crypto-js"

const getToken = () => {
  const token = localStorage.getItem("authUser")
  let accessToken = ""

  if (token) {
    try {
      const parsedToken = JSON.parse(token)

      // Decrypt the access token
      if (parsedToken?.access_token) {
        const bytes = CryptoJS.AES.decrypt(
          parsedToken.access_token,
          process.env.REACT_APP_ENCRYPTION_KEY
        )
        accessToken = bytes.toString(CryptoJS.enc.Utf8) // Decrypted access token
      }
    } catch (error) {
      console.error("Error decrypting token:", error)
    }
  }

  return accessToken
}

export {
  getStatusColor,
  getStatusBackGroundColor,
  getStatusBorderColor,
  joditConfig,
  createOptions,
  createTagsOptions,
  createOptionsForReports,
  createOptionsDefectType,
  getToken,
}
