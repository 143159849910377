import { createSlice } from "@reduxjs/toolkit"

const ProjectSlice = createSlice({
  name: "Project",
  initialState: {
    allProjectList: [],
    ProjectList: [],
    teamsAvailableList: [],
    ProjectDetailsView: [],
    tagStatus: [],
    requirementDetails: [],
    moduleDetails: [],
    teamDetails: [],
    buildDetails: [],
    requirementId: [],
    selectedRequirements: [],
    teamsUserList: [],
    teamsAllUserList: [],
    projectEditData: [],
    SelProject: [],
    projectFilterFrom: {
      project_status: null,
      project_type: null,
      planned_end_date: null,
    },
  },

  reducers: {
    setAllProjectList: (state, action) => ({
      ...state,
      allProjectList: action.payload,
    }),
    setProjectFilterFrom: (state, action) => {
      state.projectFilterFrom = action.payload
    },
    setProjectList: (state, action) => ({
      ...state,
      ProjectList: action.payload,
    }),
    setProjectDetailsView: (state, action) => ({
      ...state,
      ProjectDetailsView: action.payload,
    }),
    setBuildsDetails: (state, action) => ({
      ...state,
      buildDetails: action.payload,
    }),
    setTeamsDetails: (state, action) => ({
      ...state,
      teamDetails: action.payload,
    }),
    setModuleDetails: (state, action) => ({
      ...state,
      moduleDetails: action.payload,
    }),
    setRequirementDetails: (state, action) => ({
      ...state,
      requirementDetails: action.payload,
    }),
    setTagStatus: (state, action) => ({
      ...state,
      tagStatus: action.payload,
    }),
    setGeneratedReqId: (state, action) => ({
      ...state,
      requirementId: action.payload,
    }),
    setSelectedRequirements: (state, action) => ({
      ...state,
      selectedRequirements: action.payload,
    }),
    setTeamsUserDetails: (state, action) => ({
      ...state,
      teamsUserList: action.payload,
    }),
    setTeamsAllUserDetails: (state, action) => ({
      ...state,
      teamsAllUserList: action.payload,
    }),
    setEditProjectData: (state, action) => ({
      ...state,
      projectEditData: action.payload,
    }),
    setSelProject: (state, action) => ({
      ...state,
      SelProject: action.payload,
    }),
  },
})

export const {
  setAllProjectList,
  setTeamsAvailableList,
  setProjectDetailsView,
  setTagStatus,
  setRequirementDetails,
  setModuleDetails,
  setTeamsDetails,
  setBuildsDetails,
  setGeneratedReqId,
  setSelProject,
  setSelectedRequirements,
  setTeamsUserDetails,
  setTeamsAllUserDetails,
  setEditProjectData,
  setProjectList,
  setProjectFilterFrom,
} = ProjectSlice.actions

export default ProjectSlice.reducer
