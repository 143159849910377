import { toast } from "react-toastify"
import httpInjectorService from "services/http-Injector.Service"
import { setLoading } from "store/Slices/LoadingSlice"
import {
  setTeamsAllUserDetails,
  setTeamsUserDetails,
  setEditProjectData,
  setProjectList,
  setTagStatus,
  setSelProject,
} from "store/Slices/ProjectsSlice"

export const teamDetails = bodyoption => {
  return async dispatch => {
    let queryString = "?"
    dispatch(setLoading(true))
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    try {
      const response = await httpInjectorService.teamDetails(queryString)
      const data = response
      dispatch(setTeamsUserDetails(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const fetchTeamsAvailable = bodyoption => {
  return async dispatch => {
    let queryString = "?"
    dispatch(setLoading(true))
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    try {
      const response = await httpInjectorService.teamsAvailable(queryString)
      const data = response
      dispatch(setTeamsAllUserDetails(data))
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const setProjEditData = data => {
  return async dispatch => {
    dispatch(setEditProjectData(data))
  }
}

export const EditProject = (id, bodyoption) => {
  return async dispatch => {
    dispatch(setLoading(true))
    const formData = new FormData()
    // Add form data if not null
    for (const key in bodyoption) {
      if (
        bodyoption[key] !== undefined &&
        bodyoption[key] !== null &&
        bodyoption[key] !== ""
      ) {
        formData.append(key, bodyoption[key])
      }
    }
    try {
      const response = await httpInjectorService.EditProject(id, formData)
      if (response?.status) {
        toast.success(response.message, {
          position: "top-right",
          id: "Project updated successfully",
          autoClose: 3000,
        })
        dispatch(setLoading(false))
      } else {
        toast.warning(response.message, {
          position: "top-right",
          autoClose: 3000,
        })
      }
      return response
    } catch (error) {
      dispatch(setLoading(false))
      throw error
    }
  }
}

export const fetchAllProjectList = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.projectDetails(queryString)
      const data = response
      dispatch(setLoading(false))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const TagStatusData = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = "?"
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.tagStatusEditData(queryString)
      const data = response.data
      dispatch(setLoading(false))
      dispatch(setTagStatus(data))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const ProjectList = bodyoption => {
  return async dispatch => {
    dispatch(setLoading(true))
    let queryString = ""
    Object.entries(bodyoption).forEach(([key, value]) => {
      if (value !== null && value !== undefined && value !== "") {
        queryString += `${key}=${value}&`
      }
    })
    if (queryString.charAt(queryString.length - 1) === "&") {
      queryString = queryString.slice(0, -1)
    }
    dispatch(setLoading(true))
    try {
      const response = await httpInjectorService.ProjectList(queryString)
      const data = response
      dispatch(setLoading(false))
      dispatch(setProjectList(data))
      return data
    } catch (error) {
      dispatch(setLoading(false))
      throw new Error(error.message)
    }
  }
}

export const SelProject = data => {
  return async dispatch => {
    dispatch(setSelProject(data))
  }
}
