import Breadcrumb from "components/Common/Breadcrumb"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { getStatusBackGroundColor, joditConfig } from "helpers/helper"
import { useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
} from "reactstrap"
import Select from "react-select"
import {
  getAllDefectsListAPI,
  GetDefectByIdAPI,
} from "store/actions/DefectsActions"
import JoditEditor from "jodit-react"
import {
  ExecuteScriptMappings,
  GetTestCycleExecuteByIdAPI,
  SuitePageChartData,
  TriggerWorkFlow,
  updateExecute,
  UpdateScriptStatus,
} from "store/actions/TestCycleActions"
import { toast } from "react-toastify"
import SuitePageChart from "./SuitePageChart"
import { setReLoading } from "store/Slices/LoadingSlice"
import { setEditDefect } from "store/Slices/DefectsSlice"
import { GetAutomationScriptView } from "store/actions/AutomationScriptAction"
import Dropzone from "react-dropzone"
import BASE_URL from "services/apiConfig"
import { GetAutomationSuiteByIdAPI } from "store/actions/AutomationSuiteAction"
import { getAllIntegrationListAPI } from "store/actions/IntegrationAction"

const ExecuteTestSuite = () => {
  const options = [
    { value: "NOTRUN", label: "NOT RUN" },
    { value: "PASSED", label: "PASSED" },
    { value: "FAILED", label: "FAILED" },
  ]

  const projectLocalId = JSON.parse(localStorage.getItem("selectedProject"))
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const viewTestCaseData = useSelector(
    state => state?.ActionSuite?.singleAutomationSuiteData
  )

  const executePageContent = useSelector(state => state?.TestCycle?.executePage)

  const stripHtmlTags = html => {
    const doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent || ""
  }

  const [time, setTime] = useState("")
  const [comments, setComments] = useState("")
  const [defectOptions, setDefectOptions] = useState([])

  const [defects, setDefects] = useState(null)
  const [status, setStatus] = useState(null)
  const [defectDetails, setDefectDetails] = useState([])
  const [scriptSelectedDetails, setScriptSelectedDetails] = useState([])
  const [defectDetailSelected, setDefectDetailSelected] = useState([])
  const [validationErrMsg, setValidationErrMsg] = useState({})
  const [defectArray, setDefectArray] = useState(null)
  const [loading, setLoading] = useState(false)
  const [log, setLog] = useState(false)
  const [logLink, setLogLink] = useState("")
  const [report, setReport] = useState(false)
  const [reportLink, setReportLink] = useState("")
  const [selectedFiles, setSelectedFiles] = useState([])
  const [selectedDeleteFiles, setSelectedDeleteFiles] = useState([])

  useEffect(() => {
    if (executePageContent) {
      if (executePageContent?.excution_log) {
        setLog(true)
        setLogLink(executePageContent?.excution_log)
      }
      if (executePageContent?.report_url) {
        setReport(true)
        setReportLink(executePageContent?.report_url)
      }
      setTime(executePageContent.excution_time || "")
      setComments(executePageContent.comments || "")
      setDefects(
        executePageContent.defect_names
          ? executePageContent.defect_names.map(defect => ({
              label: defect,
              value: defectOptions?.find(option => option?.label == defect)
                ?.value,
            }))
          : []
      )
      setStatus(
        executePageContent.excution_status === "NOTRUN"
          ? {
              label: "NOT RUN",
              value: executePageContent?.excution_status,
            }
          : {
              label: executePageContent?.excution_status,
              value: executePageContent?.excution_status,
            }
      )
      if (executePageContent?.attachment) {
        const defectArrays = Array.isArray(executePageContent?.attachment)
          ? executePageContent?.attachment
          : [executePageContent?.attachment]
        const existingFiles = defectArrays?.map((file, index) => ({
          name: `Existing File ${index + 1}`,
          path: file, // Assuming this is the file path
          isExisting: true,
        }))
        setSelectedDeleteFiles(existingFiles)
        setSelectedFiles(existingFiles)
      }
    }
  }, [defectOptions, executePageContent])

  useEffect(() => {
    const defectArrays = Array.isArray(defects) ? defects : [defects]
    setDefectArray(defectArrays)
    const selectedDetails = defectArrays?.map(defect => {
      return defectDetails.find(detail => detail.id === defect.value)
    })
    setDefectDetailSelected(selectedDetails)
  }, [defects])

  const fetchSuiteChart = async id => {
    let bodyOptions = { execution_id: executePageContent?.id }
    try {
      await dispatch(SuitePageChartData(bodyOptions))
      const data = await dispatch(ExecuteScriptMappings(bodyOptions))
      setScriptSelectedDetails(data)
      dispatch(setReLoading(false))
    } catch (error) {
      console.log(error)
    }
  }

  const fetchAllExecutionData = async () => {
    if (executePageContent?.id) {
      await dispatch(GetTestCycleExecuteByIdAPI(executePageContent?.id))
    }
  }
  useEffect(() => {
    let payload = { project_id: null }
    dispatch(getAllIntegrationListAPI(payload))
  }, [])

  const softReload = async () => {
    dispatch(setReLoading(true))
    fetchSuiteChart()
    await dispatch(GetAutomationSuiteByIdAPI(viewTestCaseData?.id))
    fetchAllExecutionData()
  }

  const handleExecute = async data => {
    let payload = {
      automation_suite_id: data?.id,
      excution_id: executePageContent?.id,
    }
    try {
      const dataExecute = await dispatch(TriggerWorkFlow(payload))
      if (dataExecute?.workflow_run_url) {
        setLog(true)
        setLogLink(dataExecute?.workflow_run_url)
        fetchAllExecutionData()
      }
    } catch (error) {
      console.log(error)

      toast.error(error?.message, {
        position: "top-right",
        autoClose: 3000,
      })
    }
  }

  useEffect(() => {
    if (viewTestCaseData?.length == 0) {
      navigate("/TestCycle")
    }
    fetchSuiteChart()
    fetchAllExecutionData()
  }, [])

  const viewDefect = async id => {
    if (defectPermission?.view) {
      try {
        const res = await dispatch(GetDefectByIdAPI(id))
        if (res) {
          navigate("/DefectManagement/ViewDefect")
        }
      } catch (error) {
        console.error("Error fetching data", error)
      }
    } else {
      toast.warn("You don't have permission to view defect", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      })
    }
  }

  const handleViewClick = async id => {
    if (suitePermission?.view) {
      try {
        const res = await dispatch(GetAutomationScriptView(id))
        if (res) {
          navigate("/TestAutomation/AutomationScript/ViewAutomationScript")
        }
      } catch (error) {
        console.error("Error fetching data", error)
      }
    } else {
      toast.warn("You don't have permission to view script", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      })
    }
  }

  const hanldeCreateDefect = () => {
    if (defectPermission?.create) {
      dispatch(setEditDefect({ isEdit: false, data: null }))
      navigate("/DefectManagement/CreateEditDefect")
    } else {
      toast.warn("You don't have permission to view defect", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      })
    }
  }

  const checkValidations = () => {
    const timeFormat = /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/
    const errors = {}
    if (!status) errors.status = "Status required"
    if (time) {
      if (!timeFormat.test(time)) errors.time = "Valid Time Format : HH:MM:SS"
    }
    return errors
  }

  const handleAcceptedFiles = files => {
    const newImages = files?.map(file => {
      return Object.assign(file, {
        priview: URL.createObjectURL(file),
      })
    })
    setSelectedFiles([...selectedFiles, ...newImages])
    setSelectedDeleteFiles([...selectedFiles, ...newImages])
  }

  const Permission = JSON.parse(localStorage.getItem("authUser"))
  const defectPermission =
    Permission?.permissions[0]?.menu_item?.DefectManagement?.defectmanagement
  const suitePermission =
    Permission?.permissions[0]?.menu_item?.TestLab?.Testautomation

  const hanldeSubmit = async () => {
    const validationErrors = checkValidations()
    if (Object.keys(validationErrors).length > 0) {
      setValidationErrMsg(validationErrors)
      return
    }

    setLoading(true)
    let bodyoptions
    bodyoptions = {
      project_id: projectLocalId?.value,
      excution_status: status.value,
      comments: comments,
      excution_time: time,
      test_case_id: executePageContent?.test_case_id,
      defect_id:
        defectArray?.length !== 0
          ? defectArray?.map(defect => defect.value)
          : undefined,
      id: executePageContent?.id,
      attachment: selectedFiles[0],
      clear_attachments: selectedFiles?.length === 0 ? true : false,
    }

    try {
      await dispatch(updateExecute(bodyoptions))
      setLoading(false)
      handleClose()
    } catch (error) {
      toast.warn(error?.message, {
        position: "top-right",
        autoClose: 3000,
      })
      console.log(validationErrMsg)
      setLoading(false)
      setValidationErrMsg({ form: error.message })
    }
  }

  const fetchDefectData = async () => {
    let bodyOption = { project_id: projectLocalId?.value }

    const defectData = await dispatch(getAllDefectsListAPI(bodyOption))
    const defectOptionValues = defectData?.map(entry => ({
      value: entry.id,
      label: entry.defect_name,
    }))
    setDefectOptions(defectOptionValues)
    setDefectDetails(defectData)
  }

  useEffect(() => {
    fetchDefectData()
  }, [projectLocalId?.value])

  const [AscriptStatus, setAscriptStatus] = useState([])

  const [scriptId, setScriptId] = useState(null)

  const handleScriptStatusChange = (selectedOption, index, script) => {
    const updatedOption = {
      ...selectedOption,
      label:
        selectedOption.value === "NOTRUN" ? "NOT RUN" : selectedOption.label,
    }

    const newStatus = [...AscriptStatus]
    newStatus[index] = updatedOption
    setAscriptStatus(newStatus)
    setScriptId({ id: script, index, selectedOption: updatedOption })
  }

  const layoutModeType = useSelector(state => state.Layout.layoutModeType)

  const customStyles = {
    control: provided => ({
      ...provided,
      minWidth: "200px",
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888",
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Ensure input text is visible
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150, // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  }

  useEffect(() => {
    if (scriptSelectedDetails) {
      const initialStatus = scriptSelectedDetails.map(script => ({
        value: script.script_status,
        label:
          script.script_status === "NOTRUN" ? "NOT RUN" : script.script_status,
      }))
      setAscriptStatus(initialStatus)
    }
  }, [scriptSelectedDetails])

  useEffect(() => {
    if (scriptId) {
      const updateStatus = async () => {
        const bodyOption = {
          id: scriptId.id.id,
          script_status: scriptId.selectedOption.value,
        }

        await dispatch(UpdateScriptStatus(bodyOption))
        const newStatus = [...AscriptStatus]
        newStatus[scriptId.index] = scriptId.selectedOption
        setAscriptStatus(newStatus)
        fetchSuiteChart()
      }
      updateStatus()
    }
  }, [scriptId])

  const handleClose = () => {
    navigate("/TestCycle/ViewTestCycleAutomation")
  }

  const mapProjectList = useSelector(state => state?.Integration?.mapList)
  const integrationList = useSelector(
    state => state?.Integration?.integrationList
  )

  const [gitHub, setGitHub] = useState(false)
  const [browserStack, setBrowserStack] = useState(false)

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  useEffect(() => {
    const filteredProjectList = mapProjectList?.filter(
      project => project.product === "GitHub"
    )
    const filteredProject = integrationList?.some(
      project => project.product === "BrowserStack"
    )

    const isGitHub = filteredProjectList?.some(
      defect => defect?.frothtestops_project === globalProject?.label
    )

    setGitHub(isGitHub)
    setBrowserStack(filteredProject)
  }, [mapProjectList, integrationList])

  const formatFilePath = path => {
    if (path?.includes("/media/media")) {
      return path.replace("media/media", "media")
    }
    return path
  }

  return (
    <div className="">
      <Container fluid>
        <div className="d-flex gap-2 mt-3 mb-1">
          <i
            className="mdi mdi-arrow-left label-icon me-2 fs-4"
            style={{ cursor: "pointer" }}
            onClick={handleClose}
            title="Back"
          ></i>
          <h5 className="text-dark">AUTOMATION SUITE EXECUTION</h5>
        </div>
        <SuitePageChart reload={softReload} />
        <div>
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Automation Suite Id</h6>
                    <p className="text-muted ">
                      {viewTestCaseData?.automation_suite_id
                        ? viewTestCaseData?.automation_suite_id
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Automation Suite Name</h6>
                    <p className="text-muted ">
                      {viewTestCaseData?.automation_suite_name
                        ? viewTestCaseData?.automation_suite_name
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Build</h6>
                    <p className="text-muted ">
                      {viewTestCaseData?.build ? viewTestCaseData?.build : "-"}
                    </p>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Created Date</h6>
                    <p className="text-muted ">
                      {viewTestCaseData?.created_at
                        ? moment(viewTestCaseData?.created_at).format(
                            "DD-MM-YYYY"
                          )
                        : "-"}
                    </p>
                  </div>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Description</h6>
                    <p className="text-muted ">
                      {viewTestCaseData?.description
                        ? stripHtmlTags(viewTestCaseData?.description) || "-"
                        : "-"}
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col sm={6}>
              <Card>
                <CardBody>
                  <div>
                    <h6 style={{ fontWeight: "550" }}>Application Type</h6>
                    <p className="text-muted ">
                      {viewTestCaseData?.automation_suit_type
                        ? viewTestCaseData?.automation_suit_type
                        : "-"}
                    </p>
                  </div>
                  {browserStack &&
                    viewTestCaseData &&
                    viewTestCaseData?.automation_suit_type === "Web" && (
                      <>
                        <div>
                          <h6 style={{ fontWeight: "550" }}>
                            Browserstack Browser Name
                          </h6>
                          <p className="text-muted ">
                            {executePageContent?.app_browser
                              ? executePageContent?.app_browser
                              : "-"}
                          </p>
                        </div>
                        <div>
                          <h6 style={{ fontWeight: "550" }}>
                            Browserstack Browser Version
                          </h6>
                          <p className="text-muted ">
                            {executePageContent?.version
                              ? executePageContent?.version
                              : "-"}
                          </p>
                        </div>
                      </>
                    )}
                  {browserStack &&
                    viewTestCaseData &&
                    viewTestCaseData?.automation_suit_type !== "Web" && (
                      <>
                        <div>
                          <h6 style={{ fontWeight: "550" }}>
                            Application Name
                          </h6>
                          <p className="text-muted ">
                            {executePageContent?.application_name
                              ? executePageContent?.application_name
                              : "-"}
                          </p>
                        </div>
                        <div>
                          <h6 style={{ fontWeight: "550" }}>
                            Browserstack Device Name
                          </h6>
                          <p className="text-muted ">
                            {executePageContent?.device_name
                              ? executePageContent?.device_name
                              : "-"}
                          </p>
                        </div>
                        <div>
                          <h6 style={{ fontWeight: "550" }}>
                            Browserstack Os Version
                          </h6>
                          <p className="text-muted ">
                            {executePageContent?.version
                              ? executePageContent?.version
                              : "-"}
                          </p>
                        </div>
                      </>
                    )}
                  {gitHub && (
                    <>
                      <div>
                        <h6 style={{ fontWeight: "550" }}>
                          Browserstack Local
                        </h6>
                        <div className="square-switch">
                          <input
                            type="checkbox"
                            id="square-switch3"
                            className="switch switch-bool"
                            disabled
                            defaultChecked={
                              executePageContent?.browser_stack_local
                            }
                          />
                          <label
                            htmlFor="square-switch3"
                            data-on-label="Yes"
                            data-off-label="No"
                          />
                        </div>
                      </div>
                      <div>
                        <h6 style={{ fontWeight: "550" }}>Execution Agent</h6>
                        <p className="text-muted ">
                          {executePageContent?.execution_agent
                            ? executePageContent?.execution_agent
                            : "-"}
                        </p>
                      </div>
                    </>
                  )}
                  <div>
                    <div className="d-flex  flex-column">
                      <h6 style={{ fontWeight: "550" }}>Execute</h6>
                      <i
                        className="text-primary fs-3 mdi mdi-play label-icon"
                        title="
          Click here to trigger the workflow"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          handleExecute(viewTestCaseData)
                        }}
                      ></i>

                      {log && (
                        <a
                          className="mb-2"
                          href={logLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: "none", color: "blue" }}
                          title="Click here to view the Log Reports"
                        >
                          {" "}
                          Execution_log
                        </a>
                      )}
                      {report && (
                        <a
                          href={reportLink}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ textDecoration: "none", color: "blue" }}
                          title="Click to view the Execution URL"
                        >
                          {" "}
                          Live_Execution_URL
                        </a>
                      )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={6}></Col>
            <Col sm={6}></Col>
          </Row>
          {scriptSelectedDetails?.length !== 0 ? (
            <>
              <div className="testCaseMap">
                <div className="TestSuiteDefect-Header">
                  <h5 className="text-primary">AUTOMATION SCRIPT MAPPED</h5>
                </div>
                <div className="table-responsive">
                  <Table
                    hover
                    className="table table-bordered"
                    style={{ minWidth: "600px", overflow: "auto" }}
                  >
                    <thead>
                      <tr>
                        <th>Order No</th>
                        <th>Automation Script Id</th>
                        <th>Automation Script Name</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody className="TestSuiteExecute-TableBody">
                      {scriptSelectedDetails?.map((script, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "left",
                                cursor: "pointer",
                                alignItems: "center",
                                fontWeight: "600",
                                textDecoration: "underline",
                              }}
                              onClick={() => {
                                handleViewClick(
                                  script?.automation_script_id?.id
                                )
                              }}
                              title="View Script Details"
                            >
                              {script?.automation_script_id?.script_id}
                              <i
                                className="mdi mdi-near-me me-2"
                                style={{ cursor: "pointer" }}
                              ></i>
                            </div>
                          </td>
                          <td>{script?.automation_script_id?.script_name}</td>
                          <td>
                            <Select
                              className="select2-selection"
                              styles={customStyles}
                              menuPortalTarget={document.body} // Render the menu in a portal (above other content)
                              menuPosition="fixed"
                              value={AscriptStatus[index]}
                              onChange={selectedOption =>
                                handleScriptStatusChange(
                                  selectedOption,
                                  index,
                                  script
                                )
                              }
                              options={options}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <Row>
          <Col lg={12}>
            <Card>
              <CardBody className="d-flex flex-column flex-md-row gap-2">
                {/* Status Field */}
                <div className="mb-3 col-12 col-md-4">
                  <Label className="form-label">
                    Status<span className="text-danger">*</span>
                  </Label>
                  <Select
                    className="select2-selection col-12"
                    styles={customStyles}
                    value={status}
                    label="Single select"
                    onChange={e => setStatus(e)}
                    options={options}
                  />
                  {validationErrMsg.status && (
                    <span className="text-danger required-field">
                      {validationErrMsg.status}
                    </span>
                  )}
                </div>

                {/* Time Field */}
                <div className="mb-3 col-12 col-md-4">
                  <Label className="form-label">Time</Label>
                  <Input
                    style={{ width: "100%" }}
                    value={time}
                    onChange={e => {
                      setTime(e.target.value.replace(/[^:\d]/g, "")) // Restrict to digits and colon
                    }}
                    placeholder="HH:MM:SS"
                    type="text"
                    name="approval_from"
                    className="form-control"
                  />
                  {validationErrMsg.time && (
                    <span className="text-danger required-field">
                      {validationErrMsg.time}
                    </span>
                  )}
                </div>

                {/* Defects Field */}
                <div className="mb-3 col-12 col-md-4">
                  <Label className="form-label">Defects</Label>
                  <Select
                    className="select2-selection multiSelect col-12"
                    value={defects}
                    label="Multi select"
                    onChange={e => setDefects(e)}
                    options={defectOptions}
                    isMulti
                    closeMenuOnSelect={false}
                    styles={customStyles}
                  />
                  <Label
                    className="form-label mt-2 text-primary"
                    style={{ cursor: "pointer" }}
                    title="Click here to create a new defect"
                    onClick={hanldeCreateDefect}
                  >
                    + Create Defects
                  </Label>
                </div>
              </CardBody>

              <CardBody className="d-flex gap-2">
                <div className="mb-3 col-12">
                  <Label className="form-label">Comments</Label>
                  <JoditEditor
                    value={comments}
                    config={joditConfig()}
                    onBlur={newContent => setComments(newContent)}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg={12}>
            <Card>
              <CardBody>
                <div className="mt-3">
                  <Label>Attached Files</Label>
                  <Dropzone
                    onDrop={acceptedFiles => {
                      handleAcceptedFiles(acceptedFiles)
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <div className="dropzone">
                        <div
                          className="dz-message needsclick"
                          {...getRootProps()}
                        >
                          <input {...getInputProps()} />
                          <div className="dz-message needsclick">
                            <div className="mb-3">
                              <i className="display-4 text-muted bx bxs-cloud-upload" />
                            </div>
                            <h4>Drop files here or click to upload.</h4>
                          </div>
                        </div>
                      </div>
                    )}
                  </Dropzone>
                  <div className="dropzone-previews mt-3" id="file-previews">
                    {selectedDeleteFiles.map((file, index) => {
                      return (
                        <div className="border rounded" key={index}>
                          <div className="d-flex flex-justify-content-between gap-2 p-2">
                            <div className="flex-shrink-0 me-3">
                              <div className="avatar-xs bg-light rounded p-2">
                                <i
                                  className="bx bx-file"
                                  style={{ fontSize: "23px" }}
                                ></i>
                              </div>
                            </div>
                            <div className="flex-grow-1">
                              <div className="pt-2">
                                <p className="fs-md mb-1" data-dz-name>
                                  {" "}
                                  <a
                                    style={{
                                      textDecoration: "none",
                                      color: "black",
                                    }}
                                    href={`${BASE_URL}${formatFilePath(
                                      file.path
                                    )}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {file.path}
                                  </a>
                                </p>
                                <strong
                                  className="error text-danger"
                                  data-dz-errormessage
                                ></strong>
                              </div>
                            </div>
                            <div className="flex-shrink-0 ms-3 mt-1">
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() => {
                                  const newImages = [...selectedFiles]
                                  newImages.splice(index, 1)
                                  setSelectedFiles(newImages)
                                  setSelectedDeleteFiles(newImages)
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          {defectDetailSelected?.length !== 0 ? (
            <>
              <div className="testCaseMap">
                <div className="TestSuiteDefect-Header">
                  <h5 className="text-primary">DEFECTS MAPPED</h5>
                </div>
                <Table hover className="table table-bordered">
                  <thead>
                    <tr>
                      <th>Order No</th>
                      <th>Defect Id</th>
                      <th>Defect Name</th>
                    </tr>
                  </thead>
                  <tbody className="TestSuiteExecute-TableBody">
                    {defectDetailSelected?.map((script, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "left",
                              cursor: "pointer",
                              alignItems: "center",
                              gap: "2px",
                              fontWeight: "600",
                              textDecoration: "underline",
                            }}
                            onClick={() => {
                              viewDefect(script?.id)
                            }}
                            title="View Defect Details"
                          >
                            {script?.defect_id}
                            <i
                              className="mdi mdi-near-me  me-2"
                              style={{ cursor: "pointer" }}
                            ></i>
                          </div>
                        </td>
                        <td>{script?.defect_name}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </>
          ) : null}
          <Col md="12" className="text-end mt-3">
            <Button
              color="primary"
              type="submit"
              onClick={hanldeSubmit}
              disabled={loading}
            >
              {loading ? "Loading.." : "Submit"}
            </Button>
            <Button
              type="button"
              color="secondary"
              onClick={() => {
                navigate(-1)
              }}
              className="ms-2"
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ExecuteTestSuite
