import React, { useEffect, useMemo, useState } from "react"
import {
  Container,
  Row,
  InputGroup,
  InputGroupText,
  Input,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  Button,
  Label,
  Spinner,
} from "reactstrap"
import { useSelector, useDispatch } from "react-redux"
import {
  fetchProjectDetails,
  fetchProjectDetails1,
  tagStatus,
} from "../../store/ProjectManagement/actions"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import ProjectGrid from "./ProjectGrid"
import Paginations from "components/Common/Pagination"
import Spinners from "components/Common/Spinner"
import { Outlet, useNavigate } from "react-router-dom"
import Select from "react-select"
import NoData from "../../components/Common/NoData"
import Flatpickr from "react-flatpickr"
import "flatpickr/dist/themes/material_blue.css"
import { toast } from "react-toastify"
import { setProjectFilterFrom } from "store/Slices/ProjectsSlice"
import moment from "moment"

const ProjectManagement = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { projectDetails: ProjectDetails, isLoading } = useSelector(
    state => state.ProjectManagement
  )
  const tagStatusData = useSelector(
    state => state.ProjectManagement.tagStatus.data
  )
  // const ProjectDetails = useSelector(state => state?.Project?.allProjectList)
  // const isLoading = useSelector(state => state?.Loading?.isLoading)
  const { projectFilterFrom } = useSelector(state => state.Project)
  // State for storing filters
  const [tempStatus, setTempStatus] = useState(null)
  const [tempType, setTempType] = useState(null)
  const [tempDueDate, setTempDueDate] = useState(null)

  const [selectedStatus, setSelectedStatus] = useState(null)
  const [selectedType, setSelectedType] = useState(null)
  const [dueDate, setDueDate] = useState(null) // State to store the applied due date filter

  const [currentPage, setCurrentPage] = useState(1)
  const [searchQuery, setSearchQuery] = useState("")
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [isRight, setIsRight] = useState(false)
  const selectedProject = JSON.parse(
    localStorage.getItem("selectedProjectData")
  )
  // const isRequirementPage = location.pathname.includes("Module");

  const perPageData = 9

  const toggle = () => setDropdownOpen(!dropdownOpen)
  const toggleRightCanvas = () => setIsRight(!isRight)
  const breadcrumbItems = [{ label: "Projects", path: "/ProjectManagement" }]

  useEffect(() => {
    dispatch(fetchProjectDetails())
    dispatch(fetchProjectDetails1())
    dispatch(tagStatus())
  }, [dispatch])

  // Extract status options if available
  const Defect_Status = tagStatusData?.Project_Status
    ? Object.keys(tagStatusData.Project_Status).map(status => ({
        value: status,
        label: status,
      }))
    : []

  const Project_Type = tagStatusData?.Project_Type
    ? Object.values(tagStatusData.Project_Type)
        .flatMap(item => item)
        .map(value => ({
          value: value,
          label: value,
        }))
    : []

  // Pagination Logic
  const indexOfLast = currentPage * perPageData
  const indexOfFirst = indexOfLast - perPageData

  const formatDate = date => {
    if (!date) return ""
    const [year, month, day] = [
      date.getFullYear(),
      date.getMonth() + 1,
      date.getDate(),
    ]
    return `${year}-${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`
  }

  // Filter Projects based on search query, selected status, selected type, and due date
  const filteredProjects = useMemo(() => {
    return Array.isArray(ProjectDetails)
      ? ProjectDetails.filter(project => {
          const matchesSearch = project.project_name
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
          const matchesStatus = selectedStatus
            ? project.project_status === selectedStatus.value
            : true
          const matchesType = selectedType
            ? project.project_type === selectedType.value
            : true
          const matchesDueDate = dueDate
            ? project.planned_end_date === formatDate(dueDate)
            : true
          return matchesSearch && matchesStatus && matchesType && matchesDueDate
        })
      : []
  }, [searchQuery, ProjectDetails, selectedStatus, selectedType, dueDate])

  const currentProjects = filteredProjects.slice(indexOfFirst, indexOfLast)

  // Search handler
  const handleSearchChange = e => {
    setSearchQuery(e.target.value)
    setCurrentPage(1) // Reset to the first page on search
  }

  // Filter handler
  const handleApplyFilters = () => {
    setSelectedStatus(tempStatus)
    setSelectedType(tempType)
    setDueDate(tempDueDate) // Apply the due date filter
    setIsRight(false)
    setCurrentPage(1) // Reset to the first page when filters are applied
    dispatch(
      setProjectFilterFrom({
        ...projectFilterFrom,
        project_status: tempStatus,
        project_type: tempType,
        planned_end_date: tempDueDate,
      })
    )
  }

  console.log("projectFilterFrom", projectFilterFrom)

  const handleClearFilters = () => {
    setTempStatus(null)
    setTempType(null)
    setTempDueDate(null) // Clear the temporary due date filter
    setSelectedStatus(null)
    setSelectedType(null)
    setDueDate(null) // Clear the applied due date filter
    // setIsRight(false)
    setCurrentPage(1) // Reset to the first page when filters are cleared
    dispatch(
      setProjectFilterFrom({
        ...projectFilterFrom,
        project_status: tempStatus,
        project_type: tempType,
        planned_end_date: tempDueDate,
      })
    )
  }
  const permission = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission =
    permission?.permissions?.[0]?.menu_item?.ProjectManagement
  const CreateProject = () => {
    if (rolePermission?.Project?.create) {
      navigate("/ProjectManagement/CreateProject")
    } else {
      toast.warn("You don't have the permission to create project", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  useEffect(() => {
    setTempStatus(projectFilterFrom?.project_status)
    setTempType(projectFilterFrom?.project_type)
    setTempDueDate(
      projectFilterFrom?.planned_end_date
        ? moment(new Date(projectFilterFrom?.planned_end_date)).format(
            "DD-MM-YYYY"
          )
        : ""
    )
  }, [isRight])

  const layoutModeType = useSelector(state => state.Layout.layoutModeType)

  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888",
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Ensure input text is visible
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150, // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  }
  return (
    <React.Fragment>
      <div>
        {/* {!isRequirementPage && (  */}
        <div className="page-content">
          {isLoading ? (
            <div className="text-center mt-5">
              <Spinner color="primary" />
              <p className="mt-2">Loading data...</p>
            </div>
          ) : (
            <Container fluid>
              <Breadcrumbs title="Projects" breadcrumbItems={breadcrumbItems} />
              <div className="d-flex flex-column flex-md-row justify-content-between mb-4">
                <button
                  type="button"
                  className="btn btn-primary d-flex align-items-center mb-2 mb-md-0"
                  onClick={CreateProject}
                  style={{ width: "100%", maxWidth: "152px" }}
                >
                  <i className="bx bx-plus mx-1 font-size-18"></i>
                  <span>Create Project</span>
                </button>

                <div className="d-flex flex-column flex-md-row justify-content-between w-50">
                  <InputGroup
                    className="w-100 w-md-50 mb-2 mb-md-0"
                    style={{ height: "38px" }}
                  >
                    <InputGroupText style={{ height: "100%" }}>
                      <i className="bx bx-search-alt search-icon"></i>
                    </InputGroupText>
                    <Input
                      type="search"
                      placeholder="Search..."
                      aria-label="Search"
                      value={searchQuery}
                      onChange={handleSearchChange}
                    />
                  </InputGroup>

                  <Button
                    color="secondary"
                    outline
                    className="mx-md-2  mt-md-0"
                    style={{ width: "100%", maxWidth: "116px", height: "38px" }}
                    onClick={toggleRightCanvas}
                  >
                    <i className="bx bx-filter font-size-16 me-1" />
                    Filters
                  </Button>
                </div>
              </div>

              <Row>
                <>
                  {currentProjects.length === 0 ? (
                    <div style={{ marginTop: "60px" }}>
                      <NoData />
                    </div>
                  ) : (
                    <>
                      <ProjectGrid projects={currentProjects} />
                      <Row>
                        <Paginations
                          perPageData={perPageData}
                          data={filteredProjects}
                          currentPage={currentPage}
                          setCurrentPage={setCurrentPage}
                          isShowingPageLength={false}
                          paginationDiv="col-12"
                          paginationClass="pagination pagination-rounded justify-content-center mt-2 mb-5"
                        />
                      </Row>
                    </>
                  )}
                </>
              </Row>
            </Container>
          )}
        </div>
        {/* )} */}
        <Outlet />
      </div>
      <Offcanvas isOpen={isRight} direction="end" toggle={toggleRightCanvas}>
        <OffcanvasHeader toggle={toggleRightCanvas}>
          Filter Projects
        </OffcanvasHeader>
        <OffcanvasBody>
          <div className="mb-3">
            <Label>Status</Label>
            <Select
              value={tempStatus}
              onChange={setTempStatus}
              options={Defect_Status}
              styles={customStyles}
              maxMenuHeight={200}
              className="select2-selection"
              isClearable={true}
            />
          </div>
          <div className="mb-3">
            <Label>Project Type</Label>
            <Select
              value={tempType}
              onChange={setTempType}
              options={Project_Type}
              styles={customStyles}
              maxMenuHeight={200}
              className="select2-selection"
              isClearable={true}
            />
          </div>
          <div className="mb-3">
            <Label>Planned End Date</Label>
            <InputGroup>
              <Flatpickr
                className="form-control d-block"
                placeholder="dd-mm-yyyy"
                options={{
                  altInput: true,
                  altFormat: "d-m-Y",
                  dateFormat: "d-m-y",
                }}
                value={tempDueDate} // Directly use the state variable
                onChange={date => {
                  setTempDueDate(date[0] || null) // Ensure null when no date is selected
                }}
              />
              {tempDueDate && (
                <InputGroupText
                  onClick={() => setTempDueDate(null)} // Clear the date when clicked
                  style={{ cursor: "pointer" }}
                >
                  ✕
                </InputGroupText>
              )}
            </InputGroup>
          </div>
          <div className="d-flex justify-content-between mt-5">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleClearFilters}
            >
              Clear All
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleApplyFilters}
            >
              Apply
            </button>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  )
}

export default ProjectManagement
