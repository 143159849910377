import Breadcrumb from "components/Common/Breadcrumb"
import DeleteModal from "components/Common/DeleteModal"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { Button, Card, CardBody, Col, Container, Row, Table } from "reactstrap"
import httpInjectorService from "services/http-Injector.Service"

const ViewAutomationSuite = () => {
  const currentUrl = window.location.href
  const navigate = useNavigate()
  const parts = currentUrl.split("/")
  const id = parts[parts.length - 1]
  const breadcrumbItems = [
    { label: "Automation Suite", path: "/TestAutomation/AutomationSuite" },
    { label: "Automation Suite view", path: "/" },
  ]
  const loading = useSelector(state => state.Loading.isLoading)

  const data = useSelector(
    state => state.ActionSuite?.singleAutomationSuiteData
  )

  useEffect(() => {
    if (!loading && data.length === 0) {
      navigate("/TestAutomation/AutomationSuite")
    }
  }, [loading, data, navigate])

  const test_sequence_string = data?.test_sequence
  const test_sequence = {} // Initialize an empty object

  // Remove curly braces and split by comma to get key-value pairs
  const pairs = test_sequence_string?.slice(1, -1).split(",")

  // Iterate over key-value pairs
  pairs?.forEach(pair => {
    const [key, value] = pair.split(":").map(str => str.trim()) // Split key and value
    test_sequence[parseInt(key)] = parseInt(value) // Convert key and value to integers and assign to object
  })

  // Sort the automation_script_id array based on the "test_sequence",test_sequence values
  // Make a shallow copy of the automation_script_id array using spread operator
  const sortedAutomationScriptId = [...(data?.automation_script_id || [])].sort(
    (a, b) => {
      // Get the sequence values for the corresponding IDs
      const sequenceA = test_sequence[a.id] || Number.POSITIVE_INFINITY
      const sequenceB = test_sequence[b.id] || Number.POSITIVE_INFINITY

      // Compare the sequence values
      return sequenceA - sequenceB
    }
  )

  const onClickBack = () => {
    navigate("/TestAutomation/AutomationSuite")
  }

  return (
    <div>
      <Container fluid>
        <Breadcrumb
          title="Automation Suite view"
          breadcrumbItems={breadcrumbItems}
        />
        <div className="d-flex justify-content-end mb-2">
          <Button
            type="button"
            color="primary"
            className="btn-label"
            onClick={onClickBack}
          >
            <i className="bx bx-arrow-back label-icon"></i> Go Back
          </Button>
        </div>
        <Card style={{ minHeight: "400px" }}>
          <CardBody>
            <div className="d-flex justify-content-between mb-4">
              <div className="d-flex">
                <div className="avatar-xs me-4">
                  <span
                    className="avatar-title bg-primary"
                    style={{ fontSize: "20px", borderRadius: "8px" }}
                  >
                    {data?.automation_suite_name?.slice(0, 2).toUpperCase()}
                  </span>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <h5 className="font-size-15 d-flex align-items-center">
                    <span>
                      <Link className="text-dark  mt-2">
                        {data?.automation_suite_name || "-"}
                      </Link>
                    </span>
                    {data?.automation_suite_id && (
                      <span className="ms-2">
                        ({data?.automation_suite_id})
                      </span>
                    )}
                  </h5>
                </div>
              </div>
            </div>

            <div className="mb-4">
              <h6 style={{ fontWeight: "550" }}>Description</h6>
              <p
                className="text-muted mb-4"
                dangerouslySetInnerHTML={{
                  __html: data?.description ? data?.description : "-",
                }}
              ></p>
            </div>
            <div>
              <Row>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Build</h6>
                  <p className="text-muted ">
                    {data?.build ? data?.build : "-"}
                  </p>
                </Col>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Application Type</h6>
                  <p className="text-muted ">
                    {data?.automation_suit_type
                      ? data?.automation_suit_type
                      : "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}> Created By</h6>
                  <p className="text-muted ">
                    {data?.created_by_name?.name
                      ? data?.created_by_name?.name
                      : "-"}
                  </p>
                </Col>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}> Test Data </h6>
                  <p className="text-muted ">
                    {data?.test_data_name ? data?.test_data_name : "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}> Created At</h6>
                  <p className="text-muted ">
                    {data?.created_at
                      ? moment(data?.created_at).format("DD-MM-YYYY")
                      : "-"}{" "}
                  </p>
                </Col>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Automation Script ID</h6>
                  {sortedAutomationScriptId?.map((item, index) => (
                    <p
                      className="text-muted mb-1"
                      key={item.automation_script_id}
                    >
                      • {item.automation_script_id} -{" "}
                      {item.automation_script_name}
                    </p>
                  ))}
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Suite Description</h6>
                  <a
                    href={data?.file_location}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline-link"
                  >
                    View Description
                  </a>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default ViewAutomationSuite
