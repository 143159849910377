const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY_ID,
    redirectUri: process.env.REACT_APP_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you're having issues on IE11 or Edge
  },
}

const loginRequest = {
  scopes: ["User.Read"], // Add the scopes you want to request
}

export { msalConfig, loginRequest }
