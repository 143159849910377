import Breadcrumb from "components/Common/Breadcrumb"
import moment from "moment"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap"
import "./view.scss"

const ViewAutomationScript = () => {
  const navigate = useNavigate()
  const loading = useSelector(state => state.Loading.isLoading)

  const breadcrumbItems = [
    { label: "Automation Script", path: "/TestAutomation/AutomationScript" },
    { label: "Automation Script view", path: "/" },
  ]

  const data = useSelector(
    state => state.AutomationScript?.singleAutomationScriptData
  )

  useEffect(() => {
    if (!loading && data.length === 0) {
      navigate("/TestAutomation/AutomationScript")
    }
  }, [loading, data, navigate])

  const onClickBack = () => {
    navigate("/TestAutomation/AutomationScript")
  }
  return (
    <div>
      <Container fluid>
        <Breadcrumb
          title="Automation Suite view"
          breadcrumbItems={breadcrumbItems}
        />
        <div className="d-flex justify-content-end mb-2">
          <Button
            type="button"
            color="primary"
            className="btn-label"
            onClick={onClickBack}
          >
            <i className="bx bx-arrow-back label-icon"></i> Go Back
          </Button>
        </div>
        <Card style={{ minHeight: "400px" }}>
          <CardBody>
            <div className="d-flex justify-content-between mb-4">
              <div className="d-flex">
                <div className="avatar-xs me-4">
                  <span
                    className="avatar-title bg-primary"
                    style={{ fontSize: "20px", borderRadius: "8px" }}
                  >
                    {data?.automation_script_name?.slice(0, 2).toUpperCase()}
                  </span>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <h5 className="font-size-15 d-flex align-items-center">
                    <span>
                      <Link className="text-dark  mt-2">
                        {data.automation_script_name || "-"}
                      </Link>
                    </span>
                    {data.automation_script_id && (
                      <span className="ms-2">
                        ({data.automation_script_id})
                      </span>
                    )}
                  </h5>
                </div>
              </div>
              {/* <div>
                                <Button type="button" color="primary" className="btn-label mx-2" onClick={onClickEdit}>
                                    <i className="mdi mdi-pencil label-icon"></i> Edit
                                </Button>
                                <Button type="button" color="danger" className="btn-label" onClick={onClickDelete}>
                                    <i className="mdi mdi-delete label-icon"></i> Delete
                                </Button>
                            </div> */}
            </div>

            <div className="mb-4">
              <h6 style={{ fontWeight: "550" }}>Description</h6>
              <p
                className="text-muted mb-4"
                dangerouslySetInnerHTML={{
                  __html: data.description ? data.description : "-",
                }}
              ></p>
            </div>
            <div>
              <Row>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Build</h6>
                  <p className="text-muted ">{data.build ? data.build : "-"}</p>
                </Col>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Application Type</h6>
                  <p className="text-muted ">
                    {data.application_type ? data.application_type : "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}> Created By</h6>
                  <p className="text-muted ">
                    {data.created_by_name ? data.created_by_name : "-"}
                  </p>
                </Col>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}> Test Case </h6>
                  <p className="text-muted ">
                    {data.test_case?.id ? data.test_case?.id : "-"} -{" "}
                    {data.test_case?.test_case_name
                      ? data.test_case?.test_case_name
                      : "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}> Created At</h6>
                  <p className="text-muted ">
                    {data.created_at
                      ? moment(data.created_at).format("DD-MM-YYYY")
                      : "-"}{" "}
                  </p>
                </Col>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Automation Script ID </h6>
                  <p className="text-muted ">
                    {" "}
                    {data.automation_script_id
                      ? data.automation_script_id
                      : "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Script Description</h6>
                  <a
                    href={data?.file_location}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="underline-link"
                  >
                    View Description
                  </a>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default ViewAutomationScript
