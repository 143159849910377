import React from "react"
import { toast } from "react-toastify"
import { Modal, ModalHeader, ModalBody, Button, Row, Col } from "reactstrap"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom" // Use this for navigation
import { ExtendSessionAPI } from "store/actions/CommanAction"
import { setLoading } from "store/Slices/LoadingSlice"
import CryptoJS from "crypto-js"

const SessionWarningModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate() // To redirect user to login page
  const loginDetails = JSON.parse(localStorage.getItem("authUser"))

  const handleContinue = async () => {
    const bodyOption = {
      email: loginDetails?.email,
      is_ad_user: loginDetails?.is_ad_user,
    }
    dispatch(setLoading(true))

    try {
      const response = await dispatch(ExtendSessionAPI(bodyOption))

      if (response?.access_token) {
        // Update localStorage with new token
        const encryptedAccessToken = CryptoJS.AES.encrypt(
          response.access_token,
          process.env.REACT_APP_ENCRYPTION_KEY
        ).toString()
        const encryptedRefreshToken = CryptoJS.AES.encrypt(
          response.refresh_token,
          process.env.REACT_APP_ENCRYPTION_KEY
        ).toString()

        // Store encrypted tokens in localStorage
        localStorage.setItem(
          "authUser",
          JSON.stringify({
            ...response,
            access_token: encryptedAccessToken,
            refresh_token: encryptedRefreshToken,
          })
        )
        toast.success(response?.message || "Session extended successfully", {
          position: "top-right",
          autoClose: 3000,
        })
        onClose() // Close modal and continue the session
      } else {
        // // Handle failure case and throw error to trigger catch
        // toast.error(
        //   response?.message ||
        //     "Failed To Extend session || Session timeout, login again",
        //   {
        //     position: "top-right",
        //     autoClose: 3000,
        //   }
        // )
        throw new Error(
          response?.message ||
            "Failed To Extend session || Session timeout, login again"
        )
      }
    } catch (error) {
      toast.warn(
        error?.message ||
          "There was an issue extending the session. Redirecting to login.",
        {
          position: "top-right",
          autoClose: 3000,
        }
      )

      // Wait for the toast to be shown before redirecting
      setTimeout(() => {
        localStorage.removeItem("authUser") // Clear session data
        localStorage.removeItem("selectedProject") // Clear session data
        navigate("/login")
        onClose() // Close modal and continue the session
        // Redirect to login page after failure
      }, 1000) // Redirect after 3 seconds to give time for the toast to show
    } finally {
      dispatch(setLoading(false))
    }
  }

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>Session Expiration Warning</ModalHeader>
      <ModalBody>
        <p>
          Your session is about to expire. Please save your work and click
          <strong> Continue</strong> to resume.
        </p>
        <Row className="d-flex justify-content-between">
          <Col xs="auto">
            <Button onClick={onClose}>Cancel</Button>{" "}
            {/* This will just close the modal */}
          </Col>
          <Col xs="auto">
            <Button color="primary" onClick={handleContinue}>
              Continue
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default SessionWarningModal
