import React, { useEffect, useRef, useState } from "react"
import "./CreateRole.css"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import JoditEditor from "jodit-react"
import { useSelector, useDispatch } from "react-redux"
import { joditConfig } from "../../../../helpers/helper"
import { toast } from "react-toastify"
// import { useNavigate } from "react-router-dom";
import Breadcrumb from "components/Common/Breadcrumb"
import "./CreateTestScenarioForm.scss"
import {
  createTestScenarioAPI,
  EditTestScenarioAPI,
} from "store/actions/TestScenarioActions"
import { setEditRoles } from "store/Slices/RolesSlice"
import { Link, useLocation } from "react-router-dom"
import { createRoles, EditRoles } from "store/actions/RolesActions"
import { setViewTestCycle } from "store/Slices/TestCycleSlice"
// import PermissionComponent from "./PermissionComponent";

const CreateRoles = () => {
  // const navigate = useNavigate()
  const dispatch = useDispatch()
  const linkRef = useRef(null)
  const editRef = useRef(null)
  const loading = useSelector(state => state?.Loading?.isLoading)

  const location = useLocation()
  const { isEdit, data } = location.state || {}

  const editRoles = useSelector(state => state?.Roles?.editRoles)

  const isEditMode = editRoles?.isEdit || isEdit
  const isEditModeData = editRoles?.data || data

  const [role_description, setDescription] = useState("")
  const [is_removable, setRemovable] = useState(
    isEditModeData ? isEditModeData.is_removable : true
  )

  const [isDescriptionInvalid, setIsDescriptionInvalid] = useState(false)

  const stripHtmlTags = html => {
    const doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent || ""
  }

  const submitData = () => {
    const cleanDescription = stripHtmlTags(role_description).trim()
    if (!cleanDescription) {
      setIsDescriptionInvalid(true)
    } else {
      setIsDescriptionInvalid(false)
    }
  }

  const breadcrumbItems = [
    { label: "Settings", path: "/Settings" },
    {
      label: isEditMode ? "Edit Roles " : "Create Roles",
      path: "/",
    },
  ]

  const validationSchema = Yup.object().shape({
    role_name: Yup.string()
      .trim()
      .matches(/^.*\S.*$/, "Role cannot contain only spaces")
      .max(150, "Role cannot exceed 150 characters")
      .required("Role is required"),
  })

  //Permissions
  const [grantPermission, setGrantPermission] = useState(true)
  const [projectCreate, setProjectCreate] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.ProjectManagement?.Project?.create
      : true
  )
  const [projectView, setProjectView] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.ProjectManagement?.Project?.view
      : true
  )
  const [projectEdit, setProjectEdit] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.ProjectManagement?.Project?.edit
      : true
  )
  const [projectDelete, setProjectDelete] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.ProjectManagement?.Project?.delete
      : true
  )
  const [projectAddTeam, setProjectAddTeam] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.ProjectManagement?.Project?.addToTeam
      : true
  )

  // const [teamView, setTeamView] = useState(
  //   isEditModeData ? isEditModeData?.menu_item?.ProjectManagement?.Teams?.view : true);
  // const [teamAdd, setTeamAdd] = useState(
  //   isEditModeData ? isEditModeData?.menu_item?.ProjectManagement?.Teams?.create : true);
  // const [teamDelete, setTeamDelete] = useState(
  //   isEditModeData ? isEditModeData?.menu_item?.ProjectManagement?.Teams?.delete : true);

  const [requirementCreate, setRequirementCreate] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.ProjectManagement?.requirement?.create
      : true
  )
  const [requirementView, setRequirementView] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.ProjectManagement?.requirement?.view
      : true
  )
  const [requirementEdit, setRequirementEdit] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.ProjectManagement?.requirement?.edit
      : true
  )
  const [requirementDelete, setRequirementDelete] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.ProjectManagement?.requirement?.delete
      : true
  )

  const [moduleCreate, setModuleCreate] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.ProjectManagement?.module?.create
      : true
  )
  const [moduleView, setModuleView] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.ProjectManagement?.module?.view
      : true
  )
  const [moduleEdit, setModuleEdit] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.ProjectManagement?.module?.edit
      : true
  )
  const [moduleDelete, setModuleDelete] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.ProjectManagement?.module?.delete
      : true
  )

  const [buildCreate, setBuildCreate] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.ProjectManagement?.Build?.create
      : true
  )
  const [buildView, setBuildView] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.ProjectManagement?.Build?.view
      : true
  )
  const [buildEdit, setBuildEdit] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.ProjectManagement?.Build?.edit
      : true
  )
  const [buildDelete, setBuildDelete] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.ProjectManagement?.Build?.delete
      : true
  )

  const [testScenarioCreate, setTestScenarioCreate] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.TestManagement?.testscenario?.create
      : true
  )
  const [testScenarioView, setTestScenarioView] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.TestManagement?.testscenario?.view
      : true
  )
  const [testScenarioEdit, setTestScenarioEdit] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.TestManagement?.testscenario?.edit
      : true
  )
  const [testScenarioDelete, setTestScenarioDelete] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.TestManagement?.testscenario?.delete
      : true
  )
  const [testCaseCreate, setTestCaseCreate] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.TestManagement?.testcase?.create
      : true
  )
  const [testCaseView, setTestCaseView] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.TestManagement?.testcase?.view
      : true
  )
  const [testCaseEdit, setTestCaseEdit] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.TestManagement?.testcase?.edit
      : true
  )
  const [testCaseDelete, setTestCaseDelete] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.TestManagement?.testcase?.delete
      : true
  )

  const [testLabCreate, setTestLabCreate] = useState(
    isEditModeData ? isEditModeData?.menu_item?.TestLab?.cycle?.create : true
  )
  const [testLabView, setTestLabView] = useState(
    isEditModeData ? isEditModeData?.menu_item?.TestLab?.cycle?.view : true
  )
  const [testLabEdit, setTestLabEdit] = useState(
    isEditModeData ? isEditModeData?.menu_item?.TestLab?.cycle?.edit : true
  )
  const [testLabDelete, setTestLabDelete] = useState(
    isEditModeData ? isEditModeData?.menu_item?.TestLab?.cycle?.delete : true
  )
  const [testLabApprove, setTestLabApprove] = useState(
    isEditModeData ? isEditModeData?.menu_item?.TestLab?.cycle?.approve : true
  )
  const [testLabExecute, setTestLabExecute] = useState(
    isEditModeData ? isEditModeData?.menu_item?.TestLab?.cycle?.execution : true
  )

  const [testPlanCreate, setTestPlanCreate] = useState(
    isEditModeData ? isEditModeData?.menu_item?.TestLab?.Testplan?.create : true
  )
  const [testPlanView, setTestPlanView] = useState(
    isEditModeData ? isEditModeData?.menu_item?.TestLab?.Testplan?.view : true
  )
  const [testPlanEdit, setTestPlanEdit] = useState(
    isEditModeData ? isEditModeData?.menu_item?.TestLab?.Testplan?.edit : true
  )
  const [testPlanDelete, setTestPlanDelete] = useState(
    isEditModeData ? isEditModeData?.menu_item?.TestLab?.Testplan?.delete : true
  )

  const [defectCreate, setDefectCreate] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.DefectManagement?.defectmanagement?.create
      : true
  )
  const [defectView, setDefectView] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.DefectManagement?.defectmanagement?.view
      : true
  )
  const [defectEdit, setDefectEdit] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.DefectManagement?.defectmanagement?.edit
      : true
  )
  const [defectDelete, setDefectDelete] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.DefectManagement?.defectmanagement?.delete
      : true
  )

  const [testAutomationCreate, setTestAutomationCreate] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.TestLab?.Testautomation?.create
      : true
  )
  const [testAutomationView, setTestAutomationView] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.TestLab?.Testautomation?.view
      : true
  )
  const [testAutomationEdit, setTestAutomationEdit] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.TestLab?.Testautomation?.edit
      : true
  )
  const [testAutomationDelete, setTestAutomationDelete] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.TestLab?.Testautomation?.delete
      : true
  )

  const [taskCreate, setTaskCreate] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.TaskManagement?.taskmanagement?.create
      : true
  )
  const [taskView, setTaskView] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.TaskManagement?.taskmanagement?.view
      : true
  )
  const [taskEdit, setTaskEdit] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.TaskManagement?.taskmanagement?.edit
      : true
  )
  const [taskDelete, setTaskDelete] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.TaskManagement?.taskmanagement?.delete
      : true
  )

  const [roleCreate, setRoleCreate] = useState(
    isEditModeData ? isEditModeData?.menu_item?.Settings?.role?.create : true
  )
  const [roleEdit, setRoleEdit] = useState(
    isEditModeData ? isEditModeData?.menu_item?.Settings?.role?.edit : true
  )
  const [roleDelete, setRoleDelete] = useState(
    isEditModeData ? isEditModeData?.menu_item?.Settings?.role?.delete : true
  )
  const [roleView, setRoleView] = useState(
    isEditModeData ? isEditModeData?.menu_item?.Settings?.role?.view : true
  )

  const [userCreate, setUserCreate] = useState(
    isEditModeData ? isEditModeData?.menu_item?.Settings?.user?.create : true
  )
  const [userEdit, setUserEdit] = useState(
    isEditModeData ? isEditModeData?.menu_item?.Settings?.user?.edit : true
  )
  const [userDelete, setUserDelete] = useState(
    isEditModeData ? isEditModeData?.menu_item?.Settings?.user?.delete : true
  )
  const [userView, setUserView] = useState(
    isEditModeData ? isEditModeData?.menu_item?.Settings?.user?.view : true
  )

  const [metaCreate, setMetaCreate] = useState(
    isEditModeData ? isEditModeData?.menu_item?.Settings?.meta?.create : true
  )
  const [metaEdit, setMetaEdit] = useState(
    isEditModeData ? isEditModeData?.menu_item?.Settings?.meta?.edit : true
  )
  const [metaDelete, setMetaDelete] = useState(
    isEditModeData ? isEditModeData?.menu_item?.Settings?.meta?.delete : true
  )
  const [metaView, setMetaView] = useState(
    isEditModeData ? isEditModeData?.menu_item?.Settings?.meta?.view : true
  )

  const [integrationCreate, setIntegrationCreate] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.Settings?.integration?.create
      : true
  )
  const [integrationEdit, setIntegrationEdit] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.Settings?.integration?.edit
      : true
  )
  const [integrationDelete, setIntegrationDelete] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.Settings?.integration?.delete
      : true
  )
  const [integrationView, setIntegrationView] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.Settings?.integration?.view
      : true
  )

  const [reportsDefectCreate, setReportsDefectCreate] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.Reports?.defectreports?.create
      : true
  )
  const [reportsTCCreate, setreportsTCCreate] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.Reports?.testcasereports?.create
      : true
  )
  const [reportsTECreate, setreportsTECreate] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.Reports?.testexecutionreports?.create
      : true
  )
  const [reportsCycleCreate, setreportsCycleCreate] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.Reports?.cyclecomparisonreports?.create
      : true
  )
  const [reportsScheduleCreate, setreportsScheduleCreate] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.Reports?.schedulereports?.create
      : true
  )
  const [requirementReports, setRequirementReports] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.Reports?.requirementtraceabilityreports
          ?.create
      : true
  )

  const [globalView, setGlobalView] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.Dashboard?.GlobalDashboard?.view
      : true
  )
  const [globalDownload, setGlobalDownload] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.Dashboard?.GlobalDashboard?.download
      : true
  )
  const [projectDashboardView, setProjectDashboardView] = useState(
    isEditModeData
      ? isEditModeData?.menu_item?.Dashboard?.ProjectDashboard?.view
      : true
  )
  // const [projectDashboardDownload, setProjectDashboardDownload] = useState(
  //   isEditModeData ? isEditModeData?.menu_item?.Dashboard?.ProjectDashboard?.download : true);

  const menu_item = {
    Dashboard: {
      GlobalDashboard: {
        view: globalView,
        download: globalDownload,
      },
      ProjectDashboard: {
        view: projectDashboardView,
      },
    },
    ProjectManagement: {
      Project: {
        create: projectCreate,
        edit: projectEdit,
        view: projectView,
        delete: projectDelete,
        addToTeam: projectAddTeam,
      },
      // Teams: {
      //   create: teamAdd,
      //   view: teamView,
      //   delete: teamDelete,
      // },
      Build: {
        create: buildCreate,
        edit: buildEdit,
        view: buildView,
        delete: buildDelete,
      },
      module: {
        create: moduleCreate,
        edit: moduleEdit,
        view: moduleView,
        delete: moduleDelete,
      },
      requirement: {
        create: requirementCreate,
        edit: requirementEdit,
        view: requirementView,
        delete: requirementDelete,
      },
    },
    TestManagement: {
      testcase: {
        create: testCaseCreate,
        edit: testCaseEdit,
        view: testCaseView,
        delete: testCaseDelete,
      },
      testscenario: {
        create: testScenarioCreate,
        edit: testScenarioEdit,
        view: testScenarioView,
        delete: testScenarioDelete,
      },
    },
    TestLab: {
      cycle: {
        create: testLabCreate,
        edit: testLabEdit,
        view: testLabView,
        delete: testLabDelete,
        approve: testLabApprove,
        execution: testLabExecute,
      },
      Testplan: {
        create: testPlanCreate,
        edit: testPlanEdit,
        view: testPlanView,
        delete: testPlanDelete,
      },
      Testautomation: {
        create: testAutomationCreate,
        edit: testAutomationEdit,
        view: testAutomationView,
        delete: testAutomationDelete,
      },
    },
    DefectManagement: {
      defectmanagement: {
        create: defectCreate,
        edit: defectEdit,
        view: defectView,
        delete: defectDelete,
      },
    },
    TaskManagement: {
      taskmanagement: {
        create: taskCreate,
        edit: taskEdit,
        view: taskView,
        delete: taskDelete,
      },
    },
    Reports: {
      defectreports: {
        create: reportsDefectCreate,
      },
      testcasereports: {
        create: reportsTCCreate,
      },
      testexecutionreports: {
        create: reportsTECreate,
      },
      cyclecomparisonreports: {
        create: reportsCycleCreate,
      },
      schedulereports: {
        create: reportsScheduleCreate,
      },
      requirementtraceabilityreports: {
        create: requirementReports,
      },
    },
    Settings: {
      role: {
        create: roleCreate,
        edit: roleEdit,
        view: roleView,
        delete: roleDelete,
      },
      user: {
        create: userCreate,
        edit: userEdit,
        view: userView,
        delete: userDelete,
      },
      meta: {
        create: metaCreate,
        edit: metaEdit,
        view: metaView,
        delete: metaDelete,
      },
      integration: {
        create: integrationCreate,
        edit: integrationEdit,
        delete: integrationDelete,
        view: integrationView,
      },
    },
  }

  const handlePermissions = data => {
    if (editRef.current) {
      editRef.current.click()
    }
    dispatch(setEditRoles({ isEdit: true, data: data }))
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      role_name: isEditModeData ? isEditModeData?.role_name || "" : "",
      is_allpermission: grantPermission,
      menu_item: menu_item,
    },
    validationSchema,
    onSubmit: values => {
      const cleanDescription = stripHtmlTags(role_description).trim()
      if (!cleanDescription) {
        setIsDescriptionInvalid(true)
      } else {
        const formData = {
          ...values,
          role_description,
          is_removable,
        }
        createEditRoles(formData)
      }
    },
  })

  const createEditRoles = async formData => {
    const editFromValues = {
      ...formData,
      id: isEditModeData?.id,
    }

    if (!isEditModeData) {
      try {
        const response = await dispatch(createRoles(formData))
        if (response) {
          handlePermissions(response?.data)
        }
        // navigate(-1) // Navigate back or handle success
      } catch (error) {
        console.error("Error creating role:", error)
        toast.error(error?.message || "An error occurred while creating role", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
      }
    } else {
      try {
        await dispatch(EditRoles(editFromValues))
        dispatch(setEditRoles({ isEdit: false, data: null }))
        if (linkRef.current) {
          linkRef.current.click()
        }
      } catch (error) {
        console.error("Error updating role:", error)
        toast.error(error?.message || "An error occurred while updating role", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
      }
    }
  }

  useEffect(() => {
    if (isEditModeData) {
      setDescription(isEditModeData.role_description || "")
    }
  }, [isEditModeData])

  const [selectedOption, setSelectedOption] = useState("Global")

  const handleCheckAll = e => {
    const isChecked = e.target.checked
    setGrantPermission(isChecked)
    setProjectCreate(isChecked)
    setProjectView(isChecked)
    setProjectEdit(isChecked)
    setProjectAddTeam(isChecked)
    setProjectDelete(isChecked)
    setBuildCreate(isChecked)
    setBuildView(isChecked)
    setBuildEdit(isChecked)
    setBuildDelete(isChecked)
    setModuleCreate(isChecked)
    setModuleView(isChecked)
    setModuleEdit(isChecked)
    setModuleDelete(isChecked)
    setRequirementCreate(isChecked)
    setRequirementView(isChecked)
    setRequirementEdit(isChecked)
    setRequirementDelete(isChecked)
    // setTeamView(isChecked);
    // setTeamAdd(isChecked);
    // setTeamDelete(isChecked);
    setTestScenarioCreate(isChecked)
    setTestScenarioView(isChecked)
    setTestScenarioEdit(isChecked)
    setTestScenarioDelete(isChecked)
    setTestCaseCreate(isChecked)
    setTestCaseView(isChecked)
    setTestCaseEdit(isChecked)
    setTestCaseDelete(isChecked)
    setTestLabCreate(isChecked)
    setTestLabView(isChecked)
    setTestLabEdit(isChecked)
    setTestLabDelete(isChecked)
    setTestLabApprove(isChecked)
    setTestLabExecute(isChecked)
    setTestPlanCreate(isChecked)
    setTestPlanView(isChecked)
    setTestPlanEdit(isChecked)
    setTestPlanDelete(isChecked)
    setTestAutomationCreate(isChecked)
    setTestAutomationView(isChecked)
    setTestAutomationEdit(isChecked)
    setTestAutomationDelete(isChecked)
    setDefectCreate(isChecked)
    setDefectView(isChecked)
    setDefectEdit(isChecked)
    setDefectDelete(isChecked)
    setTaskCreate(isChecked)
    setTaskView(isChecked)
    setTaskEdit(isChecked)
    setTaskDelete(isChecked)
    setReportsDefectCreate(isChecked)
    setRequirementReports(isChecked)
    setreportsTCCreate(isChecked)
    setreportsTECreate(isChecked)
    setreportsCycleCreate(isChecked)
    setreportsScheduleCreate(isChecked)
    setRoleCreate(isChecked)
    setRoleEdit(isChecked)
    setRoleDelete(isChecked)
    setUserCreate(isChecked)
    setUserEdit(isChecked)
    setRoleView(isChecked)
    setUserView(isChecked)
    setMetaView(isChecked)
    setUserDelete(isChecked)
    setMetaCreate(isChecked)
    setMetaEdit(isChecked)
    setMetaDelete(isChecked)
    setIntegrationCreate(isChecked)
    setIntegrationDelete(isChecked)
    setIntegrationView(isChecked)
    setIntegrationEdit(isChecked)
    // setProjectDashboardDownload(isChecked);
    setProjectDashboardView(isChecked)
    setGlobalDownload(isChecked)
    setGlobalView(isChecked)
  }

  // Handle individual checkbox change
  const handleIndividualChange = setter => e => {
    setter(e.target.checked)
  }

  const handleOptionClick = option => {
    setSelectedOption(option)
  }

  // Sync 'Grant All Permissions' with individual checkboxes
  useEffect(() => {
    // Check if all individual permissions are true
    const allPermissionsTrue =
      projectCreate &&
      projectView &&
      projectEdit &&
      projectDelete &&
      projectAddTeam &&
      // teamView &&
      // teamAdd &&
      // teamDelete &&
      requirementCreate &&
      requirementView &&
      requirementEdit &&
      requirementDelete &&
      moduleCreate &&
      moduleView &&
      moduleEdit &&
      moduleDelete &&
      buildCreate &&
      buildView &&
      buildEdit &&
      buildDelete &&
      testScenarioCreate &&
      testScenarioView &&
      testScenarioEdit &&
      testScenarioDelete &&
      testCaseCreate &&
      testCaseView &&
      testCaseEdit &&
      testCaseDelete &&
      testLabCreate &&
      testLabView &&
      testLabEdit &&
      testLabDelete &&
      testLabApprove &&
      testLabExecute &&
      defectCreate &&
      defectView &&
      defectEdit &&
      defectDelete &&
      testPlanCreate &&
      testPlanView &&
      testPlanEdit &&
      testPlanDelete &&
      testAutomationCreate &&
      testAutomationView &&
      testAutomationEdit &&
      testAutomationDelete &&
      taskCreate &&
      taskView &&
      taskEdit &&
      taskDelete &&
      reportsDefectCreate &&
      requirementReports &&
      reportsTCCreate &&
      reportsTECreate &&
      reportsCycleCreate &&
      reportsScheduleCreate &&
      roleCreate &&
      roleEdit &&
      roleDelete &&
      userCreate &&
      userEdit &&
      roleView &&
      userView &&
      metaView &&
      userDelete &&
      metaCreate &&
      metaEdit &&
      metaDelete &&
      integrationCreate &&
      integrationEdit &&
      integrationDelete &&
      integrationView &&
      projectDashboardView &&
      // projectDashboardDownload &&
      globalView &&
      globalDownload
    setGrantPermission(allPermissionsTrue)
  }, [
    projectCreate,
    projectView,
    projectEdit,
    projectDelete,
    projectAddTeam,
    // teamView,
    // teamAdd,
    // teamDelete,
    requirementCreate,
    requirementView,
    requirementEdit,
    requirementDelete,
    requirementReports,
    moduleCreate,
    moduleView,
    moduleEdit,
    moduleDelete,
    buildCreate,
    buildView,
    buildEdit,
    buildDelete,
    testScenarioCreate,
    testScenarioView,
    testScenarioEdit,
    testScenarioDelete,
    testCaseCreate,
    testCaseView,
    testCaseEdit,
    testCaseDelete,
    testLabCreate,
    testLabView,
    testLabEdit,
    testLabDelete,
    testLabApprove,
    testLabExecute,
    defectCreate,
    defectView,
    defectEdit,
    defectDelete,
    testPlanCreate,
    testPlanEdit,
    testPlanView,
    testPlanDelete,
    testAutomationCreate,
    testAutomationEdit,
    testAutomationView,
    testAutomationDelete,
    taskCreate,
    taskView,
    taskEdit,
    taskDelete,
    reportsDefectCreate,
    reportsTCCreate,
    reportsTECreate,
    reportsCycleCreate,
    reportsScheduleCreate,
    roleCreate,
    roleEdit,
    roleDelete,
    userCreate,
    userEdit,
    roleView,
    userView,
    metaView,
    userDelete,
    metaCreate,
    metaEdit,
    metaDelete,
    integrationCreate,
    integrationEdit,
    integrationDelete,
    integrationView,
    projectDashboardView,
    // projectDashboardDownload,
    globalView,
    globalDownload,
  ])

  const handleViewAllAccess = e => {
    const projectViewAccess = e.target.checked

    setProjectCreate(projectViewAccess)
    setProjectEdit(projectViewAccess)
    setProjectAddTeam(projectViewAccess)
    setProjectDelete(projectViewAccess)
    setBuildCreate(projectViewAccess)
    setBuildView(projectViewAccess)
    setBuildEdit(projectViewAccess)
    setBuildDelete(projectViewAccess)
    setModuleCreate(projectViewAccess)
    setModuleView(projectViewAccess)
    setModuleEdit(projectViewAccess)
    setModuleDelete(projectViewAccess)
    setRequirementCreate(projectViewAccess)
    setRequirementView(projectViewAccess)
    setRequirementEdit(projectViewAccess)
    setRequirementDelete(projectViewAccess)
  }

  const handleViewBuildAccess = e => {
    const ViewAccess = e.target.checked
    setBuildCreate(ViewAccess)
    setBuildView(ViewAccess)
    setBuildEdit(ViewAccess)
    setBuildDelete(ViewAccess)
  }

  const handleViewRequirementAccess = e => {
    const ViewAccess = e.target.checked
    setRequirementCreate(ViewAccess)
    setRequirementView(ViewAccess)
    setRequirementEdit(ViewAccess)
    setRequirementDelete(ViewAccess)
  }

  const handleViewModuleAccess = e => {
    const ViewAccess = e.target.checked
    setModuleCreate(ViewAccess)
    setModuleView(ViewAccess)
    setModuleEdit(ViewAccess)
    setModuleDelete(ViewAccess)
  }

  const handleViewTScenarioAccess = e => {
    const ViewAccess = e.target.checked
    setTestScenarioCreate(ViewAccess)
    setTestScenarioView(ViewAccess)
    setTestScenarioEdit(ViewAccess)
    setTestScenarioDelete(ViewAccess)
  }

  const handleViewTCaseAccess = e => {
    const ViewAccess = e.target.checked
    setTestCaseCreate(ViewAccess)
    setTestCaseView(ViewAccess)
    setTestCaseEdit(ViewAccess)
    setTestCaseDelete(ViewAccess)
  }

  const handleViewTPlanAccess = e => {
    const ViewAccess = e.target.checked
    setTestPlanCreate(ViewAccess)
    setTestPlanView(ViewAccess)
    setTestPlanEdit(ViewAccess)
    setTestPlanDelete(ViewAccess)
  }

  const handleViewTCycleAccess = e => {
    const ViewAccess = e.target.checked
    setTestLabCreate(ViewAccess)
    setTestLabView(ViewAccess)
    setTestLabEdit(ViewAccess)
    setTestLabDelete(ViewAccess)
    setTestLabApprove(ViewAccess)
    setTestLabExecute(ViewAccess)
  }

  const handleViewTAutomationAccess = e => {
    const ViewAccess = e.target.checked
    setTestAutomationCreate(ViewAccess)
    setTestAutomationView(ViewAccess)
    setTestAutomationEdit(ViewAccess)
    setTestAutomationDelete(ViewAccess)
  }

  const handleViewDefectAccess = e => {
    const ViewAccess = e.target.checked
    setDefectCreate(ViewAccess)
    setDefectView(ViewAccess)
    setDefectEdit(ViewAccess)
    setDefectDelete(ViewAccess)
  }

  const handleViewRoleAccess = e => {
    const ViewAccess = e.target.checked
    setRoleCreate(ViewAccess)
    setRoleView(ViewAccess)
    setRoleEdit(ViewAccess)
    setRoleDelete(ViewAccess)
  }

  const handleViewUsersAccess = e => {
    const ViewAccess = e.target.checked
    setUserCreate(ViewAccess)
    setUserView(ViewAccess)
    setUserEdit(ViewAccess)
    setUserDelete(ViewAccess)
  }

  const handleViewIntegrationAccess = e => {
    const ViewAccess = e.target.checked
    setIntegrationCreate(ViewAccess)
    setIntegrationView(ViewAccess)
    setIntegrationEdit(ViewAccess)
    setIntegrationDelete(ViewAccess)
  }

  const handleViewTagsAccess = e => {
    const ViewAccess = e.target.checked
    setMetaCreate(ViewAccess)
    setMetaView(ViewAccess)
    setMetaEdit(ViewAccess)
    setMetaDelete(ViewAccess)
  }

  const handleViewDashboard = e => {
    console.log("dkn")

    const ViewAccess = e.target.checked
    setGlobalView(ViewAccess)
    setGlobalDownload(ViewAccess)
  }

  // Render dashboard permissions
  const renderDashboard = () => {
    if (selectedOption === "Global") {
      return (
        <>
          <tr>
            <td>Global Dashboard</td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={globalView}
                onChange={event => {
                  handleIndividualChange(setGlobalView)(event)
                  handleViewDashboard(event)
                }}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={globalDownload}
                disabled={!globalView}
                onChange={handleIndividualChange(setGlobalDownload)}
              />
            </td>
          </tr>
          <tr>
            <td>Project Dashboard</td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={projectDashboardView}
                onChange={handleIndividualChange(setProjectDashboardView)}
              />
            </td>
            <td className="text-center">-</td>
          </tr>
        </>
      )
    }
    return null
  }

  // Render project permissions
  const renderProject = () => {
    if (selectedOption === "Project") {
      return (
        <>
          <tr>
            <td>Project</td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={projectCreate}
                disabled={!projectView}
                onChange={handleIndividualChange(setProjectCreate)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={projectEdit}
                disabled={!projectView}
                onChange={handleIndividualChange(setProjectEdit)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={projectView}
                onChange={event => {
                  handleIndividualChange(setProjectView)(event)
                  handleViewAllAccess(event)
                }}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={projectDelete}
                disabled={!projectView}
                onChange={handleIndividualChange(setProjectDelete)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={projectAddTeam}
                disabled={!projectView}
                onChange={handleIndividualChange(setProjectAddTeam)}
              />
            </td>
          </tr>
          {/* <tr>
            <td>Add Teams</td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={teamAdd}
                onChange={handleIndividualChange(setTeamAdd)}
              />
            </td>
            <td className="text-center">
              -
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={teamView}
                onChange={handleIndividualChange(setTeamView)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={teamDelete}
                onChange={handleIndividualChange(setTeamDelete)}
              />
            </td>
            <td className="text-center">
              -
            </td>
          </tr> */}
          <tr>
            <td>Requirement</td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={requirementCreate}
                disabled={!projectView || !requirementView}
                onChange={handleIndividualChange(setRequirementCreate)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={requirementEdit}
                disabled={!projectView || !requirementView}
                onChange={handleIndividualChange(setRequirementEdit)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={requirementView}
                disabled={!projectView}
                onChange={e => {
                  handleIndividualChange(setRequirementView)(e)
                  handleViewRequirementAccess(e)
                }}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={requirementDelete}
                disabled={!projectView || !requirementView}
                onChange={handleIndividualChange(setRequirementDelete)}
              />
            </td>
            <td className="text-center">-</td>
          </tr>
          <tr>
            <td>Build</td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={buildCreate}
                disabled={!projectView || !buildView}
                onChange={handleIndividualChange(setBuildCreate)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={buildEdit}
                disabled={!projectView || !buildView}
                onChange={handleIndividualChange(setBuildEdit)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={buildView}
                disabled={!projectView}
                onChange={e => {
                  handleIndividualChange(setBuildView)(e)
                  handleViewBuildAccess(e)
                }}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={buildDelete}
                disabled={!projectView || !buildView}
                onChange={handleIndividualChange(setBuildDelete)}
              />
            </td>
            <td className="text-center">-</td>
          </tr>
          <tr>
            <td>Module</td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={moduleCreate}
                disabled={!projectView || !moduleView}
                onChange={handleIndividualChange(setModuleCreate)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={moduleEdit}
                disabled={!projectView || !moduleView}
                onChange={handleIndividualChange(setModuleEdit)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={moduleView}
                disabled={!projectView}
                onChange={e => {
                  handleIndividualChange(setModuleView)(e)
                  handleViewModuleAccess(e)
                }}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={moduleDelete}
                disabled={!projectView || !moduleView}
                onChange={handleIndividualChange(setModuleDelete)}
              />
            </td>
            <td className="text-center">-</td>
          </tr>
        </>
      )
    }
    return null
  }

  // Render test management permissions
  const renderTestManagement = () => {
    if (selectedOption === "TestManagement") {
      return (
        <>
          <tr>
            <td>Test Scenario</td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={testScenarioCreate}
                disabled={!testScenarioView}
                onChange={handleIndividualChange(setTestScenarioCreate)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={testScenarioEdit}
                disabled={!testScenarioView}
                onChange={handleIndividualChange(setTestScenarioEdit)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={testScenarioView}
                onChange={e => {
                  handleIndividualChange(setTestScenarioView)(e)
                  handleViewTScenarioAccess(e)
                }}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={testScenarioDelete}
                disabled={!testScenarioView}
                onChange={handleIndividualChange(setTestScenarioDelete)}
              />
            </td>
          </tr>
          <tr>
            <td>Test Case</td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={testCaseCreate}
                disabled={!testCaseView}
                onChange={handleIndividualChange(setTestCaseCreate)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={testCaseEdit}
                disabled={!testCaseView}
                onChange={handleIndividualChange(setTestCaseEdit)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={testCaseView}
                onChange={e => {
                  handleIndividualChange(setTestCaseView)(e)
                  handleViewTCaseAccess(e)
                }}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={testCaseDelete}
                disabled={!testCaseView}
                onChange={handleIndividualChange(setTestCaseDelete)}
              />
            </td>
          </tr>
        </>
      )
    }
    return null
  }

  // Render defect management permissions
  const renderDefect = () => {
    if (selectedOption === "Defect") {
      return (
        <>
          <tr>
            <td>Defect Management</td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={defectCreate}
                disabled={!defectView}
                onChange={handleIndividualChange(setDefectCreate)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={defectEdit}
                disabled={!defectView}
                onChange={handleIndividualChange(setDefectEdit)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={defectView}
                onChange={e => {
                  handleIndividualChange(setDefectView)(e)
                  handleViewDefectAccess(e)
                }}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={defectDelete}
                disabled={!defectView}
                onChange={handleIndividualChange(setDefectDelete)}
              />
            </td>
          </tr>
        </>
      )
    }
    return null
  }

  // Render test lab permissions
  const renderTestLab = () => {
    if (selectedOption === "TestLab") {
      return (
        <>
          <tr>
            <td>Test Plan</td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={testPlanCreate}
                disabled={!testPlanView}
                onChange={handleIndividualChange(setTestPlanCreate)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={testPlanEdit}
                disabled={!testPlanView}
                onChange={handleIndividualChange(setTestPlanEdit)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={testPlanView}
                onChange={e => {
                  handleIndividualChange(setTestPlanView)(e)
                  handleViewTPlanAccess(e)
                }}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={testPlanDelete}
                disabled={!testPlanView}
                onChange={handleIndividualChange(setTestPlanDelete)}
              />
            </td>
            <td className="text-center">-</td>
            <td className="text-center">-</td>
          </tr>
          <tr>
            <td>Test Cycle</td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={testLabCreate}
                disabled={!testLabView}
                onChange={handleIndividualChange(setTestLabCreate)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={testLabEdit}
                disabled={!testLabView}
                onChange={handleIndividualChange(setTestLabEdit)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={testLabView}
                onChange={e => {
                  handleIndividualChange(setTestLabView)(e)
                  handleViewTCycleAccess(e)
                }}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={testLabDelete}
                disabled={!testLabView}
                onChange={handleIndividualChange(setTestLabDelete)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={testLabApprove}
                disabled={!testLabView}
                onChange={handleIndividualChange(setTestLabApprove)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={testLabExecute}
                disabled={!testLabView}
                onChange={handleIndividualChange(setTestLabExecute)}
              />
            </td>
          </tr>
          <tr>
            <td>Test Automation</td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={testAutomationCreate}
                disabled={!testAutomationView}
                onChange={handleIndividualChange(setTestAutomationCreate)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={testAutomationEdit}
                disabled={!testAutomationView}
                onChange={handleIndividualChange(setTestAutomationEdit)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={testAutomationView}
                onChange={e => {
                  handleIndividualChange(setTestAutomationView)(e)
                  handleViewTAutomationAccess(e)
                }}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={testAutomationDelete}
                disabled={!testAutomationView}
                onChange={handleIndividualChange(setTestAutomationDelete)}
              />
            </td>
            <td className="text-center">-</td>
            <td className="text-center">-</td>
          </tr>
        </>
      )
    }
    return null
  }

  // Render reports  permissions
  const renderReports = () => {
    if (selectedOption === "Reports") {
      return (
        <>
          <tr>
            <td>Defect Reports</td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={reportsDefectCreate}
                onChange={handleIndividualChange(setReportsDefectCreate)}
              />
            </td>
          </tr>
          <tr>
            <td>Test Case Reports</td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={reportsTCCreate}
                onChange={handleIndividualChange(setreportsTCCreate)}
              />
            </td>
          </tr>
          <tr>
            <td>Test Execution Reports</td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={reportsTECreate}
                onChange={handleIndividualChange(setreportsTECreate)}
              />
            </td>
          </tr>
          <tr>
            <td>Requirement Traceability Reports</td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={requirementReports}
                onChange={handleIndividualChange(setRequirementReports)}
              />
            </td>
          </tr>
          <tr>
            <td>Cycle Comparison Reports</td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={reportsCycleCreate}
                onChange={handleIndividualChange(setreportsCycleCreate)}
              />
            </td>
          </tr>
          <tr>
            <td>Schedule Reports</td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={reportsScheduleCreate}
                onChange={handleIndividualChange(setreportsScheduleCreate)}
              />
            </td>
          </tr>
        </>
      )
    }
    return null
  }

  // Render defect management permissions
  const renderSettings = () => {
    if (selectedOption === "Settings") {
      return (
        <>
          <tr>
            <td>Roles</td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={roleCreate}
                disabled={!roleView}
                onChange={handleIndividualChange(setRoleCreate)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={roleEdit}
                disabled={!roleView}
                onChange={handleIndividualChange(setRoleEdit)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={roleView}
                onChange={e => {
                  handleIndividualChange(setRoleView)(e)
                  handleViewRoleAccess(e)
                }}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={roleDelete}
                disabled={!roleView}
                onChange={handleIndividualChange(setRoleDelete)}
              />
            </td>
          </tr>
          <tr>
            <td>Users</td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={userCreate}
                disabled={!userView}
                onChange={handleIndividualChange(setUserCreate)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={userEdit}
                disabled={!userView}
                onChange={handleIndividualChange(setUserEdit)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={userView}
                onChange={e => {
                  handleIndividualChange(setUserView)(e)
                  handleViewUsersAccess(e)
                }}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={userDelete}
                disabled={!userView}
                onChange={handleIndividualChange(setUserDelete)}
              />
            </td>
          </tr>
          <tr>
            <td>Integration</td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={integrationCreate}
                disabled={!integrationView}
                onChange={handleIndividualChange(setIntegrationCreate)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={integrationEdit}
                disabled={!integrationView}
                onChange={handleIndividualChange(setIntegrationEdit)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={integrationView}
                onChange={e => {
                  handleIndividualChange(setIntegrationView)(e)
                  handleViewIntegrationAccess(e)
                }}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={integrationDelete}
                disabled={!integrationView}
                onChange={handleIndividualChange(setIntegrationDelete)}
              />
            </td>
          </tr>
          <tr>
            <td>Tags</td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={metaCreate}
                disabled={!metaView}
                onChange={handleIndividualChange(setMetaCreate)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={metaEdit}
                disabled={!metaView}
                onChange={handleIndividualChange(setMetaEdit)}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={metaView}
                onChange={e => {
                  handleIndividualChange(setMetaView)(e)
                  handleViewTagsAccess(e)
                }}
              />
            </td>
            <td className="text-center">
              <Input
                type="checkbox"
                className="Permission-CheckBox"
                checked={metaDelete}
                disabled={!metaView}
                onChange={handleIndividualChange(setMetaDelete)}
              />
            </td>
          </tr>
        </>
      )
    }
    return null
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Breadcrumb title="Roles" breadcrumbItems={breadcrumbItems} />

        <div className="mt-2 mx-1 col-12 d-flex justify-content-between">
          <div>
            <h3>{!isEditMode ? "Create Roles" : "Edit Roles"}</h3>
            <p className="text-muted">
              Fill the form to {!isEditMode ? "create" : "edit"} a role
            </p>
          </div>
          <div>
            <Link to="/Settings" ref={linkRef}>
              <Button
                type="button"
                color="primary"
                className="btn-label"
                onClick={() => {
                  // navigate(-1);
                  dispatch(setEditRoles({ isEdit: false, data: null }))
                }}
              >
                <i className="bx bx-arrow-back label-icon"></i> Go Back
              </Button>
            </Link>
          </div>
        </div>

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="mb-3 col-xl-4">
                    <Label className="form-label">
                      Role<span className="text-danger">*</span>
                    </Label>
                    <Input
                      name="role_name"
                      type="text"
                      placeholder="Role"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.role_name}
                      invalid={
                        formik.touched.role_name && !!formik.errors.role_name
                      }
                      className="custom-input"
                    />
                    {formik.touched.role_name && formik.errors.role_name && (
                      <FormFeedback>{formik.errors.role_name}</FormFeedback>
                    )}
                  </div>

                  <div className="mb-3 col-12">
                    <Label>
                      Description<span className="text-danger">*</span>
                    </Label>
                    <JoditEditor
                      value={role_description}
                      config={joditConfig()}
                      onBlur={newContent => setDescription(newContent)}
                    />
                    {isDescriptionInvalid && (
                      <FormFeedback className="d-block">
                        Description is required
                      </FormFeedback>
                    )}
                  </div>
                  <Label>Removable ?</Label>
                  <div className="square-switch">
                    <input
                      type="checkbox"
                      id="square-switch3"
                      className="switch switch-bool"
                      defaultChecked={is_removable}
                      onChange={() => setRemovable(!is_removable)}
                    />
                    <label
                      htmlFor="square-switch3"
                      data-on-label="Yes"
                      data-off-label="No"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>

            {isEditMode && (
              <div className="mt-2 mx-1 col-12">
                <CardBody>
                  <h4 className="mb-3">Permissions</h4>
                  <div className="mb-3 d-flex gap-2">
                    <Input
                      type="checkbox"
                      className="Permission-CheckBox"
                      checked={grantPermission}
                      onChange={handleCheckAll}
                    />
                    <Label className="form-label">Grant All Permissions</Label>
                  </div>
                  <div>
                    <UncontrolledAccordion defaultOpen="1">
                      <AccordionItem>
                        <AccordionHeader
                          targetId="1"
                          onClick={() => {
                            handleOptionClick("Global")
                          }}
                        >
                          <strong>Dashboard</strong>
                        </AccordionHeader>
                        <AccordionBody accordionId="1">
                          <div className="taskManagement-Container">
                            <div className="table-responsive accord-scroll">
                              <table className="table table-bordered text-left">
                                <thead className="thead-light">
                                  <tr>
                                    <th scope="col" className="permision-th">
                                      Modules
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-center permision-th"
                                    >
                                      View
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-center permision-th"
                                    >
                                      Download
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>{renderDashboard()}</tbody>
                              </table>
                            </div>
                          </div>
                        </AccordionBody>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionHeader
                          targetId="2"
                          onClick={() => {
                            handleOptionClick("Project")
                          }}
                        >
                          <strong>Projects</strong>
                        </AccordionHeader>
                        <AccordionBody accordionId="2">
                          <div className="taskManagement-Container">
                            <div className="table-responsive accord-scroll">
                              <table className="table table-bordered text-left">
                                <thead className="thead-light bold">
                                  <tr>
                                    <th scope="col" className="permision-th">
                                      Modules
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-center permision-th"
                                    >
                                      Create
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-center permision-th"
                                    >
                                      Edit
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-center permision-th"
                                    >
                                      View
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-center permision-th"
                                    >
                                      Delete
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-center permision-th"
                                    >
                                      Add To Team
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>{renderProject()}</tbody>
                              </table>
                            </div>
                          </div>
                        </AccordionBody>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionHeader
                          targetId="3"
                          onClick={() => {
                            handleOptionClick("TestManagement")
                          }}
                        >
                          <strong>Test Management</strong>
                        </AccordionHeader>
                        <AccordionBody accordionId="3">
                          <div className="taskManagement-Container">
                            <div className="table-responsive accord-scroll">
                              <table className="table table-bordered text-left">
                                <thead className="thead-light">
                                  <tr>
                                    <th scope="col" className="permision-th">
                                      Modules
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-center permision-th"
                                    >
                                      Create
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-center permision-th"
                                    >
                                      Edit
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-center permision-th"
                                    >
                                      View
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-center permision-th"
                                    >
                                      Delete
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>{renderTestManagement()}</tbody>
                              </table>
                            </div>
                          </div>
                        </AccordionBody>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionHeader
                          targetId="4"
                          onClick={() => {
                            handleOptionClick("TestLab")
                          }}
                        >
                          <strong>Test Lab</strong>
                        </AccordionHeader>
                        <AccordionBody accordionId="4">
                          <div className="taskManagement-Container">
                            <div className="table-responsive accord-scroll">
                              <table className="table table-bordered text-left">
                                <thead className="thead-light">
                                  <tr>
                                    <th scope="col" className="permision-th">
                                      Modules
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-center permision-th"
                                    >
                                      Create
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-center permision-th"
                                    >
                                      Edit
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-center permision-th"
                                    >
                                      View
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-center permision-th"
                                    >
                                      Delete
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-center permision-th"
                                    >
                                      Approve
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-center permision-th"
                                    >
                                      Execute
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>{renderTestLab()}</tbody>
                              </table>
                            </div>
                          </div>
                        </AccordionBody>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionHeader
                          targetId="5"
                          onClick={() => {
                            handleOptionClick("Defect")
                          }}
                        >
                          <strong>Defect Management</strong>
                        </AccordionHeader>
                        <AccordionBody accordionId="5">
                          <div className="taskManagement-Container">
                            <table className="table table-bordered text-left">
                              <thead className="thead-light">
                                <tr>
                                  <th scope="col" className="permision-th">
                                    Modules
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-center permision-th"
                                  >
                                    Create
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-center permision-th"
                                  >
                                    Edit
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-center permision-th"
                                  >
                                    View
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-center permision-th"
                                  >
                                    Delete
                                  </th>
                                </tr>
                              </thead>
                              <tbody>{renderDefect()}</tbody>
                            </table>
                          </div>
                        </AccordionBody>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionHeader
                          targetId="6"
                          onClick={() => {
                            handleOptionClick("Reports")
                          }}
                        >
                          <strong>Reports</strong>
                        </AccordionHeader>
                        <AccordionBody accordionId="6">
                          <div className="taskManagement-Container">
                            <div className="table-responsive accord-scroll">
                              <table className="table table-bordered text-left">
                                <thead className="thead-light">
                                  <tr>
                                    <th scope="col" className="permision-th">
                                      Modules
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-center permision-th"
                                    >
                                      View
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>{renderReports()}</tbody>
                              </table>
                            </div>
                          </div>
                        </AccordionBody>
                      </AccordionItem>
                      <AccordionItem>
                        <AccordionHeader
                          targetId="7"
                          onClick={() => {
                            handleOptionClick("Settings")
                          }}
                        >
                          <strong>Settings</strong>
                        </AccordionHeader>
                        <AccordionBody accordionId="7">
                          <div className="taskManagement-Container">
                            <div className="table-responsive accord-scroll">
                              <table className="table table-bordered text-left">
                                <thead className="thead-light">
                                  <tr>
                                    <th scope="col" className="permision-th">
                                      Modules
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-center permision-th"
                                    >
                                      Create
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-center permision-th"
                                    >
                                      Edit
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-center permision-th"
                                    >
                                      View
                                    </th>
                                    <th
                                      scope="col"
                                      className="text-center permision-th"
                                    >
                                      Delete
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>{renderSettings()}</tbody>
                              </table>
                            </div>
                          </div>
                        </AccordionBody>
                      </AccordionItem>
                    </UncontrolledAccordion>
                  </div>
                </CardBody>
              </div>
            )}

            <Col lg={12}>
              <Row>
                <Col md="12" className="text-end mt-3 mb-2">
                  <Button
                    color="primary"
                    type="submit"
                    onClick={submitData}
                    disabled={loading}
                  >
                    {loading ? "Loading..." : "Submit"}
                  </Button>
                  <Link to="/Settings">
                    <Button
                      type="button"
                      color="secondary"
                      onClick={() => {
                        formik.resetForm()
                        dispatch(setEditRoles({ isEdit: false, data: null }))
                      }}
                      className="ms-2"
                    >
                      Cancel
                    </Button>
                  </Link>
                  <Link to="/Settings/CreateEditRoles" ref={editRef}></Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </React.Fragment>
  )
}

export default CreateRoles
