import React from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
  Form,
  FormFeedback,
  UncontrolledTooltip,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import "flatpickr/dist/themes/material_blue.css"
import { useSelector, useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { useLocation, useNavigate } from "react-router-dom"
import Breadcrumb from "components/Common/Breadcrumb"
import "./CreateTestDataForm.scss"
import {
  createTestDataAPI,
  EditTestDataAPI,
} from "store/actions/TestDataActions"
import { setEditTestData } from "store/Slices/TestDataSlice"

const checkValidations = testDataFromValues => {
  const { key_value_data, file_name } = testDataFromValues
  const error = {}

  // Validate file_name
  const isValidFilename = /^[a-zA-Z0-9_]+$/.test(file_name)

  if (!file_name) {
    error.file_name = "File name is required."
  } else if (!isValidFilename) {
    error.file_name =
      "File name can have underscores and should not contain spaces or special characters."
  }

  // Validate key_value_data
  const validKeyValueData = key_value_data.filter(
    item => item.key.trim() && item.value.trim()
  )

  if (validKeyValueData.length === 0) {
    error.key_value_data = "Please enter at least one key-value pair."
  }

  return error
}

const CreateTestDataForm = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const location = useLocation()
  const { isEdit, data } = location.state || {}

  const loading = useSelector(state => state?.Loading?.isLoading)
  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )
  const editTestData = useSelector(state => state?.TestData?.editTestData)

  const isEditMode = editTestData?.isEdit || isEdit
  const isEditModeData = editTestData?.data || data

  const breadcrumbItems = [
    { label: "Test Data", path: "/TestAutomation/TestData" },
    {
      label: isEditMode ? "Edit Test Data " : "Create Test Data",
      path: "/",
    },
  ]
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      project_id: globalProject?.value,
      file_name: isEditModeData ? isEditModeData?.file_name || "" : "",
      key_value_data: isEditModeData
        ? isEditModeData?.key_value_data || [{ key: "", value: "" }]
        : [{ key: "", value: "" }],
    },
    onSubmit: values => {
      const errors = checkValidations(values)
      if (Object.keys(errors).length > 0) {
        // Handle validation errors (e.g., set form errors in Formik)
        formik.setErrors(errors)
        return
      }
      const validKeyValueData = values?.key_value_data.filter(
        item => item.key.trim() && item.value.trim()
      )

      const key_value_data_json = JSON.stringify(validKeyValueData)
      const formData = {
        ...values,
        key_value_data: key_value_data_json,
      }
      createData(formData)
    },
  })

  const createData = async formData => {
    const editFromValues = {
      ...formData,
      id: isEditModeData?.id,
    }

    if (!isEditModeData) {
      try {
        await dispatch(createTestDataAPI(formData))
        navigate("/TestAutomation/TestData") // Navigate back or handle success
      } catch (error) {
        console.error("Error creating test data:", error)
        toast.error("An error occurred while creating Test data", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
      }
    } else {
      try {
        await dispatch(EditTestDataAPI(editFromValues))
        navigate("/TestAutomation/TestData") // Navigate back or handle success
        dispatch(setEditTestData({ isEdit: false, data: null }))
      } catch (error) {
        console.error("Error updating test data:", error)
        toast.error("An error occurred while updating Test data", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
      }
    }
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Breadcrumb title="Test Data" breadcrumbItems={breadcrumbItems} />

        <div className="mt-2 mx-3 d-flex justify-content-between">
          <div>
            <h3>{!isEditMode ? "Create New Test Data" : "Edit Test Data"}</h3>
            <p className="text-muted">
              Fill the form to {!isEditMode ? "create" : "edit"} a test Data
            </p>
          </div>
          <div>
            <Button
              type="button"
              color="primary"
              className="btn-label"
              onClick={() => {
                navigate(-1)
                dispatch(setEditTestData({ isEdit: false, data: null }))
              }}
            >
              <i className="bx bx-arrow-back label-icon"></i> Go Back
            </Button>
          </div>
        </div>

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg={4}>
              <Card>
                <CardBody>
                  <div className="mb-3">
                    <Label className="form-label">Project</Label>
                    <Input
                      name="project_id"
                      type="text"
                      placeholder="Project"
                      value={globalProject?.label}
                      className="custom-input"
                      readOnly
                      disabled
                    />
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">
                      File Name
                      <span className="text-danger">*</span>
                    </Label>
                    <Input
                      name="file_name"
                      type="text"
                      placeholder="File Name"
                      {...formik.getFieldProps("file_name")}
                      className="custom-input"
                      invalid={!!formik.errors.file_name}
                    />
                    {formik.errors.file_name ? (
                      <div className="invalid-feedback">
                        {formik.errors.file_name}
                      </div>
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col lg={8}>
              <Card>
                <CardBody>
                  {formik.values.key_value_data.map((item, index) => (
                    <div className="mb-3" key={index}>
                      <div className="d-flex mb-2 align-items-center justify-content-center">
                        <div className="flex-grow-1 me-2">
                          <Label className="form-label">
                            Key
                            {index === 0 && (
                              <span className="text-danger">*</span>
                            )}
                          </Label>
                          <Input
                            name={`key_value_data[${index}].key`}
                            type="text"
                            placeholder="Key"
                            {...formik.getFieldProps(
                              `key_value_data[${index}].key`
                            )}
                            className="custom-input"
                            invalid={
                              index === 0 &&
                              !!formik.errors.key_value_data?.[index]?.key
                            }
                          />
                          {index === 0 &&
                            formik.errors.key_value_data?.[index]?.key && (
                              <FormFeedback>
                                {formik.errors.key_value_data[index].key}
                              </FormFeedback>
                            )}
                        </div>

                        <div className="flex-grow-1 me-2">
                          <Label className="form-label">
                            Value
                            {index === 0 && (
                              <span className="text-danger">*</span>
                            )}
                          </Label>
                          <Input
                            name={`key_value_data[${index}].value`}
                            type="text"
                            placeholder="Value"
                            {...formik.getFieldProps(
                              `key_value_data[${index}].value`
                            )}
                            className="custom-input"
                            invalid={
                              index === 0 &&
                              !!formik.errors.key_value_data?.[index]?.value
                            }
                          />
                          {index === 0 &&
                            formik.errors.key_value_data?.[index]?.value && (
                              <FormFeedback>
                                {formik.errors.key_value_data[index].value}
                              </FormFeedback>
                            )}
                        </div>
                        <div className="pt-4">
                          {formik.values.key_value_data.length > 1 &&
                            index !== 0 && (
                              <div>
                                <i
                                  className="mdi mdi-delete label-icon h4"
                                  onClick={() => {
                                    const updatedKeyValueData =
                                      formik.values.key_value_data.filter(
                                        (_, i) => i !== index
                                      )
                                    formik.setFieldValue(
                                      "key_value_data",
                                      updatedKeyValueData
                                    )
                                  }}
                                  id="delete"
                                >
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="delete"
                                  >
                                    Delete Key Value Pair
                                  </UncontrolledTooltip>
                                </i>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  ))}
                  {formik.errors.key_value_data && (
                    <div className="text-danger">
                      {formik.errors.key_value_data}
                    </div>
                  )}
                </CardBody>
              </Card>

              <Col lg={12}>
                <Row>
                  <Col md="12" className="text-end mt-3">
                    <Button
                      color="secondary"
                      type="button"
                      onClick={() =>
                        formik.setFieldValue("key_value_data", [
                          ...formik.values.key_value_data,
                          { key: "", value: "" },
                        ])
                      }
                    >
                      Add More
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Col>

            <Col lg={12} className="mb-2">
              <Row>
                <Col md="12" className="text-end mt-3">
                  <Button color="primary" type="submit" disabled={loading}>
                    {loading ? "Loading..." : "Submit"}
                  </Button>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => {
                      formik.resetForm()
                      navigate(-1)
                      dispatch(setEditTestData({ isEdit: false, data: null }))
                    }}
                    className="ms-2"
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </React.Fragment>
  )
}

export default CreateTestDataForm
