import React, { useEffect, useState } from "react"
import {
  Alert,
  Label,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
} from "reactstrap"
import Select from "react-select"
import { fetchAllBuildsList } from "store/actions/BuildsActions"
import { fetchAllModulesList } from "store/actions/ModuleActions"
import { fetchAllRequirementsList } from "store/actions/RequirementActions"
import { useDispatch } from "react-redux"
import { useSelector } from "react-redux"
import { createOptions, createOptionsDefectType } from "helpers/helper"
import { getTagsStautusAPI } from "store/actions/TagsAction"
import {
  fetchAllTeamsDetailsUserAssigneeList,
  fetchAllUsersDetailsList,
  fetchUsersList,
} from "store/actions/UserActions"
import { MapDefectStatus } from "store/actions/DefectsActions"

const BulkEditDefects = ({
  isOpen,
  toggle,
  setSelectedRows,
  handleBulkEdit,
}) => {
  const dispatch = useDispatch()

  const [selectedBuild, setSelectedBuild] = useState(null)
  const [selectedModule, setSelectedModule] = useState(null)
  const [selectedRequirement, setSelectedRequirement] = useState(null)
  const [selectedDefectType, setSetselectedDefectType] = useState(null)
  const [selectedDefectStatus, setSelectedDefectStatus] = useState(null)
  const [selectedPriority, setSelectedPriority] = useState(null)
  const [selectedSeverity, setSelectedSeverity] = useState(null)
  const [selectedOs, setSelectedOs] = useState(null)
  const [selectedBrowsers, setSelectedBrowsers] = useState(null)
  const [selectedDevices, setSelectedDevices] = useState(null)
  const [selectedAssignTo, setSelectedAssignTo] = useState(null)
  const [selectedDefectViewers, setSelectedDefectViewers] = useState(null)

  const [buildOptionValue, setBuildOptionValues] = useState([])
  const [moduleOptionValue, setModuleOptionValues] = useState([])
  const [requirementOptionValue, setrequirementOptionValues] = useState([])
  const [usersOptionsValue, setUsersOptionsValue] = useState([])
  const [defectViewersOptionValue, setDefectViewersOptionValue] = useState([])
  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )
  const loading = useSelector(state => state?.Loading?.isLoading)

  const layoutModeType = useSelector(state => state.Layout.layoutModeType)

  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888",
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Ensure input text is visible
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150, // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  }
  const handleClearFilters = () => {
    setSelectedBuild(null)
    setSelectedModule(null)
    setSelectedRequirement(null)
    setSetselectedDefectType(null)
    setSelectedDefectStatus(null)
    setSelectedPriority(null)
    setSelectedSeverity(null)
    setSelectedOs(null)
    setSelectedBrowsers(null)
    setSelectedDevices(null)
    setSelectedAssignTo(null)
    setSelectedDefectViewers(null)
  }
  const handleApplyBulkEdit = () => {
    let data = {
      build_id: selectedBuild?.value,
      module_id: selectedModule?.value,
      requirement_id: selectedRequirement?.value,
      defect_status: selectedDefectStatus?.value,
      defect_type: selectedDefectType?.value,
      severity: selectedSeverity?.value,
      defect_priority: selectedPriority?.value,
      os: selectedOs?.value,
      browsers: selectedBrowsers?.value,
      devices: selectedDevices?.value,
      assign_to: selectedAssignTo?.value,
      defect_viewers: selectedDefectViewers?.value,
    }
    handleBulkEdit(data)
    setTimeout(() => {
      handleClearFilters()
    }, 2000)
  }

  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))

  useEffect(() => {
    let bodyOption = {
      project_id: globalProject?.value || selectedProject?.value,
    }
    const fetchOptions = async () => {
      try {
        const responses = await Promise.all([
          dispatch(fetchAllBuildsList(bodyOption)),
          dispatch(fetchAllModulesList(bodyOption)),
          dispatch(fetchAllRequirementsList(bodyOption)),
          dispatch(fetchAllTeamsDetailsUserAssigneeList(bodyOption)),
          // dispatch(fetchUsersList(bodyOption)),
          // dispatch(fetchAllUsersDetailsList(bodyOption)),
        ])

        const [
          buildResponse,
          moduleResponse,
          reqResponse,
          userListResponse,
          // userDetailsListResponse,
        ] = responses

        const usersListOptions = userListResponse?.results?.map(entry => ({
          value: entry.id,
          label: entry.user_name,
        }))

        const buildOptions = buildResponse?.map(entry => ({
          value: Math.round(entry.id),
          label: entry.build_id,
        }))

        const moduleOptions = moduleResponse?.map(entry => ({
          value: entry.id,
          label: entry.module_name,
        }))

        const reqOptions = reqResponse?.map(entry => ({
          value: entry.id,
          label: entry.requirement_title,
        }))

        setBuildOptionValues(buildOptions)
        setModuleOptionValues(moduleOptions)
        setrequirementOptionValues(reqOptions)
        setUsersOptionsValue(usersListOptions)
        setDefectViewersOptionValue(usersListOptions)
      } catch (error) {
        console.error(error)
      }
    }

    fetchOptions()
  }, [isOpen])

  const [defectTypeValue, setDefectTypeValue] = useState([])
  const [severityOptionValues, setSeverityOptionValues] = useState([])
  const [statusOptionValues, setStatusOptionValues] = useState([])
  const [osValue, setOsValue] = useState([])
  const [browserValue, setBrowserValue] = useState([])
  const [deviceValue, setDeviceValue] = useState([])
  const [priorityValue, setPriorityValue] = useState([])

  const handleClose = () => {
    toggle(),
      setSelectedRows([]),
      setTimeout(() => {
        handleClearFilters()
      }, 2000)
  }

  const [HasJiraId, setHasJiraId] = useState(false)
  const [HasPlaneId, setHasPlaneId] = useState(false)

  const fetchMapStatus = async () => {
    try {
      const responses = await Promise.all([
        dispatch(MapDefectStatus({ project_id: globalProject?.value })),
      ])

      const [statusResponse] = responses

      setStatusOptionValues(
        statusResponse?.statuses?.map(entry => ({
          value: entry,
          label: entry,
        }))
      )
    } catch (error) {
      console.error("Failed to fetch options", error)
      toast.error("Failed to fetch options.")
    }
  }
  const mapProjectList = useSelector(state => state?.Integration?.mapList)

  useEffect(() => {
    const filteredProjectList = mapProjectList?.filter(
      project => project.product === "Jira"
    )

    const hasJiraId = filteredProjectList?.some(
      defect => defect?.frothtestops_project === globalProject?.label
    )
    setHasJiraId(hasJiraId)

    const filteredPlaneList = mapProjectList?.filter(
      project => project.product === "Plane"
    )
    const hasPlanId = filteredPlaneList?.some(
      defect => defect?.frothtestops_project === globalProject?.label
    )
    setHasPlaneId(hasPlanId)

    if (hasPlanId || hasJiraId) {
      fetchMapStatus()
    }
  }, [mapProjectList])

  const sync = HasJiraId || HasPlaneId

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const bodyOption = { project_id: globalProject?.value }
        const metaDataResponse = await dispatch(getTagsStautusAPI(bodyOption))

        const {
          Defect_Status,
          Severity,
          Device,
          OS,
          Browser,
          Defect_Type,
          Priority,
        } = metaDataResponse?.data || {}

        setDefectTypeValue(createOptionsDefectType(Defect_Type || []))
        setSeverityOptionValues(createOptions(Severity || []))
        if (!sync) {
          setStatusOptionValues(createOptions(Defect_Status || []))
        }
        setOsValue(createOptions(OS || []))
        setBrowserValue(createOptions(Browser || []))
        setDeviceValue(createOptions(Device || []))
        setPriorityValue(createOptions(Priority || []))
      } catch (error) {
        console.log(error)
      }
    }

    fetchOptions()
  }, [isOpen])
  return (
    <Offcanvas isOpen={isOpen} direction="end" toggle={handleClose}>
      <OffcanvasHeader toggle={handleClose}>Bulk Edit Defects</OffcanvasHeader>
      <div className=" mx-3">
        <Alert color="info" role="alert">
          By Updating all the previous associations for selected records will be
          reset.
        </Alert>
      </div>
      <OffcanvasBody>
        <div className="mb-3">
          <Label>Build</Label>
          <Select
            value={selectedBuild}
            onChange={setSelectedBuild}
            options={buildOptionValue}
            styles={customStyles}
            maxMenuHeight={200}
            className="select2-selection"
          />
        </div>
        <div className="mb-3">
          <Label>Module</Label>
          <Select
            value={selectedModule}
            onChange={setSelectedModule}
            options={moduleOptionValue}
            styles={customStyles}
            maxMenuHeight={200}
            className="select2-selection"
          />
        </div>
        <div className="mb-3">
          <Label>Requirement</Label>
          <Select
            value={selectedRequirement}
            onChange={setSelectedRequirement}
            options={requirementOptionValue}
            styles={customStyles}
            maxMenuHeight={200}
            className="select2-selection"
          />
        </div>
        <div className="mb-3">
          <Label>Defect Type</Label>
          <Select
            value={selectedDefectType}
            onChange={setSetselectedDefectType}
            options={defectTypeValue}
            styles={customStyles}
            maxMenuHeight={200}
            className="select2-selection"
          />
        </div>
        <div className="mb-3">
          <Label>Status</Label>
          <Select
            value={selectedDefectStatus}
            onChange={setSelectedDefectStatus}
            options={statusOptionValues}
            styles={customStyles}
            maxMenuHeight={200}
            className="select2-selection"
          />
        </div>
        <div className="mb-3">
          <Label>Priority</Label>
          <Select
            value={selectedPriority}
            onChange={setSelectedPriority}
            options={priorityValue}
            styles={customStyles}
            maxMenuHeight={200}
            className="select2-selection"
          />
        </div>

        <div className="mb-3">
          <Label>Severity</Label>
          <Select
            value={selectedSeverity}
            onChange={setSelectedSeverity}
            options={severityOptionValues}
            styles={customStyles}
            maxMenuHeight={200}
            className="select2-selection"
          />
        </div>
        <div className="mb-3">
          <Label>OS</Label>
          <Select
            value={selectedOs}
            onChange={setSelectedOs}
            options={osValue}
            styles={customStyles}
            maxMenuHeight={200}
            className="select2-selection"
          />
        </div>
        <div className="mb-3">
          <Label>Browsers</Label>
          <Select
            value={selectedBrowsers}
            onChange={setSelectedBrowsers}
            options={browserValue}
            styles={customStyles}
            maxMenuHeight={200}
            className="select2-selection"
          />
        </div>
        <div className="mb-3">
          <Label>Devices</Label>
          <Select
            value={selectedDevices}
            onChange={setSelectedDevices}
            options={deviceValue}
            styles={customStyles}
            maxMenuHeight={200}
            className="select2-selection"
          />
        </div>
        <div className="mb-3">
          <Label>Assign To</Label>
          <Select
            value={selectedAssignTo}
            onChange={setSelectedAssignTo}
            options={usersOptionsValue}
            styles={customStyles}
            maxMenuHeight={200}
            className="select2-selection"
          />
        </div>
        <div className="mb-3">
          <Label>Defect Viewers</Label>
          <Select
            value={selectedDefectViewers}
            onChange={setSelectedDefectViewers}
            options={defectViewersOptionValue}
            styles={customStyles}
            maxMenuHeight={200}
            className="select2-selection"
          />
        </div>
        <div className="d-flex justify-content-between mt-5">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={handleClose}
          >
            Cancel
          </button>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleApplyBulkEdit}
            disabled={loading}
          >
            {loading ? "Loading..." : "Apply"}
          </button>
        </div>
      </OffcanvasBody>
    </Offcanvas>
  )
}

export default BulkEditDefects
