import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import "flatpickr/dist/themes/material_blue.css"
import JoditEditor from "jodit-react"
import { useSelector, useDispatch } from "react-redux"
import { joditConfig } from "../../../../../helpers/helper"
import { toast } from "react-toastify"
import { useLocation, useNavigate } from "react-router-dom"
import Breadcrumb from "components/Common/Breadcrumb"
import {
  CreatePrecondition,
  EditPrecondition,
} from "store/actions/TestCaseAction"
import { setEditPreCondition } from "store/Slices/TestCaseSlice"

const CreateEditPreCondition = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { isEdit, data } = location.state || {}

  const loading = useSelector(state => state?.Loading?.isLoading)

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const stripHtmlTags = html => {
    const doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent || ""
  }

  const editPreCondition = useSelector(
    state => state?.TestCase?.editPreCondition
  )

  const isEditMode = editPreCondition?.isEdit || isEdit
  const isEditModeData = editPreCondition?.data || data

  const [description, setDescription] = useState(
    isEditMode ? isEditModeData?.pre_condition_details : ""
  )
  const [isDescriptionInvalid, setIsDescriptionInvalid] = useState(false)

  const breadcrumbItems = [
    { label: "Test Case", path: "/TestManagement/TestCase" },
    {
      label: isEditMode ? "Edit Pre Condition " : "Create Pre Condition",
      path: "/",
    },
  ]

  const validationSchema = Yup.object().shape({
    condition_name: Yup.string()
      .trim()
      .matches(/^.*\S.*$/, "Pre Condition Name cannot contain only spaces")
      .required("Pre Condition Name is required"),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      project_id: globalProject?.value,
      condition_name: isEditModeData
        ? isEditModeData?.condition_name || ""
        : "",
    },
    validationSchema,
    onSubmit: values => {
      if (!stripHtmlTags(description).trim()) {
        setIsDescriptionInvalid(true)
      } else {
        const formData = {
          ...values,
          pre_condition_details: description,
        }
        createPreCondition(formData)
      }
    },
  })

  const checkError = () => {
    if (!stripHtmlTags(description).trim()) {
      setIsDescriptionInvalid(true)
    }
  }
  const createPreCondition = async formData => {
    const editFromValues = {
      ...formData,
      id: isEditModeData?.id,
    }

    if (!isEditModeData) {
      try {
        await dispatch(CreatePrecondition(formData))
        navigate(-1) // Navigate back or handle success
      } catch (error) {
        console.error("Error creating test scenario:", error)
        toast.error(error || "An error occurred while creating", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
      }
    } else {
      try {
        await dispatch(EditPrecondition(editFromValues))
        navigate(-1) // Navigate back or handle success
        dispatch(setEditPreCondition({ isEdit: false, data: null }))
      } catch (error) {
        console.error("Error updating test scenario:", error)
        toast.error(error || "An error occurred while updating", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
      }
    }
  }

  useEffect(() => {
    if (isEditModeData) {
      setDescription(isEditModeData.pre_condition_details || "")
    }
  }, [isEditModeData])

  return (
    <React.Fragment>
      <Container fluid>
        <Breadcrumb title="Pre Condition" breadcrumbItems={breadcrumbItems} />

        <div className="mt-2 mx-3 d-flex justify-content-between">
          <div>
            <h3>
              {!isEditMode ? "Create New Pre Condition" : "Edit Pre Condition"}
            </h3>
            <p className="text-muted">
              Fill the form to {!isEditMode ? "create" : "edit"} a pre condition
            </p>
          </div>
          <div>
            <Button
              type="button"
              color="primary"
              className="btn-label"
              onClick={() => {
                navigate(-1)
                dispatch(setEditPreCondition({ isEdit: false, data: null }))
              }}
            >
              <i className="bx bx-arrow-back label-icon"></i> Go Back
            </Button>
          </div>
        </div>

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="mb-3 col-12 col-md-4">
                    <Label className="form-label">
                      Pre Condition Name <span className="text-danger">*</span>
                    </Label>
                    <Input
                      name="condition_name"
                      type="text"
                      placeholder="Enter Pre Condition Name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.condition_name}
                      invalid={
                        formik.touched.condition_name &&
                        !!formik.errors.condition_name
                      }
                      className="custom-input"
                    />
                    {formik.touched.condition_name &&
                      formik.errors.condition_name && (
                        <FormFeedback>
                          {formik.errors.condition_name}
                        </FormFeedback>
                      )}
                  </div>

                  <Col md="12">
                    <Label>
                      Pre Condition Details{" "}
                      <span className="text-danger">*</span>
                    </Label>
                    <JoditEditor
                      value={description}
                      config={joditConfig()}
                      onBlur={newContent => setDescription(newContent)}
                      // onBlur={() => setIsDescriptionInvalid(!description)}
                    />
                    {isDescriptionInvalid && (
                      <FormFeedback className="d-block">
                        Pre Condition Details is required
                      </FormFeedback>
                    )}
                  </Col>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col md="12" className="text-end mt-3">
              <Button
                color="primary"
                type="submit"
                onClick={checkError}
                disabled={loading}
              >
                {loading ? "Loading..." : "Submit"}
              </Button>
              <Button
                type="button"
                color="secondary"
                onClick={() => {
                  formik.resetForm()
                  navigate(-1)
                  dispatch(setEditPreCondition({ isEdit: false, data: null }))
                }}
                className="ms-2"
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>
    </React.Fragment>
  )
}

export default CreateEditPreCondition
