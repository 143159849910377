import React, { useState } from "react"
import {
  Badge,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip,
  Modal,
  Button,
} from "reactstrap"
import { Link } from "react-router-dom"
import { getStatusBackGroundColor } from "../../../src/helpers/helper"
import { useNavigate } from "react-router-dom"
import DeleteModal from "components/Common/DeleteModal"
import { toast } from "react-toastify"
import httpInjectorService from "services/http-Injector.Service"
import {
  fetchProjectDetails,
  fetchProjectForSelectProject,
  ProjectDetailsView,
} from "../../store/ProjectManagement/actions"
import { useSelector, useDispatch } from "react-redux"
import { SelProject, setProjEditData } from "store/actions/ProjectActions"
import moment from "moment"
import { setSelectetProject } from "store/Slices/GlobalSlice"

const capitalizeFirstLetter = str => {
  return str.replace(/\b\w/g, char => char.toUpperCase())
}

const ProjectGrid = props => {
  const projects = props.projects
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [deleteModal, setDeleteModal] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(null)
  const [id, setId] = useState(null)
  const RouteUrl = "Requirement"
  const toggleDropdown = key => {
    setDropdownOpen(dropdownOpen === key ? null : key)
  }

  const permission = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission =
    permission?.permissions?.[0]?.menu_item?.ProjectManagement

  const onSelectProject = project => {
    localStorage.setItem("selectedProjectData", JSON.stringify(project))
    navigate(`/ProjectManagement/${RouteUrl}/${project.id}`)
  }

  const deleteProject = async id => {
    try {
      const response = await httpInjectorService.deleteProject(id)
      console.log(response)

      if (response.status) {
        await localStorage.removeItem("selectedProject")
        await dispatch(fetchProjectDetails())
        await dispatch(fetchProjectForSelectProject())
        await dispatch(setSelectetProject())
        window.location.reload()

        const errorMessage =
          typeof response.message === "string"
            ? response.message
            : response.message.join(", ")
        toast.success(errorMessage, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
      }
    } catch (err) {
      toast.error(err, {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 1000,
      })
    }
  }

  const onClickDelete = id => {
    if (rolePermission.Project?.delete) {
      setId(id)
      setDeleteModal(true)
    } else {
      toast.warn("You don't have the permission to delete project", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const onClickAddUser = project => {
    dispatch(SelProject(project))
    if (rolePermission.Project?.addToTeam) {
      navigate(`/ProjectManagement/AddUser/${project.id}`)
    } else {
      toast.warn("You don't have the permission to add user", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const onClickEditUser = project => {
    if (rolePermission.Project?.edit) {
      dispatch(setProjEditData(project))
      navigate("/ProjectManagement/EditProject", {
        state: { data: project },
      })
    } else {
      toast.warn("You don't have the permission to edit project", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const handleDeleteProject = () => {
    if (id) {
      deleteProject(id)
      setDeleteModal(false)
    }
  }

  const ProjectView = project => {
    if (rolePermission.Project?.view) {
      dispatch(setProjEditData(project))
      dispatch(ProjectDetailsView(project.id))
      navigate("/ProjectManagement/view")
    } else {
      toast.warn("You don't have the permission to view project", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const Project_Status = useSelector(
    state => state.ProjectManagement?.tagStatus?.data?.Project_Status
  )
  const mapStatusToCategory = status => {
    for (let category in Project_Status) {
      if (Project_Status[category].includes(status)) {
        return category
      }
    }
    return status
  }

  return (
    <React.Fragment>
      {(projects || []).map((project, key) => (
        <Col xl={4} sm={6} key={key}>
          <Card>
            <CardBody
              onClick={() => onSelectProject(project)}
              style={{ cursor: "pointer" }}
            >
              <div className="d-flex justify-content-between">
                <div className="d-flex">
                  <div className="avatar-xs me-4">
                    <span
                      className={
                        "avatar-title bg-" +
                        `${getStatusBackGroundColor(
                          mapStatusToCategory(project.project_status)
                        )}` +
                        "-subtle text-" +
                        `${getStatusBackGroundColor(
                          mapStatusToCategory(project.project_status)
                        )}`
                      }
                      style={{ fontSize: "20px", borderRadius: "8px" }}
                    >
                      {project.project_name.slice(0, 2).toUpperCase()}
                    </span>
                  </div>
                  <div className="flex-grow-1 overflow-hidden">
                    <h5
                      id={`card-label-${project.id}`} // Unique id for the tooltip target
                      className="text-truncate font-size-15 card-label"
                      onClick={() => onSelectProject(project)}
                      style={{
                        maxWidth: "180px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Link
                        to={`/ProjectManagement/${RouteUrl}/${project.id}`}
                        className="text-dark"
                      >
                        {capitalizeFirstLetter(project.project_name)}
                      </Link>
                      <UncontrolledTooltip
                        placement="top"
                        target={`card-label-${project.id}`} // Targeting the unique id
                      >
                        {capitalizeFirstLetter(project.project_name)}
                      </UncontrolledTooltip>
                    </h5>
                    <p className="text-muted mb-4">{project.project_type}</p>
                  </div>
                </div>
                <div style={{ position: "relative" }}>
                  <Dropdown
                    isOpen={dropdownOpen === key}
                    toggle={event => {
                      event.stopPropagation()
                      toggleDropdown(key)
                    }}
                    style={{ position: "relative" }}
                  >
                    <DropdownToggle tag="span" style={{ cursor: "pointer" }}>
                      <i className="bx bx-dots-vertical-rounded" />
                    </DropdownToggle>
                    <DropdownMenu
                      style={{ right: 0, left: "auto", position: "absolute" }}
                    >
                      <DropdownItem onClick={() => onClickAddUser(project)}>
                        <i className="bx bxs-user-plus font-size-16 me-1" />
                        Add User
                      </DropdownItem>
                      <DropdownItem onClick={() => onClickEditUser(project)}>
                        <i className="bx bx-pencil font-size-16 me-1" />
                        Edit Project
                      </DropdownItem>
                      <DropdownItem onClick={() => onClickDelete(project.id)}>
                        <i className="mdi mdi-trash-can font-size-16 me-1" />
                        Delete Project
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>
              </div>
            </CardBody>
            <div className="px-3 py-3 border-top d-flex justify-content-between mb-0">
              <div className="me-3" id={`dueDate-${key}`}>
                <i className="bx bx-calendar me-1" />
                {moment(project.planned_end_date).format("DD/MM/YYYY")}
                <UncontrolledTooltip placement="top" target={`dueDate-${key}`}>
                  Due Date
                </UncontrolledTooltip>
              </div>
              <div className="me-3" id={`comments-${key}`}>
                <i className="bx bx-group me-1" />
                {project.team_size || 0}
                <UncontrolledTooltip placement="top" target={`comments-${key}`}>
                  Team size
                </UncontrolledTooltip>
              </div>
              <div className="me-1">
                <Badge
                  id="hide-width"
                  className={
                    "badge-soft-" +
                    `${getStatusBackGroundColor(
                      mapStatusToCategory(project.project_status)
                    )}`
                  }
                  style={{
                    fontSize: "10px",
                    padding: "0.5em",
                    borderRadius: "0.5em",
                  }}
                >
                  {project.project_status}
                </Badge>
              </div>
              <div
                className="me-3"
                id={`view-${key}`}
                onClick={() => ProjectView(project)}
              >
                <i className="mdi mdi-eye" style={{ cursor: "pointer" }} />
                <UncontrolledTooltip placement="top" target={`view-${key}`}>
                  Project view
                </UncontrolledTooltip>
              </div>
            </div>
          </Card>
        </Col>
      ))}
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteProject}
        onCloseClick={() => setDeleteModal(false)}
        message={"Project"}
      />
    </React.Fragment>
  )
}

export default ProjectGrid
