import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
  Modal,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  InputGroup,
  InputGroupText,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Breadcrumbs from "../../../../../components/Common/Breadcrumb"
import "flatpickr/dist/themes/material_blue.css"
import JoditEditor from "jodit-react"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import { joditConfig } from "helpers/helper"
import { useLocation, useNavigate } from "react-router-dom"
import {
  AutomationScriptDetails,
  CreateAutomationScrit,
  GenerateAutomationScriptId,
  UpdateAutomationScript,
  WebActionDetails,
} from "store/actions/AutomationScriptAction"
import { BuildsDetails } from "store/actions"
import { TestCaseDetails } from "store/actions/TestCaseAction"
import ManageDbIntegration from "../ManageDbIntegration/ManageDbIntegration"
import Editor from "../Editor/Editor"
import "ace-builds/src-noconflict/ace"
import "ace-builds/src-noconflict/theme-idle_fingers"
import "ace-builds/src-noconflict/mode-html"
import CopyPopUp from "../copyPopUp/CopyPopUp"
import styles from "../copyPopUp/CopyPopUp.module.scss"

const CreateAutomationScript = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let config = joditConfig()
  const location = useLocation()
  const { data } = location.state || {}
  const currentUrl = window.location.href
  const parts = currentUrl.split("/")
  const GlobalSelectedProject = JSON.parse(
    localStorage.getItem("selectedProject")
  )
  const [description, setDescription] = useState("")
  const [buildValues, setBuildValues] = useState([])
  const [webActionDescription, setwebActionDescription] = useState([])
  const [webActionDetails, setwebActionDetails] = useState([])
  const [testCases, settestCases] = useState([])
  const buildDetails = useSelector(state => state.Project?.buildDetails)
  const testCase = useSelector(state => state.TestCase?.testCaseList)
  const [isRight, setIsRight] = useState(false)
  const loading = useSelector(state => state?.Loading?.isLoading)

  const nextAutomationScriptId = useSelector(
    state =>
      state?.AutomationScript?.NextAutomationScriptId?.next_automation_script_id
  )
  const selectedAutomationScriptData =
    data ||
    useSelector(state => state?.AutomationScript?.singleAutomationScriptData)
  const scriptCode = useSelector(state => state?.AutomationScript?.scriptCode)
  const dbIntegrationData = useSelector(
    state => state?.AutomationScript?.dbIntegrationData
  )
  const [isdescription, setIsDescription] = useState(false)
  const isCreateMode = currentUrl.includes("CreateAutomationScript")
  const isEditMode = currentUrl.includes("EditAutomationScript")
  const [showForm, setShowForm] = useState(true)
  const [modal_center, setmodal_center] = useState(false)
  const editModeStatus = isEditMode ? true : false
  const layoutModeType = useSelector(state => state.Layout.layoutModeType)
  const editModeDataValues = isEditMode
    ? selectedAutomationScriptData.file_content
    : " "
  const [copyPopUp, setCopyPopup] = useState({ click: false, data: null })

  const [values, setvalues] = useState([])
  const applicationTypeOption = [
    { value: "Web", label: "Web" },
    { value: "Android", label: "Android" },
    { value: "Ios", label: "Ios" },
  ]
  const breadcrumbItems = [
    {
      label: "Test Lab / Test Automation",
      path: "/TestAutomation/AutomationScript",
    },
    {
      label: isCreateMode
        ? "Create Automation Script"
        : "Edit Automation Script",
      path: "/",
    },
  ]

  const validationSchema = Yup.object().shape({
    fileName: Yup.string()
      .matches(
        /^[A-Za-z0-9_]+$/,
        "File name can only contain letters, numbers, and underscores"
      )
      .required("File name is required"),
    applicationType: Yup.string().required("Application type is required"),
  })

  const [searchTerm, setSearchTerm] = useState("")

  const filteredActions = webActionDetails
    ? [...webActionDetails]
        .sort((a, b) => a.action_name.localeCompare(b.action_name))
        .filter(item =>
          item.action_name.toLowerCase().includes(searchTerm.toLowerCase())
        )
    : []

  useEffect(() => {
    const pId = `project_id=${GlobalSelectedProject.value}`
    const pIds = `?project_id=${GlobalSelectedProject.value}`
    const body = {
      project_id: GlobalSelectedProject.value,
    }
    dispatch(BuildsDetails(pIds))
    dispatch(TestCaseDetails(body))
    dispatch(GenerateAutomationScriptId(pIds))
  }, [dispatch])

  useEffect(() => {
    const buldData = []
    const testCaseList = []
    if (Array.isArray(buildDetails)) {
      buildDetails.forEach(entry => {
        buldData.push({
          value: Math.round(entry.id),
          label: entry.build_id,
        })
      })
    }

    if (Array.isArray(testCase)) {
      testCase.forEach(testCase => {
        if (testCase.test_status === "Approved") {
          testCaseList.push({
            label: `${testCase?.test_case_id} - ${testCase?.test_case_name}`,
            value: testCase?.id,
          })
        }
      })
    }

    setBuildValues(buldData)
    settestCases(testCaseList)
  }, [buildDetails, testCase])

  const stripHtmlTags = html => {
    const doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent || ""
  }

  useEffect(() => {
    if (isEditMode && selectedAutomationScriptData) {
      formik.setValues({
        projectName: selectedAutomationScriptData.project,
        build: selectedAutomationScriptData.build_id,
        automationScriptId: selectedAutomationScriptData.automation_script_id,
        fileName: selectedAutomationScriptData.automation_script_name,
        applicationType: selectedAutomationScriptData.application_type,
        description: selectedAutomationScriptData.description,
        testCase: isEditMode ? selectedAutomationScriptData?.test_case_id : "",
      })

      setDescription(selectedAutomationScriptData.description)
      setIsDescription(false)
    }
  }, [isEditMode, selectedAutomationScriptData])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      projectName: isEditMode
        ? selectedAutomationScriptData?.project
        : isCreateMode
        ? GlobalSelectedProject?.label
        : GlobalSelectedProject?.label,
      build: isEditMode ? selectedAutomationScriptData?.build_id : "",
      automationScriptId: isEditMode
        ? selectedAutomationScriptData?.automation_script_id
        : isCreateMode
        ? nextAutomationScriptId
        : nextAutomationScriptId,
      fileName: isEditMode
        ? selectedAutomationScriptData?.automation_script_name
        : "",
      applicationType: isEditMode
        ? selectedAutomationScriptData?.application_type
        : "",
      description: isEditMode ? selectedAutomationScriptData?.description : "",
      testCase: isEditMode ? selectedAutomationScriptData?.test_case_id : "",
    },
    validationSchema,
    validate: values => {
      setvalues(values)
      const errors = {}
      // if (!description) {
      //   errors.description = " Description is required"
      //   setIsDescription(true)
      // } else {
      //   setIsDescription(false)
      // }

      return errors
    },
    onSubmit: async values => {
      const cleanDescription = stripHtmlTags(description).trim()
      if (!cleanDescription) {
        setIsDescription(true)
      }

      if (cleanDescription) {
        try {
          const bodyoptions = { application_type: values.applicationType }
          const response = await dispatch(WebActionDetails(bodyoptions))
          setwebActionDetails(response)
        } catch (error) {
          console.error("Failed to submit form:", error.message)
        }
        setShowForm(false)
      }
    },
  })

  function tog_center() {
    setmodal_center(!modal_center)
  }

  const onPrevious = () => {
    setShowForm(true) // Show the form again
  }

  const handleDescriptionChange = newContent => {
    setDescription(newContent)
    if (newContent) {
      setIsDescription(false)
    } else {
      setIsDescription(true)
    }
  }

  const onCancel = () => {
    formik.handleReset()
    setIsDescription(false)
    navigate("/TestAutomation/AutomationScript")
  }

  const customStyle = {
    control: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888",
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Ensure input text is visible
    }),
  }

  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
      borderColor: layoutModeType === "dark" ? "#444" : "#ddd",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#2a3042",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888", // Keep or customize based on dark/light mode
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Ensure text is visible while typing
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150,
      backgroundColor: "white", // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  }

  const renderSelect = (
    name,
    label,
    placeholder,
    options,
    isRequired = true,
    isDisabled = false,
    customProps = {}
  ) => (
    <div className="mb-3">
      <Label className="form-label">
        {label} {isRequired && <span className="text-danger">*</span>}
      </Label>
      <Select
        name={name}
        placeholder={placeholder}
        onChange={option => formik.setFieldValue(name, option?.value || "")}
        onBlur={() => formik.setFieldTouched(name)}
        options={options}
        className={`select2-selection ${
          formik.touched[name] && formik.errors[name] ? "is-invalid" : ""
        }`}
        {...customProps}
        maxMenuHeight={200}
        value={options.find(option => option.value === formik.values[name])}
        isClearable={true}
        isDisabled={isDisabled}
      />
      {formik.touched[name] && formik.errors[name] && (
        <FormFeedback className="custom-error-message">
          {formik.errors[name]}
        </FormFeedback>
      )}
    </div>
  )

  const renderInput = (
    name,
    label,
    type = "text",
    placeholder = "",
    isRequired = true,
    disabled = false
  ) => (
    <div className="mb-3">
      <Label className="form-label">
        {label} {isRequired && <span className="text-danger">*</span>}
      </Label>
      <Input
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values[name] || ""}
        invalid={formik.touched[name] && formik.errors[name]}
        className="custom-input"
        disabled={disabled}
      />
      {formik.touched[name] && formik.errors[name] && (
        <FormFeedback className="custom-error-message">
          {formik.errors[name]}
        </FormFeedback>
      )}
    </div>
  )

  const toggleRightCanvas = () => setIsRight(!isRight)

  const handleWebActionClick = data => {
    toggleRightCanvas()
    setCopyPopup({ click: true, data: data.code_descriptions })
    setwebActionDescription(data.code_descriptions)
  }

  const handleDbIntegration = () => {
    setCopyPopup({ click: true, data: dbIntegrationData })
    setwebActionDescription(dbIntegrationData)
  }

  const OnCancelPopoup = () => {
    setmodal_center(false)
  }

  const onClickNext = () => {
    const cleanDescription = stripHtmlTags(description).trim()
    if (!cleanDescription) {
      setIsDescription(true)
    }
  }

  const onSave = async () => {
    if (isCreateMode) {
      try {
        let bodyoptions = {
          project_id: GlobalSelectedProject?.value,
          automation_script_id: values.automationScriptId,
          automation_script_name: values.fileName,
          build_id: values.build,
          file: scriptCode,
          application_type: values.applicationType,
          test_case_id: values.testCase,
          description: description,
        }
        const response = await dispatch(CreateAutomationScrit(bodyoptions))

        if (response?.status) {
          navigate("/TestAutomation/AutomationScript")
        }
      } catch (error) {
        console.error("Failed to submit form:", error.message)
      }
    } else {
      try {
        let bodyoptions = {
          id: selectedAutomationScriptData?.id,
          project_id: GlobalSelectedProject?.value,
          automation_script_id: values.automationScriptId,
          automation_script_name: values.fileName,
          build_id: values.build,
          file: scriptCode,
          application_type: values.applicationType,
          test_case_id: values.testCase,
          description: description,
        }
        const response = await dispatch(
          UpdateAutomationScript(selectedAutomationScriptData?.id, bodyoptions)
        )

        if (response?.status) {
          navigate("/TestAutomation/AutomationScript")
        }
      } catch (error) {
        console.error("Failed to submit form:", error.message)
      }
    }
  }

  const onCancelPopup = () => {
    setIsRight(false)
  }
  const [copyHandler, setCopyHandler] = useState(null)
  const [copied, setCopied] = useState(false)

  const handleCopy = async () => {
    if (copyHandler) {
      // await copyHandler();
      setCopied(true)
      setTimeout(() => setCopied(false), 2000)
    }
  }

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          <Breadcrumbs
            title={
              currentUrl.includes("CreateAutomationSuite")
                ? "Create Automation Suite"
                : "Edit Automation Suite"
            }
            breadcrumbItems={breadcrumbItems}
          />
          <div className="mt-2 mx-1 col-12 d-flex justify-content-between">
            <div>
              <h3>
                {!isEditMode
                  ? "Create Automation Script"
                  : `Edit Automation Script - ${selectedAutomationScriptData.automation_script_name}`}
              </h3>
              <p className="text-muted">
                Fill the form to {!isEditMode ? "create" : "edit"} a automation
                script
              </p>
            </div>
            <div>
              <Button
                type="button"
                color="primary"
                className="btn-label"
                onClick={() => {
                  navigate("/TestAutomation/AutomationScript")
                }}
              >
                <i className="bx bx-arrow-back label-icon"></i> Go Back
              </Button>
            </div>
          </div>
          {showForm ? (
            <Form onSubmit={formik.handleSubmit}>
              <Row>
                <Col lg={6}>
                  <Card>
                    <CardBody>
                      {renderInput(
                        "projectName",
                        "Project Name",
                        "text",
                        "Enter project name",
                        false,
                        true
                      )}
                      {renderInput(
                        "automationScriptId",
                        "Automation Script ID",
                        "text",
                        "Enter Automation Script ID",
                        false,
                        true
                      )}
                      {renderInput(
                        "fileName",
                        "File Name",
                        "text",
                        "Enter File Name",
                        true,
                        isEditMode
                      )}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6}>
                  <Card>
                    <CardBody>
                      {renderSelect(
                        "applicationType",
                        "Application Type",
                        "Select Application Type",
                        applicationTypeOption,
                        true,
                        isEditMode,
                        { styles: isEditMode ? customStyles : customStyle }
                      )}
                      {renderSelect(
                        "build",
                        "Build",
                        "Select Build",
                        buildValues,
                        false,
                        false,
                        { styles: customStyle }
                      )}
                      {renderSelect(
                        "testCase",
                        "Test Case",
                        "Select Test Case",
                        testCases,
                        false,
                        false,
                        { styles: customStyle }
                      )}
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <div>
                        <Label>
                          {" "}
                          Description <span className="text-danger">*</span>
                        </Label>
                        <JoditEditor
                          value={description}
                          config={config}
                          onBlur={handleDescriptionChange}
                        />
                        {isdescription && (
                          <div className="mt-1 font-size-10 text-danger">
                            Description is required
                          </div>
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <div className="d-flex justify-content-end flex-wrap gap-2 m-2 mb-3">
                <Button type="reset" color="secondary" onClick={onCancel}>
                  Cancel
                </Button>
                <Button type="submit" color="primary" onClick={onClickNext}>
                  Next
                </Button>
              </div>
            </Form>
          ) : (
            <div>
              <Card style={{ minHeight: "400px" }}>
                <CardBody>
                  <Row>
                    <Col
                      lg={3}
                      style={{ maxHeight: "400px", overflow: "auto" }}
                    >
                      <InputGroup
                        className="mb-2"
                        style={{
                          width: "200px",
                        }}
                      >
                        <InputGroupText>
                          <i className="bx bx-search-alt search-icon"></i>
                        </InputGroupText>
                        <Input
                          type="search"
                          placeholder="Search..."
                          aria-label="Search"
                          value={searchTerm}
                          onChange={e => setSearchTerm(e.target.value)}
                        />
                      </InputGroup>
                      {filteredActions &&
                        [...filteredActions]
                          .sort((a, b) =>
                            a.action_name.localeCompare(b.action_name)
                          )
                          .map(item => (
                            <div key={item.id}>
                              <Button
                                className="mb-2"
                                style={{ minWidth: "200px" }}
                                color="primary"
                                onClick={() => handleWebActionClick(item)}
                                outline
                              >
                                {item.action_name}
                              </Button>
                            </div>
                          ))}
                    </Col>
                    <Col lg={9} className="mb-2" style={{ minHeight: "400px" }}>
                      <Editor
                        editmode={editModeStatus}
                        data={editModeDataValues}
                      />
                    </Col>
                  </Row>
                  <div className="d-flex justify-content-end gap-2">
                    <Button type="button" color="secondary" onClick={onCancel}>
                      Cancel
                    </Button>
                    <Button
                      type="button"
                      color="secondary"
                      onClick={onPrevious}
                    >
                      Previous
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      onClick={onSave}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Submit"}
                    </Button>
                  </div>
                </CardBody>
              </Card>
            </div>
          )}
        </Container>
        <Modal
          isOpen={modal_center}
          toggle={() => {
            tog_center()
          }}
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0">DB INTEGRATION</h5>
            <button
              type="button"
              onClick={() => {
                setmodal_center(false)
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <ManageDbIntegration
              setCopyPopup={setCopyPopup}
              setDbPopup={handleDbIntegration}
              onClose={OnCancelPopoup}
            />
          </div>
        </Modal>

        {/* {copyPopUp && copyPopUp?.click ? (
          <>
            <div className={styles.BlurOverlay}></div>
            <div className={styles.CopyPopUp}>
              <CopyPopUp
                setCopyPopup={setCopyPopup}
                codeData={webActionDescription}
              />
            </div>
          </>
        ) : null} */}
      </div>

      <Offcanvas isOpen={isRight} direction="end" toggle={toggleRightCanvas}>
        <OffcanvasHeader toggle={toggleRightCanvas}>
          Select Code
        </OffcanvasHeader>
        <OffcanvasBody>
          <CopyPopUp
            setCopyPopup={setCopyPopup}
            codeData={webActionDescription}
            onCopy={setCopyHandler} // Set the copy handler from CopyPopUp
          />
          <div
            className="d-flex justify-content-between"
            style={{ marginTop: "-15px" }}
          >
            <button
              type="button"
              className="btn btn-secondary"
              onClick={onCancelPopup}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleCopy}
              disabled={copied} // Disable button while showing "Copied"
            >
              {copied ? "Copied" : "Copy"}{" "}
              {/* Change text based on copied state */}
            </button>
          </div>
        </OffcanvasBody>
      </Offcanvas>
    </React.Fragment>
  )
}

export default CreateAutomationScript
