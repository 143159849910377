import React, { useEffect, useState, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import DeleteModal from "components/Common/DeleteModal"
import { useSelector, useDispatch } from "react-redux"
import { ToastContainer } from "react-toastify"
import TableContainer from "components/Common/helper/TableContainer"
import {
  deletePreCondition,
  fetchAllPreConditon,
  GetPreConditionById,
} from "store/actions/TestCaseAction"
import moment from "moment"
import { setEditPreCondition } from "store/Slices/TestCaseSlice"

const PreConditon = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))
  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [totalCount, setTotalCount] = useState(null)
  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )
  const [globalFilter, setGlobalFilter] = useState("")
  const stripHtmlTags = html => {
    const doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent || ""
  }

  const [data, setData] = useState([])

  const allTestScenario = useSelector(
    state => state?.TestScenario?.allTestScenario
  )

  useEffect(() => {
    if (allTestScenario && allTestScenario.Data) {
      setData(allTestScenario)
    }
  }, [allTestScenario])

  const handleSingleEditClick = async id => {
    try {
      const res = await dispatch(GetPreConditionById(id))
      dispatch(setEditPreCondition({ isEdit: true, data: res }))
      navigate("/TestManagement/CreateEditPreCondition", {
        state: { isEdit: true, data: res },
      })
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  const handleCreateClick = () => {
    navigate("/TestManagement/CreateEditPreCondition")
  }

  const [deleteModal, setDeleteModal] = useState(false)
  const [isDeleteId, setIsDeleteId] = useState("")

  const onClickDelete = testscenario => {
    setIsDeleteId(testscenario?.id)
    setDeleteModal(true)
  }

  const columns = useMemo(
    () => [
      {
        header: "Name",
        accessorKey: "condition_name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const condition_name = cell.getValue()
          return (
            <>
              <p
                className="font-size-14 mb-1"
                style={{
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  whiteSpace: "normal",
                  maxWidth: "300px", // Set the max-width to control the wrapping
                }}
              >
                {condition_name ? condition_name : "-"}
              </p>
            </>
          )
        },
      },
      {
        header: "Description",
        accessorKey: "pre_condition_details",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const pre_condition_details = cell.getValue()
          return (
            <>
              <p
                className="font-size-14 mb-1"
                style={{
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  whiteSpace: "normal",
                  maxWidth: "300px", // Set the max-width to control the wrapping
                }}
              >
                {stripHtmlTags(pre_condition_details)
                  ? stripHtmlTags(pre_condition_details)
                  : "-"}
              </p>
            </>
          )
        },
      },
      {
        header: "Created At",
        accessorKey: "created_at",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return <>{moment(cell.getValue()).format("DD-MM-YYYY")}</>
        },
      },
      {
        header: "Action",
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li>
                <span
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={() => {
                    const data = cellProps.row.original
                    handleSingleEditClick(data?.id)
                  }}
                  id={`edittooltip-${cellProps.row.original.test_scenario_id}`}
                >
                  <i className="mdi mdi-pencil-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`edittooltip-${cellProps.row.original.test_scenario_id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </span>
              </li>

              <li>
                <span
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const userData = cellProps.row.original
                    onClickDelete(userData)
                  }}
                  id={`deletetooltip-${cellProps.row.original.test_scenario_id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`deletetooltip-${cellProps.row.original.test_scenario_id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </span>
              </li>
            </ul>
          )
        },
      },
    ],
    [data]
  )

  const fetchTableData = async (page, size, search) => {
    let payload = {
      project_id: globalProject?.value || selectedProject?.value,
      page: page + 1,
      page_size: size,
      search: search,
    }
    try {
      const data = await dispatch(fetchAllPreConditon(payload))
      setData(data?.Data)
      setTotalCount(data?.TotalCount)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  useEffect(() => {
    fetchTableData(pageIndex, pageSize, globalFilter)
  }, [globalProject?.value, pageIndex, pageSize, globalFilter])

  const handleDelete = async () => {
    try {
      await dispatch(deletePreCondition(isDeleteId))
      setDeleteModal(false)
      await fetchTableData(pageIndex, pageSize, globalFilter)
      setIsDeleteId("")
    } catch (error) {
      console.error("Error deleting:", error)
    }
  }

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
        message="Pre Condition"
      />
      <Container fluid>
        <Row>
          <Col lg="12">
            <Card
              style={{
                borderTopLeftRadius: "0px",
                borderTopRightRadius: "0px",
              }}
            >
              <CardBody className="pt-2">
                <div className="table-container">
                  <TableContainer
                    columns={columns}
                    data={data}
                    pageIndex={pageIndex}
                    pageSize={pageSize}
                    totalCount={totalCount}
                    setPageIndex={setPageIndex}
                    setPageSize={setPageSize}
                    isGlobalFilter={true}
                    isPagination={true}
                    isCustomPageSize={true}
                    isAddButton={true}
                    isAddDelete={false}
                    isAddEdit={false}
                    isAddFilter={false}
                    pagination="pagination"
                    SearchPlaceholder="Search..."
                    handleAddClick={handleCreateClick}
                    setGlobalFilter={setGlobalFilter}
                    tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                    theadClass="table-light"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      <ToastContainer />
    </React.Fragment>
  )
}

export default withRouter(PreConditon)
