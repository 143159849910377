import React, { useState } from "react"
import {
  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Table,
  Button,
  UncontrolledTooltip,
} from "reactstrap"
import { RiDeleteBinLine } from "react-icons/ri"
import { FiEdit2 } from "react-icons/fi"
import DeleteModal from "components/Common/DeleteModal"
import NoData from "components/Common/NoData"
import { GetTagsByIdAPI } from "store/actions/TagsAction"
import { setEditTag } from "store/Slices/TagsSlice"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"

const TableContainer = ({
  data,
  handleDelete,
  setIsDeleteId,
  deleteModal,
  setDeleteModal,
}) => {
  const [openItem, setOpenItem] = useState(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const Permission = JSON.parse(localStorage.getItem("authUser"))
  const tagPermission = Permission?.permissions[0]?.menu_item?.Settings?.meta
  const handleToggle = itemId => {
    setOpenItem(openItem === itemId ? null : itemId)
  }

  const handleEditButtonClick = async row => {
    if (tagPermission?.delete) {
      try {
        const res = await dispatch(GetTagsByIdAPI(row?.id))
        dispatch(setEditTag({ isEdit: true, data: res }))
        navigate("/Settings/EditTag", {
          state: { isEdit: true, data: res },
        })
      } catch (error) {
        console.error("Error fetching data", error)
      }
    } else {
      toast.warn("You don't have permission to Edit Tag", {
        position: "top-right",
        autoClose: 3000,
      })
    }
  }

  const handleDeleteButtonClick = item => {
    if (tagPermission?.delete) {
      setIsDeleteId(item?.id)
      setDeleteModal(true)
    } else {
      toast.warn("You don't have permission to Delete Tag", {
        position: "top-right",
        autoClose: 3000,
      })
    }
  }

  const toCamelCase = str =>
    str
      .toLowerCase()
      .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
        index === 0 ? word.toLowerCase() : word.toUpperCase()
      )
      .replace(/\s+/g, "")

  const groupedData =
    Array.isArray(data) &&
    data.reduce((acc, item) => {
      // Convert the meta_key to camelCase
      const camelCaseKey = toCamelCase(item.meta_key)

      // Initialize the nested structure if it doesn't exist
      if (!acc[camelCaseKey]) {
        acc[camelCaseKey] = {}
      }
      if (!acc[camelCaseKey][item.category]) {
        acc[camelCaseKey][item.category] = []
      }

      // Push the current item into the correct category array
      acc[camelCaseKey][item.category].push(item)

      return acc
    }, {})

  const columnStyles = {
    description: { width: "30%" },
    projects: { width: "50%" },
    action: { width: "20%" },
  }
  return (
    <>
      {data && data?.length > 0 ? (
        <>
          {" "}
          <UncontrolledAccordion defaultOpen="1">
            {Object.keys(groupedData).map((metaKey, index) => (
              <AccordionItem key={metaKey} style={{ marginBottom: "0.5rem" }}>
                <AccordionHeader
                  targetId={String(index + 1)}
                  onClick={() => handleToggle(String(index + 1))}
                  className="d-flex justify-content-between align-items-center"
                  style={{
                    cursor: "pointer",
                    borderBottom: "1px solid #ddd",
                    fontSize: "14px",
                  }}
                >
                  <strong>
                    {metaKey
                      .replace(/([A-Z])/g, " $1")
                      .replace(/^./, str => str.toUpperCase())}
                  </strong>
                </AccordionHeader>
                <AccordionBody
                  accordionId={String(index + 1)}
                  style={{ padding: "0.5rem" }}
                >
                  {/* Single Table Header for Each Accordion Item */}
                  <Table
                    bordered
                    responsive
                    size="lg"
                    className="table-hover"
                    style={{ marginBottom: "1rem" }}
                  >
                    <thead>
                      <tr>
                        <th style={columnStyles.description}>Description</th>
                        <th style={columnStyles.projects}>Projects</th>
                        <th style={columnStyles.action}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Object.keys(groupedData[metaKey]).map(category => (
                        <React.Fragment key={category}>
                          <tr>
                            <td colSpan="3">
                              <h6
                                className="text-success"
                                style={{
                                  fontSize: "15px",
                                  marginBottom: "0.5rem",
                                  borderBottom: "none", // Remove any border at the bottom
                                  paddingBottom: "0", // Remove padding at the bottom
                                }}
                              >
                                {category}
                              </h6>
                            </td>
                          </tr>
                          {groupedData[metaKey][category].map(row => (
                            <tr key={row.id}>
                              <td
                                style={{
                                  fontSize: "14px",
                                  ...columnStyles.description,
                                }}
                              >
                                {row?.meta_value ? row?.meta_value : "-"}
                              </td>
                              <td style={columnStyles.projects}>
                                {Array.isArray(row?.project_names) ? (
                                  row.project_names.length > 0 ? (
                                    row.project_names.map((name, index) => (
                                      <Button
                                        key={index}
                                        color="secondary"
                                        size="sm"
                                        className="me-1 mb-1"
                                        style={{
                                          display: "inline-block",
                                          fontSize: "12px",
                                        }}
                                      >
                                        {name}
                                      </Button>
                                    ))
                                  ) : (
                                    "-"
                                  )
                                ) : row?.project_names ? (
                                  <Button
                                    color="secondary"
                                    size="sm"
                                    style={{ fontSize: "12px" }}
                                  >
                                    {row.project_names}
                                  </Button>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td style={columnStyles.action}>
                                <ul className="list-unstyled hstack gap-1 mb-0">
                                  <li
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span
                                      className="btn btn-sm btn-soft-warning"
                                      onClick={() => handleEditButtonClick(row)}
                                      id={`edittip-${row.id}`}
                                    >
                                      <FiEdit2 size={14} />
                                    </span>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={`edittip-${row.id}`}
                                    >
                                      Edit Tag
                                    </UncontrolledTooltip>
                                  </li>

                                  <li
                                    data-bs-toggle="tooltip"
                                    data-bs-placement="top"
                                  >
                                    <span
                                      className="btn btn-sm btn-soft-danger"
                                      onClick={() =>
                                        handleDeleteButtonClick(row)
                                      }
                                      id={`deletetip-${row.id}`}
                                    >
                                      <RiDeleteBinLine size={16} />
                                    </span>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={`deletetip-${row.id}`}
                                    >
                                      Delete Tag
                                    </UncontrolledTooltip>
                                  </li>
                                </ul>
                              </td>
                            </tr>
                          ))}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </Table>
                </AccordionBody>
              </AccordionItem>
            ))}
          </UncontrolledAccordion>
        </>
      ) : (
        <NoData />
      )}

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDelete}
        onCloseClick={() => setDeleteModal(false)}
        message="Tag"
      />
    </>
  )
}

export default TableContainer
