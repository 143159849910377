import React, { useEffect, useState, useMemo } from "react"
import { Link, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Progress,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import DeleteModal from "components/Common/DeleteModal"
import { useSelector, useDispatch } from "react-redux"
import { toast, ToastContainer } from "react-toastify"
import BulkDeleteModal from "components/Common/BulkDeleteModal"
import moment from "moment"
import TableContainer from "./TestCycleContainer/TableContainer"
import {
  BulkDeleteTestCycleAPI,
  CreateTestCycle,
  deleteTestCycleAPI,
  fetchTestCycleDetailsAPI,
  GetTestCycleByIdAPI,
} from "store/actions/TestCycleActions"
import { getStatusBackGroundColor } from "helpers/helper"
import {
  setCreateEditTestCycle,
  setViewTestCycle,
} from "store/Slices/TestCycleSlice"
import {
  getAllIntegrationListAPI,
  getAllMapProject,
} from "store/actions/IntegrationAction"
import NoPermission from "components/Common/NoPermission"

const TestCyclePage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))
  const Permission = JSON.parse(localStorage.getItem("authUser"))
  const cyclePermission = Permission?.permissions[0]?.menu_item?.TestLab?.cycle

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const [data, setData] = useState([])

  const handleSingleEditClick = async id => {
    if (cyclePermission?.edit) {
      try {
        const res = await dispatch(GetTestCycleByIdAPI(id))
        dispatch(setCreateEditTestCycle({ isEdit: true, data: res }))
        navigate("/TestCycle/CreateEditTestCycle", {
          state: { isEdit: true, data: res },
        })
      } catch (error) {
        console.error("Error fetching data", error)
      }
    } else {
      toast.warn("You don't have permission to edit cycle", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      })
    }
  }

  const handleViewTestScenario = async data => {
    if (cyclePermission?.view) {
      const res = await dispatch(GetTestCycleByIdAPI(data?.id))
      localStorage.setItem("cycleId", res?.id)
      localStorage.setItem("TestCycleName", res?.test_cycle_id)
      dispatch(setViewTestCycle({ viewCycle: true, data: res }))
      if (data?.cycle_type === "Manual") {
        navigate("/TestCycle/ViewTestCycleManual")
      } else {
        navigate("/TestCycle/ViewTestCycleAutomation")
      }
    } else {
      toast.warn("You don't have permission to view cycle", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      })
    }
  }

  const handleCopyCycle = async data => {
    if (cyclePermission?.create) {
      let bodyoption = {
        project_id: globalProject?.value || selectedProject?.value,
      }
      const res = await dispatch(GetTestCycleByIdAPI(data?.id))
      const response = await dispatch(fetchTestCycleDetailsAPI(bodyoption))
      let payload = processPayload(res, response)
      try {
        await dispatch(CreateTestCycle(payload))
      } catch (error) {
        toast.error(
          error?.message || "An error occurred while copying Test Cycle",
          {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          }
        )
      }
      await fetchTableData(pageIndex, pageSize, globalFilter)
    } else {
      toast.warn("You don't have permission to copy cycle", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      })
    }
  }

  const processPayload = (res, response) => {
    function incrementCopyName(cycle_name) {
      const regex = /-Copy(\d+)$/
      let maxCopyNumber = 0

      response.forEach(item => {
        if (item.cycle_name.startsWith(cycle_name.split("-Copy")[0])) {
          const match = item.cycle_name.match(regex)
          if (match) {
            const currentNumber = parseInt(match[1], 10)
            maxCopyNumber = Math.max(maxCopyNumber, currentNumber)
          }
        }
      })

      // If maxCopyNumber is still 0, it means this is the first copy, so start with 1
      return `${cycle_name.split("-Copy")[0]}-Copy${maxCopyNumber + 1}`
    }

    let updatedCycleName = incrementCopyName(res.cycle_name)

    const filteredRes = {
      project_id: res.project_id,
      build_id: res.build_id,
      cycle_status: res.cycle_status,
      description: res.description,
      testcycle_type: res.testcycle_type,
      start_date: res.start_date,
      end_date: res.end_date,
      cycle_type: res.cycle_type,
      assign_to_id:
        res.assign_to_id?.length !== 0
          ? res.assign_to_id?.map(item => item.id)
          : null,
      test_case_id:
        res?.test_case_id?.length !== 0
          ? res?.test_case_id
              .filter(
                id => id?.id !== null && id?.id !== undefined && id?.id !== ""
              )
              .map(id => parseInt(id?.id))
          : null,
      automation_suite_id:
        res?.automation_suite_id?.length !== 0
          ? res?.automation_suite_id
              .filter(
                id => id?.id !== null && id?.id !== undefined && id?.id !== ""
              )
              .map(id => parseInt(id?.id))
          : null,
      browser_stack_local: res.browser_stack_local,
      execution_agent: res.execution_agent,
      app_browser: res.app_browser,
      device_name: res.device_name,
      version: res.version,
      cycle_type: res.cycle_type,
      browser_applications: res.browser_applications_id,
    }

    // Add the modified cycle_name to the filteredRes object
    filteredRes.cycle_name = updatedCycleName

    // Return the filtered object
    return filteredRes
  }

  const handleCreateClick = id => {
    if (cyclePermission?.create) {
      navigate("/TestCycle/CreateEditTestCycle")
    } else {
      toast.warn("You don't have permission to create cycle", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      })
    }
  }

  const [deleteModal, setDeleteModal] = useState(false)
  const [bulkDeleteModal, setBulkDeleteModal] = useState(false)
  const [isDeleteId, setIsDeleteId] = useState("")

  const onClickDelete = testscenario => {
    if (cyclePermission?.delete) {
      setIsDeleteId(testscenario?.id)
      setDeleteModal(true)
    } else {
      toast.warn("You don't have permission to delete cycle", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      })
    }
  }

  const [selectedRows, setSelectedRows] = useState([])
  const handleCheckboxChange = test_cycle_id => {
    setSelectedRows(prevValues =>
      prevValues.includes(test_cycle_id)
        ? prevValues.filter(val => val !== test_cycle_id)
        : [...prevValues, test_cycle_id]
    )
  }

  const handleSelectAll = e => {
    const isChecked = e.target.checked
    if (isChecked) {
      const newSelectedRows = data.map(item => item?.test_cycle_id)
      setSelectedRows(newSelectedRows)
    } else {
      setSelectedRows([])
    }
  }
  const columns = useMemo(
    () => [
      {
        header: (
          <input
            type="checkbox"
            onChange={handleSelectAll}
            style={{ cursor: "pointer" }}
            title="Select All"
            checked={
              Array.isArray(selectedRows) &&
              selectedRows.length === data?.length &&
              data?.length > 0
            }
          />
        ),
        accessorKey: "checkbox",
        cell: cellProps => {
          const jobData = cellProps.row.original?.test_cycle_id

          const isChecked = () => {
            if (!Array.isArray(selectedRows)) {
              console.error("selectedRows is not an array")
              return false
            }
            return selectedRows.includes(jobData)
          }

          const handleCheckboxClick = () => {
            try {
              if (!Array.isArray(selectedRows)) {
                throw new Error("selectedRows is not an array")
              }
              handleCheckboxChange(jobData)
            } catch (error) {
              console.error(error.message)
            }
          }

          return (
            <input
              type="checkbox"
              style={{ cursor: "pointer" }}
              checked={isChecked()}
              onChange={handleCheckboxClick}
              onClick={handleCheckboxClick}
            />
          )
        },
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Cycle Id",
        accessorKey: "test_cycle_id",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cellProps => {
          const test_cycle_id = cellProps.row.original.test_cycle_id
          return (
            <div
              className="text-decoration-underline fw-bold"
              style={{ cursor: "pointer" }}
              onClick={() => {
                const TestScenarioData = cellProps.row.original
                handleViewTestScenario(TestScenarioData)
              }}
            >
              {test_cycle_id ? test_cycle_id : "-"}
            </div>
          )
        },
      },
      {
        header: "Cycle Name",
        accessorKey: "cycle_name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const cycle_name = cell.getValue()
          return (
            <>
              <p
                className="font-size-14 mb-1"
                style={{
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  whiteSpace: "normal",
                  maxWidth: "350px",
                  minWidth: "200px",
                }}
              >
                {cycle_name ? cycle_name : "-"}
              </p>
            </>
          )
        },
      },
      {
        header: "Cycle Type",
        accessorKey: "cycle_type",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const cycle_type = cell.getValue()
          return <>{cycle_type ? cycle_type : "-"}</>
        },
      },
      {
        header: "Build",
        accessorKey: "build",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const build = cell.getValue()
          //   return <>{moment(cell.getValue()).format("DD-MM-YYYY")}</>
          return <>{build ? build : "-"}</>
        },
      },
      {
        header: "Start Date",
        accessorKey: "start_date",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return (
            <>
              {cell.getValue()
                ? moment(cell.getValue()).format("DD-MM-YYYY")
                : "-"}
            </>
          )
        },
      },
      {
        header: "End Date",
        accessorKey: "end_date",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return (
            <>
              {cell.getValue()
                ? moment(cell.getValue()).format("DD-MM-YYYY")
                : "-"}
            </>
          )
        },
      },
      {
        header: "Result",
        accessorKey: "execution_status.results",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const result = cell.getValue()
          const total = result?.PASSED + result?.FAILED + result?.NOTRUN
          return (
            <>
              {total !== 0 ? (
                <Col
                  className="pt-2 mb-0"
                  style={{
                    borderTopLeftRadius: "0px",
                    borderTopRightRadius: "0px",
                    width: "9rem",
                  }}
                >
                  <Progress multi style={{ height: "0.8rem" }}>
                    <Progress
                      bar
                      color="success"
                      value={(result?.PASSED / total) * 100}
                    >
                      {result?.PASSED}
                    </Progress>
                    <Progress
                      bar
                      color="danger"
                      value={(result?.FAILED / total) * 100}
                    >
                      {result?.FAILED}
                    </Progress>
                    <Progress
                      bar
                      color="warning"
                      value={(result?.NOTRUN / total) * 100}
                    >
                      {result?.NOTRUN}
                    </Progress>
                  </Progress>
                </Col>
              ) : (
                "-"
              )}
            </>
          )
        },
      },
      {
        header: "Application Type",
        accessorKey: "testcycle_type",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const testcycle_type = cell.getValue()
          return <>{testcycle_type ? testcycle_type : "-"}</>
        },
      },
      {
        header: "Assign To",
        accessorKey: "assign_to_id",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const assignTo = cell.getValue() // assuming this is an array of user objects
          const avatarGroupStyle = {
            display: "flex",
            flexWrap: "wrap", // Allows items to wrap to the next line
            maxWidth: "300px",
            minWidth: "140px", // Adjust the max width as needed
          }

          // CSS for limiting avatars per row
          const avatarItemStyle = {}

          return (
            <div style={avatarGroupStyle}>
              {assignTo && assignTo.length > 0 ? (
                assignTo.map(user => (
                  <React.Fragment key={user.id}>
                    <div className="" style={avatarItemStyle}>
                      <Link to="#" className="" id={"member" + user.id}>
                        <div className="me-1 mb-1">
                          <span
                            className="avatar-title rounded-circle bg-primary text-white text-align-center"
                            style={{
                              width: "20px",
                              height: "20px",
                              fontSize: "14px",
                            }}
                          >
                            {user.user_name
                              ? user.user_name.charAt(0).toUpperCase()
                              : "-"}
                          </span>
                          <UncontrolledTooltip
                            placement="top"
                            target={"member" + user.id}
                            style={{ zIndex: 9999 }}
                          >
                            {user.user_name}
                          </UncontrolledTooltip>
                        </div>
                      </Link>
                    </div>
                  </React.Fragment>
                ))
              ) : (
                <span>-</span> // Display "-" if assignTo is empty or undefined
              )}
            </div>
          )
        },
      },
      {
        header: "Status",
        accessorKey: "cycle_status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const cycle_status = cell.getValue()
          return (
            <>
              <Badge
                className={
                  "badge-soft-" + `${getStatusBackGroundColor(cycle_status)}`
                }
                style={{
                  fontSize: "11px", // Increase font size
                  padding: "0.5em", // Increase padding
                  borderRadius: "0.5em", // Adjust border radius
                }}
              >
                {cycle_status}
              </Badge>
            </>
          )
        },
      },
      {
        header: "Action",
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top">
                <span
                  // to="/"
                  className="btn btn-sm btn-soft-warning"
                  onClick={() => {
                    const TestScenarioData = cellProps.row.original
                    handleCopyCycle(TestScenarioData)
                  }}
                  id={`copytooltip-${cellProps.row.original?.id}`}
                >
                  <i className="mdi mdi-content-copy" />
                </span>
              </li>
              <UncontrolledTooltip
                placement="top"
                target={`copytooltip-${cellProps.row.original?.id}`}
              >
                Copy
              </UncontrolledTooltip>

              <li data-bs-toggle="tooltip" data-bs-placement="top">
                <span
                  // to="/"
                  className="btn btn-sm btn-soft-primary"
                  onClick={() => {
                    const TestScenarioData = cellProps.row.original
                    handleViewTestScenario(TestScenarioData)
                  }}
                  id={`viewtooltip-${cellProps.row.original?.id}`}
                >
                  <i className="mdi mdi-eye-outline" />
                </span>
              </li>
              <UncontrolledTooltip
                placement="top"
                target={`viewtooltip-${cellProps.row.original?.id}`}
              >
                View
              </UncontrolledTooltip>

              <li>
                <span
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={() => {
                    const data = cellProps.row.original
                    handleSingleEditClick(data?.id)
                  }}
                  id={`edittooltip-${cellProps.row.original?.id}`}
                >
                  <i className="mdi mdi-pencil-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`edittooltip-${cellProps.row.original?.id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </span>
              </li>

              <li>
                <span
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const userData = cellProps.row.original
                    onClickDelete(userData)
                  }}
                  id={`deletetooltip-${cellProps.row.original?.id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`deletetooltip-${cellProps.row.original?.id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </span>
              </li>
            </ul>
          )
        },
      },
    ],
    [selectedRows, data]
  )

  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [globalFilter, setGlobalFilter] = useState("")
  const [totalCount, setTotalCount] = useState(null)

  const fetchTableData = async (page, size, search) => {
    let bodyoption = {
      page: page + 1,
      page_size: size,
      search: search,
      project_id: globalProject?.value || selectedProject?.value,
    }
    let payload = { project_id: null }
    try {
      const data = await dispatch(fetchTestCycleDetailsAPI(bodyoption))
      await dispatch(getAllMapProject(payload))
      await dispatch(getAllIntegrationListAPI(payload))
      setData(data.Data)
      setTotalCount(data.TotalCount)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  useEffect(() => {
    fetchTableData(pageIndex, pageSize, globalFilter)
  }, [pageIndex, pageSize, globalFilter, globalProject?.value])

  const handleDelete = async () => {
    try {
      await dispatch(deleteTestCycleAPI(isDeleteId))
      setDeleteModal(false)
      await fetchTableData(pageIndex, pageSize, globalFilter)
      setIsDeleteId("")
    } catch (error) {
      console.error("Error deleting test cycle:", error)
    }
  }

  const handleDeleteClick = async () => {
    if (cyclePermission?.delete) {
      try {
        if (selectedRows?.length > 0) {
          setBulkDeleteModal(true)
        } else {
          toast.warn(
            "Please select a test cycle before attempting to bulk delete.",
            {
              position: "top-right",
              id: "selectBeforeDelete",
              autoClose: 3000,
            }
          )
        }
      } catch (error) {
        console.error("Error deleting test cycle:", error)
      }
    } else {
      toast.warn("You don't have permission to delete cycle", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      })
    }
  }

  const handleBulkDelete = async () => {
    const bodyoption = {
      project_id: globalProject?.value || selectedProject?.value,
      test_cycle_ids: selectedRows,
    }
    try {
      await dispatch(BulkDeleteTestCycleAPI(bodyoption))
      setSelectedRows([])
      setBulkDeleteModal(false)
      await fetchTableData(pageIndex, pageSize, globalFilter)
    } catch (error) {
      console.error("Error deleting test data:", error)
    }
  }

  return (
    <React.Fragment>
      {cyclePermission?.view ? (
        <>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDelete}
            onCloseClick={() => setDeleteModal(false)}
            message="Test Cycle"
          />
          <BulkDeleteModal
            show={bulkDeleteModal}
            onDeleteClick={handleBulkDelete}
            onCloseClick={() => {
              setBulkDeleteModal(false)
              setSelectedRows([])
            }}
            message="Test Cycles"
          />

          <Container fluid>
            <Row>
              <Col lg="12">
                <Card
                  style={{
                    borderTopLeftRadius: "0px",
                    borderTopRightRadius: "0px",
                  }}
                >
                  <CardBody className="pt-2">
                    <div className="table-container">
                      <TableContainer
                        columns={columns}
                        data={data}
                        pageIndex={pageIndex}
                        pageSize={pageSize}
                        totalCount={totalCount}
                        setPageIndex={setPageIndex}
                        setPageSize={setPageSize}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        isGlobalFilter={true}
                        isPagination={true}
                        SearchPlaceholder="Search..."
                        isCustomPageSize={true}
                        isAddButton={true}
                        isAddDelete={true}
                        isAddEdit={false}
                        isAddFilter={false}
                        handleAddClick={handleCreateClick}
                        handleDeleteClick={handleDeleteClick}
                        buttonClass="btn btn-success btn-rounded waves-effect waves-light addContact-modal ms-2 mb-2"
                        buttonName="Create"
                        tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                        theadClass="table-light"
                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                        pagination="pagination"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>

          <ToastContainer />
        </>
      ) : (
        <NoPermission />
      )}
    </React.Fragment>
  )
}

export default withRouter(TestCyclePage)
