import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import Breadcrumbs from "../../../../../components/Common/Breadcrumb"
import "flatpickr/dist/themes/material_blue.css"
import JoditEditor from "jodit-react"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import { joditConfig } from "helpers/helper"
import { useLocation, useNavigate } from "react-router-dom"
import { BuildsDetails } from "store/ProjectManagement/actions"
import { fetchTestDataList } from "store/actions/TestDataActions"
import {
  CreateAutomationSuites,
  GenerateAutomationSuiteId,
  UpdateAutomationSuite,
} from "store/actions/AutomationSuiteAction"
import { AutomationScriptDetails } from "store/actions/AutomationScriptAction"

const CreateAutomationSuite = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const location = useLocation()
  const { data } = location.state || {}
  let config = joditConfig()
  const currentUrl = window.location.href
  const parts = currentUrl.split("/")
  const GlobalSelectedProject = JSON.parse(
    localStorage.getItem("selectedProject")
  )
  const [description, setDescription] = useState("")
  const [buildValues, setBuildValues] = useState([])
  const [testDatas, settestDatas] = useState([])
  const buildDetails = useSelector(state => state.Project?.buildDetails)
  const testData = useSelector(state => state.TestData?.TestData)
  const nextAutomationSuiteId = useSelector(
    state => state.ActionSuite?.NextAutomationSuiteId?.next_automation_suite_id
  )
  const selectedAutomationSuiteData =
    data || useSelector(state => state?.ActionSuite?.editAutomationSuitData)
  const automationScriptData = useSelector(
    state => state.AutomationScript?.allActionScriptList
  )
  const [isdescription, setIsDescription] = useState(false)
  const layoutModeType = useSelector(state => state.Layout.layoutModeType)
  const [isautomationScriptIds, setIsautomationScriptIds] = useState(false)
  const [automationScriptIds, setAutomationScriptIds] = useState([
    { id: 1, value: "" },
  ])
  const [automationScriptOptions, setAutomationScriptOptions] = useState([])
  console.log(automationScriptOptions)
  const [selAutomationScriptId, setselAutomationScriptId] = useState([])
  const isCreateMode = currentUrl.includes("CreateAutomationSuite")
  const isEditMode = currentUrl.includes("EditAutomationSuite")
  const loading = useSelector(state => state?.Loading?.isLoading)

  const applicationTypeOption = [
    { value: "Web", label: "Web" },
    { value: "Android", label: "Android" },
    { value: "Ios", label: "Ios" },
  ]
  const breadcrumbItems = [
    {
      label: "Test Lab / Test Automation",
      path: "/TestAutomation/AutomationSuite",
    },
    {
      label: isCreateMode ? "Create Automation Suite" : "Edit Automation Suite",
      path: "/",
    },
  ]

  const validationSchema = Yup.object().shape({
    automationSuiteName: Yup.string()
      .matches(
        /^[A-Za-z0-9_]+$/,
        "Automation suite name can only contain letters, numbers, and underscores"
      )
      .required("Automation suite name is required"),
    applicationType: Yup.string().required("Application type is required"),
  })

  useEffect(() => {
    const pIds = `?project_id=${GlobalSelectedProject.value}`
    const body = {
      project_id: GlobalSelectedProject.value,
    }
    dispatch(BuildsDetails(pIds))
    dispatch(fetchTestDataList(pIds))
    dispatch(GenerateAutomationSuiteId(pIds))
    dispatch(AutomationScriptDetails(body))
  }, [dispatch])

  useEffect(() => {
    const buldData = []
    const testDataList = []
    if (Array.isArray(buildDetails)) {
      buildDetails.forEach(entry => {
        buldData.push({
          value: Math.round(entry.id),
          label: entry.build_id,
        })
      })
    }

    if (Array.isArray(testData)) {
      testData.forEach(entry => {
        testDataList.push({
          value: entry?.id,
          label: entry?.file_name,
        })
      })
    }

    setBuildValues(buldData)
    settestDatas(testDataList)
  }, [buildDetails, testData])

  useEffect(() => {
    if (isEditMode && selectedAutomationSuiteData) {
      formik.setValues({
        projectName: selectedAutomationSuiteData.project_name,
        build: selectedAutomationSuiteData.build_id,
        automationSuiteId: selectedAutomationSuiteData.automation_suite_id,
        automationSuiteName: selectedAutomationSuiteData.automation_suite_name,
        applicationType: selectedAutomationSuiteData.automation_suit_type,
        description: selectedAutomationSuiteData.description,
        testData: isEditMode ? selectedAutomationSuiteData?.test_data_id : "",
      })

      setDescription(selectedAutomationSuiteData.description)
      setIsDescription(false)

      const selectedOption = selectedAutomationSuiteData?.automation_suit_type

      if (selectedOption && Array.isArray(automationScriptData)) {
        const filteredScripts = automationScriptData.filter(
          script => script.application_type === selectedOption
        )
        const scriptOptions = filteredScripts.map(script => ({
          value: script.id,
          label: `${script.automation_script_id} - ${script.automation_script_name}`,
        }))
        setAutomationScriptOptions(scriptOptions)
      } else {
        setAutomationScriptOptions([])
      }

      // Transform and sort selectedScripts according to test_sequence
      let selectedScripts
      if (selectedAutomationSuiteData?.automation_script_id) {
        selectedScripts =
          selectedAutomationSuiteData.automation_script_id.map(script => ({
            id: script.id,
            value: script.id,
            label: `${script.automation_script_id} - ${script.automation_script_name}`,
          })) || []
      } else {
        selectedScripts = []
      }

      // Parse and sort test_sequence data
      const formattedTestSequence = selectedAutomationSuiteData?.test_sequence
        ?.replace(/'/g, '"')
        .replace(/([0-9]+):/g, '"$1":')

      try {
        const testSequenceObj = JSON.parse(formattedTestSequence)

        // Convert to array and sort by order value
        const sortedIds = Object.entries(testSequenceObj)
          .sort((a, b) => a[1] - b[1])
          ?.map(([id]) => Number(id))

        // Reorder selectedScripts based on sortedIds
        const orderedScripts = sortedIds
          ?.map(id => selectedScripts.find(script => script.id === id))
          .filter(script => script !== undefined) // Ensure no undefined entries

        setselAutomationScriptId(orderedScripts)
      } catch (error) {
        console.error("Error parsing test_sequence:", error)
      }
    }
  }, [isEditMode, selectedAutomationSuiteData, automationScriptData, data])
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      projectName: isEditMode
        ? selectedAutomationSuiteData?.project_name
        : isCreateMode
        ? GlobalSelectedProject?.label
        : GlobalSelectedProject?.label,
      build: isEditMode ? selectedAutomationSuiteData?.build_id : "",
      automationSuiteId: isEditMode
        ? selectedAutomationSuiteData?.automation_suite_id
        : isCreateMode
        ? nextAutomationSuiteId
        : nextAutomationSuiteId,
      automationSuiteName: isEditMode
        ? selectedAutomationSuiteData?.automation_suite_name
        : "",
      applicationType: isEditMode
        ? selectedAutomationSuiteData?.automation_suit_type
        : "",
      description: isEditMode ? selectedAutomationSuiteData?.description : "",
      testData: isEditMode ? selectedAutomationSuiteData?.test_data_id : "",
    },
    validationSchema,
    // validate: values => {
    //   const errors = {}
    // if (!description) {
    //   errors.description = " description is required"
    //   setIsDescription(true)
    // } else {
    //   setIsDescription(false)
    // }

    //   return errors
    // },

    onSubmit: async values => {
      const script_Id = automationScriptIds.map(item => item.value)
      const selscript_Id = selAutomationScriptId?.map(item => item.value)
      const data = [...selscript_Id, ...script_Id]
      const editautomation_script_id = data.filter(value => value !== "")
      const automation_script_id = script_Id.filter(value => value !== "")
      if (editautomation_script_id.length > 0) {
        setIsautomationScriptIds(false)
      } else {
        setIsautomationScriptIds(true)
      }
      if (!description) {
        setIsDescription(true)
        return
      } else {
        setIsDescription(false)
      }

      if (description && !isautomationScriptIds) {
        if (isEditMode && selectedAutomationSuiteData) {
          try {
            const bodyoptions = {
              id: selectedAutomationSuiteData.id,
              automation_script_id: editautomation_script_id,
              automation_suite_name: undefined,
              description: description,
              project_id: GlobalSelectedProject.value,
              build_id: values.build,
              test_data_id: values.testData,
              automation_suit_type: values.applicationType,
            }

            console.log(bodyoptions)
            const response = await dispatch(
              UpdateAutomationSuite(selectedAutomationSuiteData.id, bodyoptions)
            )

            if (response?.status) {
              navigate("/TestAutomation/AutomationSuite")
            }
          } catch (error) {
            console.error("Failed to submit form:", error.message)
          }
        } else {
          try {
            const bodyoptions = {
              automation_script_id: automation_script_id,
              automation_suite_name: values.automationSuiteName,
              description: description,
              project_id: GlobalSelectedProject.value,
              build_id: values.build,
              test_data_id: values.testData,
              automation_suit_type: values.applicationType,
            }
            console.log(bodyoptions)

            const response = await dispatch(CreateAutomationSuites(bodyoptions))

            if (response?.status) {
              navigate("/TestAutomation/AutomationSuite")
            }
          } catch (error) {
            console.error("Failed to submit form:", error.message)
          }
        }
      }
    },
  })

  const handleDescriptionChange = newContent => {
    setDescription(newContent)
    if (newContent) {
      setIsDescription(false)
    } else {
      setIsDescription(true)
    }
  }

  const handleApplicationTypeChange = selectedOption => {
    if (selectedOption && automationScriptData) {
      // Filter automationScriptData based on the selected application type
      const filteredScripts = automationScriptData?.filter(
        script => script.application_type === selectedOption.value
      )

      // Map filtered data to options for the automation script ID dropdown
      const scriptOptions = filteredScripts.map(script => ({
        value: script.id,
        label: `${script.automation_script_id} - ${script.automation_script_name}`,
      }))
      setAutomationScriptOptions(scriptOptions)
      formik.setFieldValue("applicationType", selectedOption.value)
    } else {
      setAutomationScriptOptions([])
    }
  }

  const addMoreDropdown = () => {
    setAutomationScriptIds([
      ...automationScriptIds,
      { id: Date.now(), value: "" },
    ])
    // if (isEditMode) {
    //     setselAutomationScriptId([...selAutomationScriptId, { id: Date.now(), value: '' }]);
    // }
  }

  const removeDropdown = id => {
    setAutomationScriptIds(
      automationScriptIds.filter(dropdown => dropdown.id !== id)
    )
    if (isEditMode) {
      setselAutomationScriptId(
        selAutomationScriptId.filter(dropdown => dropdown.id !== id)
      )
    }
  }

  const handleDropdownChange = (id, selectedOption) => {
    setIsautomationScriptIds(false)
    setAutomationScriptIds(prevState =>
      prevState.map(dropdown =>
        dropdown.id === id
          ? { ...dropdown, value: selectedOption?.value }
          : dropdown
      )
    )

    if (isEditMode) {
      setselAutomationScriptId(prevState =>
        prevState.map(dropdown =>
          dropdown.id === id
            ? { ...dropdown, value: selectedOption?.value }
            : dropdown
        )
      )
    }
  }

  const getFilteredOptions = currentId => {
    let selectedValues
    const data = [...selAutomationScriptId, ...automationScriptIds]
    if (isEditMode) {
      selectedValues = data
        .filter(dropdown => dropdown.id !== currentId && dropdown.value) // Exclude current dropdown and empty values
        .map(dropdown => dropdown.value)
    } else {
      selectedValues = automationScriptIds
        .filter(dropdown => dropdown.id !== currentId && dropdown.value) // Exclude current dropdown and empty values
        .map(dropdown => dropdown.value)
    }
    return automationScriptOptions?.filter(
      option => !selectedValues?.includes(option.value)
    )
  }

  const onSubmit = () => {
    const script_Id = automationScriptIds.map(item => item.value)
    const selscript_Id = selAutomationScriptId?.map(item => item.value)
    const data = [...selscript_Id, ...script_Id]
    const editautomation_script_id = data.filter(value => value !== "")
    if (!description) {
      setIsDescription(true)
    } else {
      setIsDescription(false)
    }

    if (editautomation_script_id.length > 0) {
      setIsautomationScriptIds(false)
    } else {
      setIsautomationScriptIds(true)
    }
  }

  const onCancel = () => {
    formik.handleReset()
    setIsDescription(false)
    navigate("/TestAutomation/AutomationSuite")
  }

  const renderSelect = (
    name,
    label,
    placeholder,
    options,
    isRequired = true,
    isDisabled = false,
    customProps = {}
  ) => (
    <div className="mb-3">
      <Label className="form-label">
        {label} {isRequired && <span className="text-danger">*</span>}
      </Label>
      <Select
        name={name}
        placeholder={placeholder}
        onChange={option => formik.setFieldValue(name, option?.value || "")}
        onBlur={() => formik.setFieldTouched(name)}
        options={options}
        className={`select2-selection ${
          formik.touched[name] && formik.errors[name] ? "is-invalid" : ""
        }`}
        {...customProps}
        maxMenuHeight={200}
        value={options.find(option => option.value === formik.values[name])}
        isClearable={true}
        isDisabled={isDisabled}
        styles={disabledCustomStyles}
      />
      {formik.touched[name] && formik.errors[name] && (
        <FormFeedback className="custom-error-message">
          {formik.errors[name]}
        </FormFeedback>
      )}
    </div>
  )

  const renderInput = (
    name,
    label,
    type = "text",
    placeholder = "",
    isRequired = true,
    disabled = false
  ) => (
    <div className="mb-3">
      <Label className="form-label">
        {label} {isRequired && <span className="text-danger">*</span>}
      </Label>
      <Input
        name={name}
        type={type}
        placeholder={placeholder}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        value={formik.values[name] || ""}
        invalid={formik.touched[name] && formik.errors[name]}
        className="custom-input"
        disabled={disabled}
      />
      {formik.touched[name] && formik.errors[name] && (
        <FormFeedback className="custom-error-message">
          {formik.errors[name]}
        </FormFeedback>
      )}
    </div>
  )

  const disabledCustomStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
      borderColor: layoutModeType === "dark" ? "#444" : "#ddd",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#2a3042",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888", // Keep or customize based on dark/light mode
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Ensure text is visible while typing
    }),
  }

  const customStyles = {
    control: provided => ({
      ...provided,
      width: 550,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
      borderColor: layoutModeType === "dark" ? "#444" : "#ddd",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#2a3042",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888", // Keep or customize based on dark/light mode
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Ensure text is visible while typing
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150,
      backgroundColor: "white", // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  }

  return (
    <React.Fragment>
      <div>
        <Container fluid>
          <Breadcrumbs
            title={
              currentUrl.includes("CreateAutomationSuite")
                ? "Create Automation Suite"
                : "Edit Automation Suite"
            }
            breadcrumbItems={breadcrumbItems}
          />

          <div className="mt-2 mx-1 col-12 d-flex justify-content-between">
            <div>
              <h3>
                {!isEditMode
                  ? "Create Automation Suite"
                  : "Edit Automation Suite"}
              </h3>
              <p className="text-muted">
                Fill the form to {!isEditMode ? "create" : "edit"} a automation
                suite
              </p>
            </div>
            <div>
              <Button
                type="button"
                color="primary"
                className="btn-label"
                onClick={() => {
                  navigate("/TestAutomation/AutomationSuite")
                }}
              >
                <i className="bx bx-arrow-back label-icon"></i> Go Back
              </Button>
            </div>
          </div>
          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    {renderInput(
                      "projectName",
                      "Project Name",
                      "text",
                      "Enter project name",
                      false,
                      true
                    )}
                    {renderInput(
                      "automationSuiteId",
                      "Automation Suite ID",
                      "text",
                      "Enter Automation Suite ID",
                      false,
                      true
                    )}
                    {renderInput(
                      "automationSuiteName",
                      "Automation Suite Name",
                      "text",
                      "Enter Automation Suite Name",
                      true,
                      isEditMode
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    {renderSelect(
                      "build",
                      "Build",
                      "Select Build",
                      buildValues,
                      false,
                      false
                    )}
                    {renderSelect(
                      "testData",
                      "Test Data",
                      "Select Test Data",
                      testDatas,
                      false,
                      false
                    )}
                    {renderSelect(
                      "applicationType",
                      "Application Type",
                      "Select Application Type",
                      applicationTypeOption,
                      true,
                      isEditMode,
                      { onChange: handleApplicationTypeChange }
                    )}
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div>
                      <Label>
                        Description <span className="text-danger">*</span>
                      </Label>
                      <JoditEditor
                        value={description}
                        config={config}
                        onBlur={handleDescriptionChange}
                      />
                      {isdescription && (
                        <div className="mt-1 font-size-10 text-danger">
                          Description is required
                        </div>
                      )}
                    </div>
                    {isEditMode && selAutomationScriptId.length > 0 && (
                      <div className="mt-3">
                        <Label> Selected Automation Script ID </Label>
                        {selAutomationScriptId?.map((item, index) => (
                          <div key={item.id} className="d-flex mb-2">
                            <Select
                              placeholder={`${item?.label}`}
                              className="select2-selection"
                              isDisabled={true}
                              styles={customStyles}
                            />

                            <i
                              className="mdi mdi-delete-outline mx-3 h3 mt-2 text-danger"
                              style={{ cursor: "pointer" }}
                              onClick={() => removeDropdown(item.id)}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                    <div className="mt-3">
                      <Label>
                        Automation Script ID{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      {automationScriptIds?.map((dropdown, index) => (
                        <div key={dropdown.id} className="d-flex ">
                          <Select
                            name={`automationScriptId-${index}`}
                            placeholder="Select Automation Script ID"
                            onChange={option =>
                              handleDropdownChange(dropdown.id, option)
                            }
                            options={getFilteredOptions(dropdown.id)}
                            value={automationScriptOptions?.find(
                              option => option.value === dropdown.value
                            )}
                            className="select2-selection"
                            isClearable={true}
                            styles={customStyles}
                          />
                          {automationScriptIds.length > 1 && (
                            <i
                              className="mdi mdi-delete-outline mx-3 h3 mt-2 text-danger"
                              style={{ cursor: "pointer" }}
                              onClick={() => removeDropdown(dropdown.id)}
                            />
                          )}
                        </div>
                      ))}
                      {isautomationScriptIds && (
                        <div className="mt-1 font-size-10 text-danger">
                          Automation script id is required
                        </div>
                      )}

                      <Button
                        className="mt-2"
                        type="button"
                        color="primary"
                        // disabled={
                        //   automationScriptOptions?.length ===
                        //   (isEditMode
                        //     ? automationScriptIds?.length - 1
                        //     : automationScriptIds?.length)
                        // }
                        onClick={addMoreDropdown}
                      >
                        ADD MORE
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <div className="d-flex justify-content-end flex-wrap gap-2 m-2 mb-3">
              <Button type="reset" color="secondary" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                onClick={onSubmit}
                disabled={loading}
              >
                {loading ? "Loading..." : "Submit"}
              </Button>
            </div>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreateAutomationSuite
