import moment from "moment"
import React from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"

const TestPlanInfo = ({ viewData = [] }) => {
  return (
    <div className="">
      <Container fluid>
        <Card>
          <CardBody>
            <div>
              <Row>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Project Name</h6>
                  <p className="text-muted ">
                    {viewData?.project ? viewData?.project : "-"}
                  </p>
                </Col>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Test Plan</h6>
                  <p className="text-muted ">
                    {viewData?.test_plan_id ? viewData?.test_plan_id : "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Name</h6>
                  <p className="text-muted ">
                    {viewData?.test_plan_name ? viewData?.test_plan_name : "-"}
                  </p>
                </Col>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Build</h6>
                  <p className="text-muted ">
                    {viewData?.build ? viewData?.build : "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <h6 style={{ fontWeight: "550" }}>Environment</h6>
                  <p
                    className="text-muted"
                    dangerouslySetInnerHTML={{
                      __html: viewData?.environment
                        ? viewData?.environment
                        : "-",
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <h6 style={{ fontWeight: "550" }}>Milestone</h6>
                  <p
                    className="text-muted"
                    dangerouslySetInnerHTML={{
                      __html: viewData?.milestone_description
                        ? viewData?.milestone_description
                        : "-",
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <h6 style={{ fontWeight: "550" }}>Deliverables</h6>
                  <p
                    className="text-muted"
                    dangerouslySetInnerHTML={{
                      __html: viewData?.deliverables
                        ? viewData?.deliverables
                        : "-",
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <h6 style={{ fontWeight: "550" }}>Description</h6>
                  <p
                    className="text-muted"
                    dangerouslySetInnerHTML={{
                      __html: viewData?.description
                        ? viewData?.description
                        : "-",
                    }}
                  />
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default TestPlanInfo
