import React, { useEffect, useState, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap"
import DeleteModal from "components/Common/DeleteModal"
import { useSelector, useDispatch } from "react-redux"
import { toast, ToastContainer } from "react-toastify"
import BulkDeleteModal from "components/Common/BulkDeleteModal"
import FilterTestCase from "./Filter Test Case/FilterTestCase"
import BulkEditTestCase from "./Edit Test Case/BulkEditTestCase"
import TableContainer from "components/Common/helper/TableContainer"
import {
  BulkDeleteTestCaseAPI,
  BulkEditTestCaseAPI,
  deleteTestCaseAPI,
  fetchNextCaseID,
  getAuditHistory,
  GetTestCaseByIdAPI,
  TestCaseCharts,
  TestCaseDetails,
} from "store/actions/TestCaseAction"
import { getStatusBackGroundColor } from "helpers/helper"
import moment from "moment"
import TestCaseChart from "./TestCaseChart"
import { clearFilters, setEditTestCase } from "store/Slices/TestCaseSlice"
import { setTcList } from "store/Slices/RequestPageSlice"
import NoPermission from "components/Common/NoPermission"

const TestCase = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const [data, setData] = useState([])
  const [modal, setModal] = useState(false)

  const allTestScenario = useSelector(state => state?.TestCase?.testCaseList)

  const permission = JSON.parse(localStorage.getItem("authUser"))
  const rolePermission = permission?.permissions?.[0]?.menu_item?.TestManagement

  const allChartData = useSelector(state => state?.TestCase?.testCaseChart)
  useEffect(() => {
    if (allTestScenario && allTestScenario.Data) {
      setData(allTestScenario)
    }
  }, [allTestScenario])

  const handleSingleEditClick = async id => {
    if (rolePermission?.testcase?.edit) {
      try {
        const res = await dispatch(GetTestCaseByIdAPI(id))
        if (res?.test_status === "Pending Approval") {
          toast.error("Pending Approval test case cannot be edited", {
            position: "top-right",
            id: " Error ",
            autoClose: 3000,
          })
        } else {
          dispatch(setEditTestCase({ isEdit: true, data: res }))
          navigate("/TestManagement/CreateEditTestCase", {
            state: { isEdit: true, data: res },
          })
        }
      } catch (error) {
        console.error("Error fetching data", error)
      }
    } else {
      toast.warn("You don't have the permission to edit test case", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }
  const [viewData, setViewData] = useState(null)

  const handleView = async id => {
    if (rolePermission?.testcase?.view) {
      let payload = {
        project_id: globalProject?.value || selectedProject?.value,
        test_case_id: id,
      }
      try {
        const res = await dispatch(GetTestCaseByIdAPI(id))
        await dispatch(getAuditHistory(payload))
        setViewData(res)
      } catch (error) {
        console.error("Error fetching data", error)
      }
    } else {
      toast.warn("You don't have the permission to view test case", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }
  useEffect(() => {
    if (viewData) {
      navigate("/TestManagement/ViewTestCase")
    }
  }, [viewData])

  const bodyOption = {
    project_id: globalProject?.value || selectedProject?.value,
  }

  const handleCreateClick = () => {
    if (rolePermission?.testcase?.create) {
      dispatch(fetchNextCaseID(bodyOption))
      navigate("/TestManagement/CreateEditTestCase")
    } else {
      toast.warn("You don't have the permission to create test case", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }

  const [deleteModal, setDeleteModal] = useState(false)
  const [bulkDeleteModal, setBulkDeleteModal] = useState(false)
  const [isDeleteId, setIsDeleteId] = useState("")

  const onClickDelete = testscenario => {
    if (testscenario?.test_status === "Pending Approval") {
      toast.error("Pending Approval test case cannot be deleted", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    } else {
      if (rolePermission?.testcase?.delete) {
        setIsDeleteId(testscenario?.id)
        setDeleteModal(true)
      } else {
        toast.warn("You don't have the permission to delete test case", {
          position: "top-right",
          id: " Error ",
          autoClose: 3000,
        })
      }
    }
  }

  const [selectedRows, setSelectedRows] = useState([])
  const handleCheckboxChange = test_case_id => {
    setSelectedRows(prevValues =>
      prevValues.includes(test_case_id)
        ? prevValues.filter(val => val !== test_case_id)
        : [...prevValues, test_case_id]
    )
  }

  const handleSelectAll = e => {
    const isChecked = e.target.checked

    if (isChecked) {
      // Filter out rows with "Approved" or "Pending" test_status
      const newSelectedRows = data
        ?.filter(item => item.test_status !== "Pending Approval")
        ?.map(item => item.test_case_id)

      setSelectedRows(newSelectedRows)
    } else {
      // Deselect all rows
      setSelectedRows([])
    }
  }

  const columns = useMemo(
    () => [
      {
        header: (
          <input
            type="checkbox"
            onChange={handleSelectAll}
            style={{ cursor: "pointer" }}
            title="Select All"
            checked={
              Array.isArray(selectedRows) &&
              Array.isArray(data) && // Ensure data is an array
              selectedRows.length ===
                data.filter(row => row.test_status !== "Pending Approval")
                  .length &&
              data.length > 0
            }
            disabled={
              Array.isArray(data) && // Ensure data is an array
              data.length > 0 &&
              data.every(row => row.test_status === "Pending Approval")
            }
          />
        ),
        accessorKey: "checkbox",
        cell: cellProps => {
          const jobData = cellProps.row.original.test_case_id
          const testStatus = cellProps.row.original.test_status

          // Disable the checkbox if the test status is "Approved" or "Pending"
          const isDisabled = testStatus === "Pending Approval"

          const isChecked = () => {
            if (!Array.isArray(selectedRows)) {
              console.error("selectedRows is not an array")
              return false
            }
            return selectedRows.includes(jobData)
          }

          const handleCheckboxClick = () => {
            try {
              if (!Array.isArray(selectedRows)) {
                throw new Error("selectedRows is not an array")
              }
              handleCheckboxChange(jobData)
            } catch (error) {
              console.error(error.message)
            }
          }

          return (
            <input
              type="checkbox"
              style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
              checked={isChecked()}
              onChange={!isDisabled ? handleCheckboxClick : undefined}
              onClick={!isDisabled ? handleCheckboxClick : undefined}
              disabled={isDisabled} // Disable checkbox if status is "Approved" or "Pending"
            />
          )
        },
        enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: "Test Case Id",
        accessorKey: "test_case_id",
        cell: cell => <div>{cell.getValue()}</div>,
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return <>{cell.getValue()}</>
        },
      },
      {
        header: "Test Case Name",
        accessorKey: "test_case_name",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const test_case_name = cell.getValue()
          return (
            <>
              <p
                className="font-size-14 mb-1"
                style={{
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  whiteSpace: "normal",
                  maxWidth: "350px",
                  minWidth: "200px",
                }}
              >
                {test_case_name ? test_case_name : "-"}
              </p>
            </>
          )
        },
      },
      {
        header: "Status",
        accessorKey: "test_status",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const test_status = cell.getValue()
          return (
            <>
              <Badge
                className={
                  "badge-soft-" + `${getStatusBackGroundColor(test_status)}`
                }
                style={{
                  fontSize: "12px", // Increase font size
                  padding: "0.5em", // Increase padding
                  borderRadius: "0.5em", // Adjust border radius
                }}
              >
                {test_status}
              </Badge>
            </>
          )
        },
      },
      {
        header: "Priority",
        accessorKey: "priority",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const priority = cell.getValue()
          return <>{priority ? priority : "-"}</>
        },
      },
      {
        header: "Test Scenario Id",
        accessorKey: "test_scenario",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const test_scenario = cell.getValue()
          return <>{test_scenario ? test_scenario : "-"}</>
        },
      },
      {
        header: "Requirement Title",
        accessorKey: "requirement_title",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const requirement_title = cell.getValue()
          return (
            <>
              <p
                className="font-size-14 mb-1"
                style={{
                  wordWrap: "break-word",
                  overflowWrap: "break-word",
                  whiteSpace: "normal",
                  minWidth: "200px",
                  maxWidth: "500px",
                }}
              >
                {requirement_title ? requirement_title : "-"}
              </p>
            </>
          )
        },
      },
      {
        header: "Execution Type",
        accessorKey: "execution_type",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          const execution_type = cell.getValue()
          return <>{execution_type ? execution_type : "-"}</>
        },
      },
      {
        header: "Created At",
        accessorKey: "created_at",
        enableColumnFilter: false,
        enableSorting: true,
        cell: cell => {
          return <>{moment(cell.getValue()).format("DD-MM-YYYY")}</>
        },
      },
      {
        header: "Action",
        cell: cellProps => {
          return (
            <ul className="list-unstyled hstack gap-1 mb-0">
              <li data-bs-toggle="tooltip" data-bs-placement="top">
                <span
                  // to="/"
                  className="btn btn-sm btn-soft-primary"
                  onClick={() => {
                    const data = cellProps.row.original
                    handleView(data?.id)
                  }}
                  id={`viewtooltip`}
                >
                  <i className="mdi mdi-eye-outline" />
                </span>
              </li>
              <UncontrolledTooltip placement="top" target={`viewtooltip`}>
                View
              </UncontrolledTooltip>

              <li>
                <span
                  to="#"
                  className="btn btn-sm btn-soft-info"
                  onClick={() => {
                    const data = cellProps.row.original
                    handleSingleEditClick(data?.id)
                  }}
                  id={`edittooltip-${cellProps.row.original.test_scenario_id}`}
                >
                  <i className="mdi mdi-pencil-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`edittooltip-${cellProps.row.original.test_scenario_id}`}
                  >
                    Edit
                  </UncontrolledTooltip>
                </span>
              </li>

              <li>
                <span
                  to="#"
                  className="btn btn-sm btn-soft-danger"
                  onClick={() => {
                    const userData = cellProps.row.original
                    onClickDelete(userData)
                  }}
                  id={`deletetooltip-${cellProps.row.original.test_scenario_id}`}
                >
                  <i className="mdi mdi-delete-outline" />
                  <UncontrolledTooltip
                    placement="top"
                    target={`deletetooltip-${cellProps.row.original.test_scenario_id}`}
                  >
                    Delete
                  </UncontrolledTooltip>
                </span>
              </li>
            </ul>
          )
        },
      },
    ],
    [selectedRows, data]
  )

  const [pageIndex, setPageIndex] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [globalFilter, setGlobalFilter] = useState("")
  const [totalCount, setTotalCount] = useState(null)
  const [isFilterFormOpen, setIsFilterFormOpen] = useState(false)
  const [isOpen, setIsEditOpen] = useState(false)
  // Provide a default empty object for testCaseFilterFrom
  const { testCaseFilterFrom } = useSelector(state => state.TestCase)

  const fetchTableData = async (page, size, search) => {
    // let userId = localStorage.getItem('authUser')
    // let userData = JSON.parse(userId);
    let bodyoption = {
      page: page + 1,
      page_size: size,
      search: search,
      project_id: globalProject?.value || selectedProject?.value,
      filter_build: testCaseFilterFrom?.filter_build,
      filter_requirement: testCaseFilterFrom?.filter_requirement,
      filter_module: testCaseFilterFrom?.filter_module,
      filter_test_scenario_id: testCaseFilterFrom?.filter_test_scenario_id,
      filter_test_status: testCaseFilterFrom?.filter_test_status,
      filter_testing_type: testCaseFilterFrom?.filter_testing_type,
      filter_execution_type: testCaseFilterFrom?.filter_execution_type,
      filter_created_by: testCaseFilterFrom?.filter_created_by,
      filter_assign_to: testCaseFilterFrom?.filter_assign_to,
      filter_approval_from: testCaseFilterFrom?.filter_approval_from,
    }
    let payload = {
      project_id: globalProject?.value || selectedProject?.value,
    }
    // let payloadOptions = {
    //   receiver: userData.user_id,
    //   models_name: "test_case",
    //   project_id: globalProject?.value || selectedProject?.value,
    // }
    try {
      const data = await dispatch(TestCaseDetails(bodyoption))
      dispatch(setTcList({ tcData: true }))
      await dispatch(TestCaseCharts(payload))
      setData(data.Data)
      setTotalCount(data.TotalCount)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  useEffect(() => {
    fetchTableData(pageIndex, pageSize, globalFilter)
  }, [
    pageIndex,
    pageSize,
    globalFilter,
    globalProject?.value,
    testCaseFilterFrom,
  ])

  const handleFilterSubmit = async values => {
    setPageIndex(0)
    let bodyoption = {
      page: 0 + 1,
      page_size: pageSize,
      search: globalFilter,
      project_id: globalProject?.value || selectedProject?.value,
      filter_build: testCaseFilterFrom?.filter_build,
      filter_requirement: testCaseFilterFrom?.filter_requirement,
      filter_module: testCaseFilterFrom?.filter_module,
      filter_test_scenario_id: testCaseFilterFrom?.filter_test_scenario_id,
      filter_test_status: testCaseFilterFrom?.filter_test_status,
      filter_testing_type: testCaseFilterFrom?.filter_testing_type,
      filter_execution_type: testCaseFilterFrom?.filter_execution_type,
      filter_created_by: testCaseFilterFrom?.filter_created_by,
      filter_assign_to: testCaseFilterFrom?.filter_assign_to,
      filter_approval_from: testCaseFilterFrom?.filter_approval_from,
    }
    try {
      const data = await dispatch(TestCaseDetails(bodyoption))
      setData(data.Data)
      setTotalCount(data.TotalCount)
      setIsFilterFormOpen(false)
      return data
    } catch (error) {
      console.error("Error fetching data", error)
    }
  }

  const handleDelete = async () => {
    try {
      await dispatch(deleteTestCaseAPI(isDeleteId))
      setDeleteModal(false)
      await fetchTableData(pageIndex, pageSize, globalFilter)
      setIsDeleteId("")
    } catch (error) {
      console.error("Error deleting test case:", error)
    }
  }

  const handleFilterClick = () => {
    setIsFilterFormOpen(!isFilterFormOpen)
  }

  const handleEditClick = () => {
    if (rolePermission?.testcase?.edit) {
      try {
        if (selectedRows?.length > 0) {
          setIsEditOpen(!isOpen)
        } else {
          toast.warn(
            "Please select a test case before attempting to bulk edit.",
            {
              position: "top-right",
              id: "selectBeforeDelete",
              autoClose: 3000,
            }
          )
        }
      } catch (error) {
        console.error("Error deleting test case:", error)
      }
    } else {
      toast.warn("You don't have the permission to edit test case's", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }
  const handleDeleteClick = async () => {
    if (rolePermission?.testcase?.delete) {
      try {
        if (selectedRows?.length > 0) {
          setBulkDeleteModal(true)
        } else {
          toast.warn(
            "Please select a test case before attempting to bulk delete.",
            {
              position: "top-right",
              id: "selectBeforeDelete",
              autoClose: 3000,
            }
          )
        }
      } catch (error) {
        console.error("Error deleting test case:", error)
      }
    } else {
      toast.warn("You don't have the permission to delete test case's", {
        position: "top-right",
        id: " Error ",
        autoClose: 3000,
      })
    }
  }
  const handleBulkDelete = async () => {
    const bodyoption = {
      project_id: globalProject?.value || selectedProject?.value,
      test_case_ids: selectedRows,
    }
    try {
      await dispatch(BulkDeleteTestCaseAPI(bodyoption))
      setSelectedRows([])
      setBulkDeleteModal(false)
      await fetchTableData(pageIndex, pageSize, globalFilter)
    } catch (error) {
      console.error("Error deleting test case:", error)
    }
  }
  const handleBulkEdit = async data => {
    const bodyOption = {
      ...data,
      project_id: globalProject?.value,
      test_case_ids: selectedRows,
    }
    try {
      await dispatch(BulkEditTestCaseAPI(bodyOption))
      setSelectedRows([])
      setIsEditOpen(false)
      await fetchTableData(pageIndex, pageSize, globalFilter)
    } catch (error) {
      console.error("Error updating test case:", error)
    }
  }
  useEffect(() => {
    return () => {
      dispatch(clearFilters())
    }
  }, [])

  return (
    <React.Fragment>
      {rolePermission?.testcase?.view ? (
        <>
          <DeleteModal
            show={deleteModal}
            onDeleteClick={handleDelete}
            onCloseClick={() => setDeleteModal(false)}
            message="Test Case"
          />
          <BulkDeleteModal
            show={bulkDeleteModal}
            onDeleteClick={handleBulkDelete}
            onCloseClick={() => {
              setBulkDeleteModal(false)
              setSelectedRows([])
            }}
            message="Test Case"
          />

          <FilterTestCase
            isFilterFormOpen={isFilterFormOpen}
            toggle={handleFilterClick}
            onSubmitFilterFrom={handleFilterSubmit}
          />
          <BulkEditTestCase
            isOpen={isOpen}
            toggle={handleEditClick}
            handleBulkEdit={handleBulkEdit}
            setSelectedRows={setSelectedRows}
          />
          {allChartData?.length !== 0 && (
            <TestCaseChart chartData={allChartData?.data} />
          )}
          <Container fluid>
            <Row>
              <Col lg="12">
                <Card
                  style={{
                    borderTopLeftRadius: "0px",
                    borderTopRightRadius: "0px",
                  }}
                >
                  <CardBody className="pt-2">
                    <div className="table-container">
                      <TableContainer
                        columns={columns}
                        data={data}
                        pageIndex={pageIndex}
                        setPageIndex={setPageIndex}
                        pageSize={pageSize}
                        setPageSize={setPageSize}
                        totalCount={totalCount}
                        globalFilter={globalFilter}
                        setGlobalFilter={setGlobalFilter}
                        isGlobalFilter={true}
                        isPagination={true}
                        isCustomPageSize={true}
                        isAddButton={true}
                        isAddDelete={true}
                        isAddEdit={true}
                        isAddFilter={true}
                        pagination="pagination"
                        SearchPlaceholder="Search..."
                        handleAddClick={handleCreateClick}
                        handleDeleteClick={handleDeleteClick}
                        handleEditClick={handleEditClick}
                        handleFilterClick={handleFilterClick}
                        tableClass="project-list-table align-middle table-nowrap dt-responsive nowrap w-100 table-borderless dataTable no-footer dtr-inline"
                        theadClass="table-light"
                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>

          <ToastContainer />
        </>
      ) : (
        <NoPermission />
      )}
    </React.Fragment>
  )
}

export default withRouter(TestCase)
