import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  Container,
  FormFeedback,
  Form,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import "flatpickr/dist/themes/material_blue.css"
import JoditEditor from "jodit-react"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import { joditConfig } from "../../../../helpers/helper"
import { toast } from "react-toastify"
import { useLocation, useNavigate } from "react-router-dom"
import Breadcrumb from "components/Common/Breadcrumb"
import "./CreateTestScenarioForm.scss"
import { fetchAllBuildsList } from "store/actions/BuildsActions"
import { fetchAllModulesList } from "store/actions/ModuleActions"
import { fetchAllRequirementsList } from "store/actions/RequirementActions"
import {
  createTestScenarioAPI,
  EditTestScenarioAPI,
  fetchNextScenarioID,
} from "store/actions/TestScenarioActions"
import { setEditTestScenario } from "store/Slices/TestScenarioSlice"

const CreateTestScenarioForm = () => {
  const [buildOptionValue, setBuildOptionValues] = useState([])
  const [moduleOptionValue, setModuleOptionValues] = useState([])
  const [requirementOptionValue, setRequirementOptionValues] = useState([])
  const [nextTestScenarioId, setNextTestScenarioId] = useState("")
  const layoutModeType = useSelector(state => state.Layout.layoutModeType)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const { isEdit, data } = location.state || {}

  const loading = useSelector(state => state?.Loading?.isLoading)
  const currentUrl = window.location.href
  const parts = currentUrl.split("/")
  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const stripHtmlTags = html => {
    const doc = new DOMParser().parseFromString(html, "text/html")
    return doc.body.textContent || ""
  }

  const editTestScenario = useSelector(
    state => state?.TestScenario?.editTestScenario
  )
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))

  const isEditMode = editTestScenario?.isEdit || isEdit
  const isEditModeData = editTestScenario?.data || data

  const [description, setDescription] = useState("")
  const [isDescriptionInvalid, setIsDescriptionInvalid] = useState(false)

  const breadcrumbItems = [
    { label: "Test Scenario", path: "/TestManagement/TestScenario" },
    {
      label: isEditMode ? "Edit Test Scenario " : "Create Test Scenario",
      path: "/",
    },
  ]

  const validationSchema = Yup.object().shape({
    test_scenario_name: Yup.string()
      .trim()
      .matches(/^.*\S.*$/, "Test Scenario Name cannot contain only spaces")
      .required("Test Scenario Name is required"),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      project_id: globalProject?.value || selectedProject?.value,
      test_scenario_id: isEditModeData
        ? isEditModeData?.test_scenario_id
        : nextTestScenarioId,
      build_id: isEditModeData ? isEditModeData?.build_id || "" : "",
      module_id: isEditModeData ? isEditModeData?.module_id || "" : "",
      requirement_id: isEditModeData
        ? isEditModeData?.requirement_id || ""
        : "",
      test_scenario_name: isEditModeData
        ? isEditModeData?.test_scenario_name || ""
        : "",
      description: isEditModeData ? isEditModeData?.description || "" : "",
    },
    validationSchema,
    onSubmit: values => {
      // Remove HTML tags and trim whitespace from the description
      const cleanDescription = stripHtmlTags(description).trim()

      // Check if the description is empty
      if (!cleanDescription) {
        setIsDescriptionInvalid(true) // Set an error flag
      } else {
        const formData = {
          ...values,
          description,
        }

        createTestScenario(formData)
      }
    },
  })

  const createTestScenario = async formData => {
    const editFromValues = {
      ...formData,
      id: isEditModeData?.id,
    }

    if (!isEditModeData) {
      try {
        await dispatch(createTestScenarioAPI(formData))
        navigate(-1) // Navigate back or handle success
      } catch (error) {
        console.error("Error creating test scenario:", error)
        toast.error("An error occurred while creating Test Scenario", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
      }
    } else {
      try {
        await dispatch(EditTestScenarioAPI(editFromValues))
        navigate(-1) // Navigate back or handle success
        dispatch(setEditTestScenario({ isEdit: false, data: null }))
      } catch (error) {
        console.error("Error updating test scenario:", error)
        toast.error("An error occurred while updating Test Scenario", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        })
      }
    }
  }

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const responses = await Promise.all([
          dispatch(
            fetchAllBuildsList({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          dispatch(
            fetchAllModulesList({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          dispatch(
            fetchAllRequirementsList({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          dispatch(
            fetchNextScenarioID({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
        ])

        const [buildResponse, moduleResponse, reqResponse, nextIdResponse] =
          responses

        setBuildOptionValues(
          buildResponse?.map(entry => ({
            value: Math.round(entry.id),
            label: entry.build_id,
          }))
        )

        setModuleOptionValues(
          moduleResponse?.map(entry => ({
            value: entry.id,
            label: entry.module_name,
          }))
        )

        setRequirementOptionValues(
          reqResponse?.map(entry => ({
            value: entry.id,
            label: entry.requirement_title,
          }))
        )
        await setNextTestScenarioId(nextIdResponse?.next_test_scenario_id)
      } catch (error) {
        console.error("Failed to fetch options", error)
        toast.error("Failed to fetch options.")
      }
    }
    fetchOptions()
  }, [globalProject, dispatch])

  useEffect(() => {
    if (isEditModeData) {
      setDescription(isEditModeData.description || "")
    }
  }, [isEditModeData])

  const submitData = () => {
    const cleanDescription = stripHtmlTags(description).trim()
    if (!cleanDescription) {
      setIsDescriptionInvalid(true)
    } else {
      setIsDescriptionInvalid(false)
    }
  }

  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888",
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Ensure input text is visible
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150, // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  }

  return (
    <React.Fragment>
      <div className={parts.includes("TestManagement") ? "" : "page-content"}>
        <Container fluid>
          <Breadcrumb title="Test Scenario" breadcrumbItems={breadcrumbItems} />

          <div className="mt-2 mx-3 d-flex justify-content-between">
            <div>
              <h3>
                {!isEditMode
                  ? "Create New Test Scenario"
                  : "Edit Test Scenario"}
              </h3>
              <p className="text-muted">
                Fill the form to {!isEditMode ? "create" : "edit"} a test
                scenario
              </p>
            </div>
            <div>
              <Button
                type="button"
                color="primary"
                className="btn-label"
                onClick={() => {
                  navigate(-1)
                  dispatch(setEditTestScenario({ isEdit: false, data: null }))
                }}
              >
                <i className="bx bx-arrow-back label-icon"></i> Go Back
              </Button>
            </div>
          </div>

          <Form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <div className="mb-3">
                      <Label className="form-label">Project</Label>
                      <Input
                        name="project_id"
                        type="text"
                        placeholder="Project"
                        value={selectedProject?.label}
                        className="custom-input"
                        readOnly
                        disabled
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Test Scenario Id</Label>
                      <Input
                        name="test_scenario_id"
                        type="text"
                        placeholder="Test Scenario Id"
                        value={formik.values.test_scenario_id}
                        className="custom-input"
                        readOnly
                        disabled
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">
                        Test Scenario Name{" "}
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        name="test_scenario_name"
                        type="text"
                        placeholder="Enter Test Scenario Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.test_scenario_name}
                        invalid={
                          formik.touched.test_scenario_name &&
                          !!formik.errors.test_scenario_name
                        }
                        className="custom-input"
                      />
                      {formik.touched.test_scenario_name &&
                        formik.errors.test_scenario_name && (
                          <FormFeedback>
                            {formik.errors.test_scenario_name}
                          </FormFeedback>
                        )}
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={6}>
                <Card>
                  <CardBody>
                    <div className="mb-3">
                      <Label className="form-label">Build</Label>
                      <Select
                        isClearable
                        name="build_id"
                        className="select2-selection"
                        options={buildOptionValue}
                        onChange={option =>
                          formik.setFieldValue(
                            "build_id",
                            option ? option.value : ""
                          )
                        }
                        onBlur={() => formik.setFieldTouched("build_id")}
                        value={buildOptionValue.find(
                          option => option.value === formik.values.build_id
                        )}
                        styles={customStyles}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Module</Label>
                      <Select
                        isClearable
                        name="module_id"
                        options={moduleOptionValue}
                        className="select2-selection"
                        onChange={option =>
                          formik.setFieldValue(
                            "module_id",
                            option ? option.value : ""
                          )
                        }
                        onBlur={() => formik.setFieldTouched("module_id")}
                        value={moduleOptionValue.find(
                          option => option.value === formik.values.module_id
                        )}
                        styles={customStyles}
                      />
                    </div>
                    <div className="mb-3">
                      <Label className="form-label">Requirement</Label>
                      <Select
                        isClearable
                        name="requirement_id"
                        className="select2-selection"
                        options={requirementOptionValue}
                        onChange={option =>
                          formik.setFieldValue(
                            "requirement_id",
                            option ? option.value : ""
                          )
                        }
                        onBlur={() => formik.setFieldTouched("requirement_id")}
                        value={requirementOptionValue.find(
                          option =>
                            option.value === formik.values.requirement_id
                        )}
                        styles={customStyles}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12} className="mb-3">
                <Card>
                  <CardBody>
                    <Col md="12">
                      <Label>
                        Description <span className="text-danger">*</span>
                      </Label>
                      <JoditEditor
                        value={description}
                        config={joditConfig()}
                        onBlur={newContent => setDescription(newContent)}
                        // onBlur={() => setIsDescriptionInvalid(!description)}
                      />
                      {isDescriptionInvalid && (
                        <FormFeedback className="d-block">
                          Description is required
                        </FormFeedback>
                      )}
                    </Col>
                  </CardBody>
                </Card>
                <Row>
                  <Col md="12" className="text-end mt-3">
                    <Button
                      color="primary"
                      type="submit"
                      onClick={submitData}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Submit"}
                    </Button>
                    <Button
                      type="button"
                      color="secondary"
                      onClick={() => {
                        formik.resetForm()
                        navigate(-1)
                        dispatch(
                          setEditTestScenario({ isEdit: false, data: null })
                        )
                      }}
                      className="ms-2"
                    >
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default CreateTestScenarioForm
