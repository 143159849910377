import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Container,
  Form,
  Input,
  FormFeedback,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import "flatpickr/dist/themes/material_blue.css"
import Select from "react-select"
import { useSelector, useDispatch } from "react-redux"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import Breadcrumb from "components/Common/Breadcrumb"
import { getTagsStautusAPI } from "store/actions/TagsAction"
import { createOptions } from "helpers/helper"
import {
  createDbIntegrationAPI,
  EditDbIntegrationAPI,
} from "store/actions/DBIntegrationActions"
import { setEditDbIntegration } from "store/Slices/DbIntegrationSlice"
import { createAgentAPI, FetchToken } from "store/actions/AgentCreationActions"

const CommandStep = ({ label, command, onCopy }) => {
  const [copied, setCopied] = useState(false)
  const [hovered, setHovered] = useState(false)

  const handleCopy = () => {
    onCopy(command)
    setCopied(true)
    setTimeout(() => setCopied(false), 3000) // Reset copied state after 3 seconds
  }

  return (
    <div className="command-step" style={styles.commandStep}>
      <label style={styles.label}>{label}</label>
      <code style={styles.code}>{command}</code>

      <button
        style={{
          ...(label ? styles.button : styles.button1), // Apply styles.button only if label exists
          ...(hovered ? styles.buttonHovered : {}), // Apply hover style if hovered
        }}
        onClick={handleCopy}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        title={copied ? "Copied!" : "Copy"} // Set tooltip based on copied state
      >
        <i className={copied ? "mdi mdi-check" : "mdi mdi-content-copy"} />
      </button>
    </div>
  )
}

const CreateAgent = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const globalProject = useSelector(
    state => state?.globalProjectSelect?.selectetProject
  )

  const editDbIntegration = useSelector(
    state => state?.DbIntegration?.editDbIntegration
  )

  const mapProjectList = useSelector(state => state?.Integration?.mapList)
  const [gitHub, setGitHub] = useState([])

  useEffect(() => {
    const filteredProjectList = mapProjectList?.filter(
      project =>
        project.product === "GitHub" &&
        project.frothtestops_project === globalProject?.label
    )

    setGitHub(filteredProjectList)
  }, [mapProjectList])

  const [selectedValue, setSelectedValue] = useState("macOS")

  const handleRadioChange = event => {
    setSelectedValue(event.target.value)
  }

  const isEditMode = editDbIntegration?.isEdit
  // const isEditModeData = editDbIntegration?.data
  const loading = useSelector(state => state?.Loading?.isLoading)
  const breadcrumbItems = [
    {
      label: "Test Lab / Test Automation",
      path: "/TestAutomation/AgentCreation",
    },
    {
      label: "Create Agent",
      path: "/",
    },
  ]

  const validationSchema = Yup.object().shape({
    agent_name: Yup.string()
      .trim()
      .matches(/^.*\S.*$/, "Agent Name cannot contain only spaces")
      .required("Agent Name is required"),
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      project_id: globalProject?.value,
      agent_name: "",
      tool_name: "GitHub",
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      create(values)
      resetForm()
    },
  })

  const create = async formData => {
    try {
      await dispatch(createAgentAPI(formData))
      navigate(-1) // Navigate back or handle success
    } catch (error) {
      toast.error(error || "An error occurred", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      })
    }
  }

  // const [databasetype, setDatabaseType] = useState([])
  // const [databasetypeOptions, setDatabaseTypeOptions] = useState([])
  const [token, setToken] = useState([])

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const { data } = await dispatch(
          FetchToken({
            project_id: globalProject?.value,
          })
        )
        setToken(data?.token)
      } catch (error) {
        console.error(error)
      }
    }

    fetchOptions()
  }, [globalProject])

  const configCommandMac = `./config.sh --url https://github.com/${gitHub[0]?.organizations}/${gitHub[0]?.GitHub_Repository} --token ${token}`
  const runCommandMac = "./run.sh"
  const configCommandLinux = `./config.sh --url https://github.com/${gitHub[0]?.organizations}/${gitHub[0]?.GitHub_Repository} --token ${token}`
  const runCommandLinux = "./run.sh"
  const configCommandWindows = `./config.cmd --url https://github.com/${gitHub[0]?.organizations}/${gitHub[0]?.GitHub_Repository} --token ${token}`
  const runCommandWindows = "./run.cmd"

  // Function to copy command to clipboard
  const copyToClipboard = command => {
    event.preventDefault()
    navigator.clipboard.writeText(command).then(() => {
      // toast.success("Command copied!", {
      //     position: toast.POSITION.TOP_RIGHT,
      //     autoClose: 3000,
      // })
    })
  }

  return (
    <React.Fragment>
      <Container fluid>
        <Breadcrumb title="Test Plan" breadcrumbItems={breadcrumbItems} />

        <div className="mt-2 mx-3 d-flex justify-content-between">
          <div>
            <h3>Create Agent</h3>
            <p className="text-muted">
              Please follow the steps and fill the form to configure an agent
            </p>
          </div>
          <div>
            <Button
              type="button"
              color="primary"
              onClick={() => {
                navigate(-1)
              }}
            >
              Go Back
            </Button>
          </div>
        </div>

        <Form onSubmit={formik.handleSubmit}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <div className="mt-1 d-flex mb-3 gap-5 justify-content-center">
                    <div
                      className="d-flex border p-3"
                      style={{ borderRadius: "5px" }}
                    >
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios1"
                          value="macOS"
                          defaultChecked={selectedValue === "macOS"}
                          disabled={isEditMode && true}
                          onChange={handleRadioChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios1"
                        >
                          <i className="mdi mdi-apple me-1" />
                          macOS
                        </label>
                      </div>
                    </div>
                    <div
                      className="d-flex border p-3"
                      style={{ borderRadius: "5px" }}
                    >
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios2"
                          disabled={isEditMode && true}
                          value="Window"
                          defaultChecked={selectedValue === "Window"}
                          onChange={handleRadioChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios2"
                        >
                          <i className="mdi mdi-microsoft-windows me-1" />
                          Window
                        </label>
                      </div>
                    </div>
                    <div
                      className="d-flex border p-3"
                      style={{ borderRadius: "5px" }}
                    >
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="exampleRadios"
                          id="exampleRadios2"
                          disabled={isEditMode && true}
                          value="Linux"
                          defaultChecked={selectedValue === "Linux"}
                          onChange={handleRadioChange}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="exampleRadios2"
                        >
                          <i className="mdi mdi-linux me-1" />
                          Linux
                        </label>
                      </div>
                    </div>
                  </div>
                  <strong className="mb-3">Download & Install</strong>
                  {selectedValue === "macOS" && (
                    <>
                      <p>Please execute the below command in terminal </p>
                      <div style={styles.container}>
                        <CommandStep
                          label="Create a folder"
                          command="mkdir <folder_name> && <folder_name>"
                          onCopy={copyToClipboard}
                        />
                        <CommandStep
                          label="Download the latest runner package"
                          command="curl -o actions-runner-osx-x64-2.319.1.tar.gz -L https://github.com/actions/runner/releases/download/v2.319.1/actions-runner-osx-x64-2.319.1.tar.gz"
                          onCopy={copyToClipboard}
                        />
                        <CommandStep
                          label="Extract the installer"
                          command="tar xzf ./actions-runner-osx-x64-2.319.1.tar.gz"
                          onCopy={copyToClipboard}
                        />
                      </div>
                    </>
                  )}
                  {selectedValue === "Linux" && (
                    <>
                      <p>Please execute the below command in terminal </p>
                      <div style={styles.container}>
                        <CommandStep
                          label="Create a folder"
                          command="mkdir <folder_name> && <folder_name>"
                          onCopy={copyToClipboard}
                        />
                        <CommandStep
                          label="Download the latest runner package"
                          command="curl -o actions-runner-linux-x64-2.319.1.tar.gz -L https://github.com/actions/runner/releases/download/v2.319.1/actions-runner-linux-x64-2.319.1.tar.gz"
                          onCopy={copyToClipboard}
                        />
                        <CommandStep
                          label="Extract the installer"
                          command="tar xzf ./actions-runner-linux-x64-2.319.1.tar.gz"
                          onCopy={copyToClipboard}
                        />
                      </div>
                    </>
                  )}
                  {selectedValue === "Window" && (
                    <>
                      <p>
                        Please execute the below command in windows powershell{" "}
                      </p>
                      <div style={styles.container}>
                        <CommandStep
                          label="Create a folder"
                          command="mkdir <folder_name>; cd <folder_name>"
                          onCopy={copyToClipboard}
                        />
                        <CommandStep
                          label="Download the latest runner package"
                          command="Invoke-WebRequest -Uri https://github.com/actions/runner/releases/download/v2.319.1/actions-runner-win-x64-2.319.1.zip -OutFile actions-runner-win-x64-2.319.1.zip"
                          onCopy={copyToClipboard}
                        />
                        <CommandStep
                          label="Extract the installer"
                          command="Expand-Archive -Path .\actions-runner-win-x64-2.319.1.zip -DestinationPath .\"
                          onCopy={copyToClipboard}
                        />
                      </div>
                    </>
                  )}
                  <div className="mt-3 mb-3" style={styles.container}>
                    {/* <CommandStep
                                            label="Registration Token"
                                            command={token}
                                            onCopy={copyToClipboard}
                                        /> */}

                    <div className="command-step" style={styles.commandStep}>
                      <label style={styles.label}>Registration Token</label>
                      <code style={styles.code}>{token}</code>
                    </div>
                  </div>
                  <strong className="mb-3">Configure</strong>
                  {selectedValue === "macOS" && (
                    <>
                      <div
                        className="mt-3 mb-2"
                        style={{
                          padding: "20px",
                          backgroundColor: "#f4f4f4",
                          borderRadius: "8px",
                          fontFamily: "monospace",
                          marginTop: "1rem",
                        }}
                      >
                        {/* Step 1 - Config command */}
                        {/* <div style={{ position: 'relative', marginBottom: '15px' }}>

                                                <code style={{ display: 'block', backgroundColor: 'rgb(207 207 207 / 73%)', color: 'black', padding: '10px', borderRadius: '5px' }}>
                                                    {configCommandMac}
                                                </code>
                                                <button
                                                    style={{
                                                        position: 'absolute',
                                                        right: '10px',
                                                        top: '5px',
                                                        backgroundColor: 'rgb(216 216 216)',
                                                        color: 'black',
                                                        border: 'none',
                                                        padding: '5px 10px',
                                                        borderRadius: '3px',
                                                        cursor: 'pointer'
                                                    }}
                                                    onClick={() => copyToClipboard(configCommandMac)}
                                                >
                                                    <i className="mdi mdi-content-copy" />
                                                </button>
                                            </div> */}
                        <div style={styles.containerSub}>
                          <CommandStep
                            label="Follow the steps below to configure the runner:"
                            command={configCommandMac}
                            onCopy={copyToClipboard}
                          />
                        </div>

                        {/* Other configuration instructions */}
                        <p>
                          Enter the name of the runner group to add this runner
                          to: [press Enter for Default] &rarr;{" "}
                          <strong>PRESS ENTER</strong>
                        </p>
                        <p>
                          Enter the name of the runner: [press Enter for
                          Users-MacBook-Air-2] &rarr;{" "}
                          <strong>&lt;unique name for the runner&gt;</strong>
                        </p>
                        <p>
                          This runner will have the following labels:
                          'self-hosted', 'macOS', 'X64'
                        </p>
                        <p>
                          Enter any additional labels (e.g., label-1, label-2):
                          [press Enter to skip] &rarr;{" "}
                          <strong>&lt;same name as above&gt;</strong>
                        </p>
                        <p>
                          Enter the name of the work folder: [press Enter for
                          _work] &rarr; <strong>PRESS ENTER</strong>
                        </p>

                        {selectedValue == "macOS" && (
                          <>
                            Kindly ensure the execution path is noted in case
                            the PowerShell window closes.
                            <div style={styles.containerSub}>
                              <b className="mb-2">
                                {" "}
                                # Note the execution path by executing the below
                                command.
                                <br /> # Note : to re-execute go to this
                                location
                              </b>
                              <CommandStep
                                command="pwd"
                                onCopy={copyToClipboard}
                              />
                            </div>
                          </>
                        )}

                        {/* Step 2 - Run command */}

                        <div style={styles.containerSub}>
                          <CommandStep
                            label="# Last step, run it!"
                            command={runCommandMac}
                            onCopy={copyToClipboard}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {selectedValue === "Linux" && (
                    <>
                      <div
                        className="mt-3 mb-2"
                        style={{
                          padding: "20px",
                          backgroundColor: "#f4f4f4",
                          borderRadius: "8px",
                          fontFamily: "monospace",
                          marginTop: "1rem",
                        }}
                      >
                        <div style={styles.containerSub}>
                          <CommandStep
                            label="Follow the steps below to configure the runner:"
                            command={configCommandLinux}
                            onCopy={copyToClipboard}
                          />
                        </div>

                        {/* Other configuration instructions */}
                        <p>
                          Enter the name of the runner group to add this runner
                          to: [press Enter for Default] &rarr;{" "}
                          <strong>PRESS ENTER</strong>
                        </p>
                        <p>
                          Enter the name of the runner: [press Enter for
                          Users-Linux] &rarr;{" "}
                          <strong>&lt;unique name for the runner&gt;</strong>
                        </p>
                        <p>
                          This runner will have the following labels:
                          'self-hosted', 'Linux', 'X64'
                        </p>
                        <p>
                          Enter any additional labels (e.g., label-1, label-2):
                          [press Enter to skip] &rarr;{" "}
                          <strong>&lt;same name as above&gt;</strong>
                        </p>
                        <p>
                          Enter name of work folder: [press Enter for _work]
                          &rarr; <strong>PRESS ENTER</strong>
                        </p>
                        {selectedValue == "Linux" && (
                          <>
                            Kindly ensure the execution path is noted in case
                            the PowerShell window closes.
                            <div style={styles.containerSub}>
                              <b className="mb-2">
                                {" "}
                                # Note the execution path by executing the below
                                command.
                                <br /> # Note : to re-execute go to this
                                location
                              </b>
                              <CommandStep
                                command="ls"
                                onCopy={copyToClipboard}
                              />
                            </div>{" "}
                            <br />
                          </>
                        )}
                        <div style={styles.containerSub}>
                          <CommandStep
                            label="# Last step, run it!"
                            command={runCommandLinux}
                            onCopy={copyToClipboard}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {selectedValue === "Window" && (
                    <>
                      <div
                        className="mt-3 mb-2"
                        style={{
                          padding: "20px",
                          backgroundColor: "#f4f4f4",
                          borderRadius: "8px",
                          fontFamily: "monospace",
                          marginTop: "1rem",
                        }}
                      >
                        <div style={styles.containerSub}>
                          <CommandStep
                            label="Follow the steps below to configure the runner:"
                            command={configCommandWindows}
                            onCopy={copyToClipboard}
                          />
                        </div>

                        {/* Other configuration instructions */}
                        <p>
                          # Create the runner and start the configuration
                          experience
                          <br />
                          Example $ ./config.cmd --url
                          https://github.com/YTLC-Testing-Team/ytlc_product
                          token BFNE744D12E23V2UMDGBPVTGZV2YI
                        </p>
                        <p>
                          Enter the name of the runner group to add this runner
                          to: [press Enter for Default] &rarr;{" "}
                          <strong>PRESS ENTER</strong>
                        </p>
                        <p>
                          Enter the name of runner: [press Enter for
                          Users-Windows] &rarr;{" "}
                          <strong>&lt;unique name for the runner&gt;</strong>
                        </p>
                        <p>
                          This runner will have the following labels:
                          'self-hosted', 'Windows', 'X64'
                        </p>
                        <p>
                          Enter any additional labels (e.g., label-1, label-2):
                          [press Enter to skip] &rarr;{" "}
                          <strong>&lt;same name as above&gt;</strong>
                        </p>
                        <p>
                          Enter name of work folder: [press Enter for work]
                          &rarr; <strong>PRESS ENTER</strong>
                        </p>
                        {selectedValue == "Window" && (
                          <>
                            Kindly ensure the execution path is noted in case
                            the PowerShell window closes.
                            <div style={styles.containerSub}>
                              <b className="mb-2">
                                {" "}
                                # Note the execution path by executing the below
                                command.
                                <br /> # Note : to re-execute go to this
                                location
                              </b>
                              <CommandStep
                                command="pwd"
                                onCopy={copyToClipboard}
                              />
                              <br />
                            </div>
                          </>
                        )}
                        <div style={styles.containerSub}>
                          <CommandStep
                            label="# Last step, run it!"
                            command={runCommandWindows}
                            onCopy={copyToClipboard}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  <div className="col-5 mb-3">
                    <Label className="form-label">
                      Agent Name <span className="text-danger">*</span>
                      <i
                        className="mdi mdi-information-outline ms-1"
                        title="Please enter the name of the agent you provided during the configuration process"
                      />
                    </Label>
                    <div className="mb-2">
                      <Input
                        name="agent_name"
                        type="text"
                        placeholder="Agent Name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.agent_name}
                        invalid={
                          formik.touched.agent_name &&
                          !!formik.errors.agent_name
                        }
                        className="custom-input"
                      />
                      {formik.touched.agent_name &&
                        formik.errors.agent_name && (
                          <FormFeedback>
                            {formik.errors.agent_name}
                          </FormFeedback>
                        )}
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>

            <Col lg={12}>
              <Row className="mb-3">
                <Col md="12" className="text-end mt-3">
                  <Button color="primary" type="submit" disabled={loading}>
                    {loading ? "Loading..." : "Submit"}
                  </Button>
                  <Button
                    type="button"
                    color="secondary"
                    onClick={() => {
                      formik.resetForm()
                      navigate(-1)
                      dispatch(
                        setEditDbIntegration({ isEdit: false, data: null })
                      )
                    }}
                    className="ms-2"
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Container>
    </React.Fragment>
  )
}
const styles = {
  commandStep: {
    position: "relative",
    marginBottom: "15px",
    padding: "0px",
    backgroundColor: "#f4f4f4",
    borderRadius: "8px",
    fontFamily: "monospace",
  },
  label: {
    fontWeight: "bold",
    marginBottom: "5px",
  },
  code: {
    display: "block",
    backgroundColor: "rgb(207 207 207 / 73%)",
    color: "black",
    padding: "10px",
    borderRadius: "5px",
  },
  button: {
    position: "absolute",
    right: "10px",
    top: "28px",
    backgroundColor: "rgb(216 216 216)", // Your updated background color
    color: "black", // Button text color
    border: "none", // No border
    padding: "5px 10px", // Padding for the button
    borderRadius: "3px", // Rounded corners
    cursor: "pointer", // Pointer cursor on hover
    transition: "background-color 0.3s ease", // Smooth background color transition on hover
  },
  button1: {
    position: "absolute",
    right: "10px",
    top: "23px",
    backgroundColor: "rgb(216 216 216)", // Your updated background color
    color: "black", // Button text color
    border: "none", // No border
    padding: "5px 10px", // Padding for the button
    borderRadius: "3px", // Rounded corners
    cursor: "pointer", // Pointer cursor on hover
    transition: "background-color 0.3s ease", // Smooth background color transition on hover
  },
  container: {
    padding: "20px",
    backgroundColor: "#f4f4f4",
    borderRadius: "8px",
    fontFamily: "monospace",
    marginTop: "1rem",
  },
  containerSub: {
    backgroundColor: "#f4f4f4",
    borderRadius: "8px",
    fontFamily: "monospace",
  },
}
export default CreateAgent
