import React, { useEffect, useState } from "react"
import { Label, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { fetchAllBuildsList } from "store/actions/BuildsActions"
import { fetchAllModulesList } from "store/actions/ModuleActions"
import { fetchAllRequirementsList } from "store/actions/RequirementActions"
import { useFormik } from "formik"
// import {
//   fetchAllUsersDetailsList,
//   fetchUsersList,
// } from "store/actions/UserActions"
import { getTagsStautusAPI } from "store/actions/TagsAction"
import { createOptions } from "helpers/helper"
import { fetchAllTestScenarioList } from "store/actions/TestScenarioActions"
import { clearFilters, setTestCaseFilterFrom } from "store/Slices/TestCaseSlice"
import { fetchAllTeamsDetailsUserAssigneeList } from "store/actions/UserActions"

const FilterTestCase = props => {
  const { isFilterFormOpen, toggle, onSubmitFilterFrom } = props
  const dispatch = useDispatch()
  const { testCaseFilterFrom } = useSelector(state => state.TestCase)
  const globalProject = useSelector(
    state => state.globalProjectSelect.selectetProject
  )

  const formik = useFormik({
    initialValues: testCaseFilterFrom,
    enableReinitialize: true,
    onSubmit: async values => {
      try {
        if (onSubmitFilterFrom) {
          await onSubmitFilterFrom(values)
        }
        dispatch(setTestCaseFilterFrom(values))
      } catch (error) {
        console.error("Error during form submission:", error)
      }
    },
  })

  const [buildOptions, setBuildOptions] = useState([])
  const [moduleOptions, setModuleOptions] = useState([])
  const [requirementOptions, setRequirementOptions] = useState([])
  const [createdByValue, setCreatedByValue] = useState([])
  const [approvalRequestOptionsValue, setApprovalRequestOptionsValue] =
    useState([])
  const [assignToOptionsValue, setAssignToOptionsValue] = useState([])
  const [testScenariosIDsValue, setTestScenariosIDsValue] = useState([])

  const layoutModeType = useSelector(state => state.Layout.layoutModeType)

  const customStyles = {
    control: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    menu: provided => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#2a3042" : "#fff",
    }),
    singleValue: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000",
    }),
    placeholder: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#888" : "#888",
    }),
    input: provided => ({
      ...provided,
      color: layoutModeType === "dark" ? "#fff" : "#000", // Ensure input text is visible
    }),
    menuList: provided => ({
      ...provided,
      maxHeight: 150, // Set max height for dropdown
      overflowY: "auto", // Enable vertical scroll
      zIndex: 1000, // Ensure dropdown appears above other elements
    }),
  }

  const handleClearFilters = () => {
    formik.resetForm()
    dispatch(clearFilters())
  }
  const selectedProject = JSON.parse(localStorage.getItem("selectedProject"))

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const responses = await Promise.all([
          dispatch(
            fetchAllBuildsList({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          dispatch(
            fetchAllModulesList({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          dispatch(
            fetchAllRequirementsList({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          dispatch(
            fetchAllTeamsDetailsUserAssigneeList({
              project_id: globalProject?.value || selectedProject?.value,
            })
          ),
          // dispatch(
          //   fetchAllUsersDetailsList({ project_id: globalProject?.value })
          // ),
          // dispatch(fetchUsersList({ project_id: globalProject?.value })),
          dispatch(
            fetchAllTestScenarioList({ project_id: globalProject?.value })
          ),
        ])

        const [
          buildResponse,
          moduleResponse,
          reqResponse,
          userListResponse,
          // userTeamDetailsResponse,
          testScenarioIdsResponse,
        ] = responses

        const buildOptions = buildResponse?.map(entry => ({
          value: Math.round(entry.id),
          label: entry.build_id,
        }))

        const moduleOptions = moduleResponse?.map(entry => ({
          value: entry.id,
          label: entry.module_name,
        }))

        const reqOptions = reqResponse?.map(entry => ({
          value: entry.id,
          label: entry.requirement_title,
        }))

        // const createdByOptions = []
        // const approvalRequestOptions = []
        // const assignToOptions = []
        const createdByOptions = userListResponse?.results?.map(entry => ({
          value: entry.id,
          label: entry.user_name,
        }))
        const approvalRequestOptions = userListResponse?.results?.map(
          entry => ({
            value: entry.id,
            label: entry.user_name,
          })
        )
        const assignToOptions = userListResponse?.results?.map(entry => ({
          value: entry.id,
          label: entry.user_name,
        }))

        // userListResponse?.forEach(entry => {
        //   createdByOptions.push({
        //     value: entry.id,
        //     label: entry.name,
        //   })
        // })
        // userTeamDetailsResponse?.forEach(entry => {
        //   approvalRequestOptions.push({
        //     value: entry.id,
        //     label: entry.user_name,
        //   })
        //   assignToOptions.push({
        //     value: entry.id,
        //     label: entry.user_name,
        //   })
        // })
        const testScenariosIDsOptions = testScenarioIdsResponse?.map(entry => ({
          value: entry.id,
          label: entry.test_scenario_id,
        }))
        setBuildOptions(buildOptions)
        setModuleOptions(moduleOptions)
        setRequirementOptions(reqOptions)
        setCreatedByValue(createdByOptions)
        setApprovalRequestOptionsValue(approvalRequestOptions)
        setAssignToOptionsValue(assignToOptions)
        setTestScenariosIDsValue(testScenariosIDsOptions)
      } catch (error) {
        console.error(error)
      }
    }

    fetchOptions()
  }, [isFilterFormOpen, globalProject, dispatch])

  const [testingTypeOptions, setTestingTypeOptions] = useState([])
  const [testStatusOptions, setTestStatusOptions] = useState([])
  const [executionOptions, setExecutionOptions] = useState([])
  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const bodyOption = { project_id: globalProject?.value }
        const metaDataResponse = await dispatch(getTagsStautusAPI(bodyOption))

        const { Testing_Type, Test_Status, Execution_Type } =
          metaDataResponse?.data || {}

        setTestStatusOptions(createOptions(Test_Status || []))
        setExecutionOptions(createOptions(Execution_Type || []))
        setTestingTypeOptions(createOptions(Testing_Type || []))
      } catch (error) {
        console.log(error)
      }
    }

    fetchOptions()
  }, [isFilterFormOpen, globalProject?.value, dispatch])

  useEffect(() => {
    if (isFilterFormOpen) {
      formik.resetForm({ values: testCaseFilterFrom })
    }
  }, [isFilterFormOpen, testCaseFilterFrom])
  // Dynamically update form values when `isFilterFormOpen` is true
  useEffect(() => {
    if (isFilterFormOpen) {
      dispatch(setTestCaseFilterFrom(testCaseFilterFrom))
    }
  }, [isFilterFormOpen, dispatch, testCaseFilterFrom])
  return (
    <Offcanvas isOpen={isFilterFormOpen} direction="end" toggle={toggle}>
      <OffcanvasHeader toggle={toggle}>Filter Test Case</OffcanvasHeader>
      <OffcanvasBody>
        <form onSubmit={formik.handleSubmit}>
          <div className="mb-3">
            <Label>Build</Label>
            <Select
              value={
                buildOptions.find(
                  option => option.value === formik.values.filter_build
                ) || null
              }
              onChange={option =>
                formik.setFieldValue(
                  "filter_build",
                  option ? option.value : null
                )
              }
              options={buildOptions}
              styles={customStyles}
              isClearable
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>
          <div className="mb-3">
            <Label>Module</Label>
            <Select
              value={
                moduleOptions.find(
                  option => option.value === formik.values.filter_module
                ) || null
              }
              onChange={option =>
                formik.setFieldValue(
                  "filter_module",
                  option ? option.value : null
                )
              }
              options={moduleOptions}
              styles={customStyles}
              isClearable
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>
          <div className="mb-3">
            <Label>Requirement</Label>
            <Select
              value={
                requirementOptions.find(
                  option => option.value === formik.values.filter_requirement
                ) || null
              }
              onChange={option =>
                formik.setFieldValue(
                  "filter_requirement",
                  option ? option.value : null
                )
              }
              options={requirementOptions}
              styles={customStyles}
              isClearable
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>
          <div className="mb-3">
            <Label>Test Scenario ID</Label>
            <Select
              value={
                testScenariosIDsValue.find(
                  option =>
                    option.value === formik.values.filter_test_scenario_id
                ) || null
              }
              onChange={option =>
                formik.setFieldValue(
                  "filter_test_scenario_id",
                  option ? option.value : null
                )
              }
              options={testScenariosIDsValue}
              styles={customStyles}
              isClearable
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>
          <div className="mb-3">
            <Label>Status</Label>
            <Select
              value={
                testStatusOptions.find(
                  option => option.value === formik.values.filter_test_status
                ) || null
              }
              onChange={option =>
                formik.setFieldValue(
                  "filter_test_status",
                  option ? option.value : null
                )
              }
              options={testStatusOptions}
              styles={customStyles}
              isClearable
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>
          <div className="mb-3">
            <Label>Testing Type</Label>
            <Select
              value={
                testingTypeOptions.find(
                  option => option.value === formik.values.filter_testing_type
                ) || null
              }
              onChange={option =>
                formik.setFieldValue(
                  "filter_testing_type",
                  option ? option.value : null
                )
              }
              options={testingTypeOptions}
              styles={customStyles}
              isClearable
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>
          <div className="mb-3">
            <Label>Execution type</Label>
            <Select
              value={
                executionOptions.find(
                  option => option.value === formik.values.filter_execution_type
                ) || null
              }
              onChange={option =>
                formik.setFieldValue(
                  "filter_execution_type",
                  option ? option.value : null
                )
              }
              options={executionOptions}
              styles={customStyles}
              isClearable
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>
          <div className="mb-3">
            <Label>Approval Requested</Label>
            <Select
              value={
                approvalRequestOptionsValue.find(
                  option => option.value === formik.values.filter_approval_from
                ) || null
              }
              onChange={option =>
                formik.setFieldValue(
                  "filter_approval_from",
                  option ? option.value : null
                )
              }
              options={approvalRequestOptionsValue}
              styles={customStyles}
              isClearable
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>
          <div className="mb-3">
            <Label>Created By</Label>
            <Select
              value={
                createdByValue.find(
                  option => option.value === formik.values.filter_created_by
                ) || null
              }
              onChange={option =>
                formik.setFieldValue(
                  "filter_created_by",
                  option ? option.value : null
                )
              }
              options={createdByValue}
              styles={customStyles}
              isClearable
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>
          <div className="mb-3">
            <Label>Assign to</Label>
            <Select
              value={
                assignToOptionsValue.find(
                  option => option.value === formik.values.filter_assign_to
                ) || null
              }
              onChange={option =>
                formik.setFieldValue(
                  "filter_assign_to",
                  option ? option.value : null
                )
              }
              options={assignToOptionsValue}
              styles={customStyles}
              isClearable
              maxMenuHeight={200}
              className="select2-selection"
            />
          </div>
          <div className="d-flex justify-content-between mt-5">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleClearFilters}
            >
              Clear All
            </button>
            <button type="submit" className="btn btn-primary">
              Apply
            </button>
          </div>
        </form>
      </OffcanvasBody>
    </Offcanvas>
  )
}

export default FilterTestCase
