import Breadcrumb from "components/Common/Breadcrumb"
import { getStatusBackGroundColor } from "helpers/helper"
import moment from "moment"
import React, { useEffect } from "react"
import { useSelector } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
} from "reactstrap"
import BASE_URL from "services/apiConfig"

const viewDefect = () => {
  const navigate = useNavigate()

  // Access layout mode type from Redux to toggle between dark and light modes
  const layoutModeType = useSelector(state => state.Layout.layoutModeType)

  // Dynamic chart colors based on mode
  const isDarkMode = layoutModeType === "dark"

  const breadcrumbItems = [
    { label: "Defect", path: "/DefectManagement" },
    {
      label: "Defect Details",
      path: "/",
    },
  ]
  const singleDefectData = useSelector(
    state => state?.Defects?.singleDefectData
  )

  useEffect(() => {
    if (!singleDefectData) {
      navigate("/DefectManagement")
    }
  }, [])

  const formatFilePath = path => {
    if (path?.includes("/media/media")) {
      return path.replace("media/media", "media")
    }
    return path
  }

  const renderFilePreview = file => {
    const fileExtension = file.split(".").pop().toLowerCase()
    const imageExtensions = ["png", "jpg", "jpeg", "gif"]
    const isImage = imageExtensions?.includes(fileExtension)

    if (isImage) {
      return (
        <img
          style={{ height: "10rem", width: "29rem" }}
          src={`${BASE_URL}/${formatFilePath(file)}`}
        />
      )
    } else {
      return (
        <img
          style={{ height: "1rem", width: "1rem" }}
          src={`${BASE_URL}/${formatFilePath(file)}`}
          alt=""
        />
      )
    }
  }

  const onClickBack = () => {
    navigate("/DefectManagement")
  }
  return (
    <div className="">
      <Container fluid>
        <Breadcrumb title="Defect Details" breadcrumbItems={breadcrumbItems} />
        <div className="d-flex justify-content-end mb-2">
          <Button
            type="button"
            color="primary"
            className="btn-label me-2"
            onClick={onClickBack}
          >
            <i className="bx bx-arrow-back label-icon"></i> Go Back
          </Button>
        </div>
        <Card style={{ minHeight: "400px" }}>
          <CardBody>
            <div className="d-flex flex-column flex-md-row justify-content-between mb-4">
              <div className="d-flex">
                <div className="avatar-xs me-4">
                  <span
                    className="avatar-title bg-primary"
                    style={{ fontSize: "20px", borderRadius: "8px" }}
                  >
                    {singleDefectData?.defect_name?.slice(0, 2).toUpperCase()}
                  </span>
                </div>
                <div className="flex-grow-1 overflow-hidden">
                  <h5 className="font-size-15 d-flex align-items-center flex-wrap">
                    <span
                      style={{
                        maxWidth: "135px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                      className="me-2"
                    >
                      <Link className="text-dark">
                        {singleDefectData?.defect_name || "-"}
                      </Link>
                    </span>
                    {singleDefectData?.defect_id && (
                      <span className="ms-2 d-none d-md-inline">
                        ({singleDefectData?.defect_id})
                      </span>
                    )}
                    {singleDefectData?.defect_id && (
                      <span className="ms-2 d-md-none">
                        {singleDefectData?.defect_id}
                      </span>
                    )}
                  </h5>
                </div>
              </div>
            </div>

            {/* <div className="mb-4">
              <h6 style={{ fontWeight: "550" }}>Defect Description</h6>
              <p
                className="text-muted mb-4"
                dangerouslySetInnerHTML={{
                  __html: singleDefectData?.description
                    ? singleDefectData?.description
                    : "-",
                }}
              ></p>
            </div> */}
            <div>
              <Row>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Project Name</h6>
                  <p className="text-muted ">
                    {singleDefectData?.project_name
                      ? singleDefectData?.project_name
                      : "-"}
                  </p>
                </Col>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Status</h6>
                  <p className="text-muted ">
                    <Badge
                      className={
                        "badge-soft-" +
                        `${getStatusBackGroundColor(
                          singleDefectData?.defect_status
                        )}`
                      }
                      style={{
                        fontSize: "11px", // Increase font size
                        padding: "0.5em", // Increase padding
                        borderRadius: "0.5em", // Adjust border radius
                      }}
                    >
                      {singleDefectData?.defect_status
                        ? singleDefectData?.defect_status
                        : "-"}
                    </Badge>
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Defect Type</h6>
                  <p className="text-muted ">
                    {singleDefectData?.defect_type
                      ? singleDefectData?.defect_type
                      : "-"}
                  </p>
                </Col>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Test Case</h6>
                  <p className="text-muted ">
                    {singleDefectData?.test_case_name
                      ? singleDefectData?.test_case_name
                      : "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Build</h6>
                  <p className="text-muted ">
                    {singleDefectData?.build ? singleDefectData?.build : "-"}
                  </p>
                </Col>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Module</h6>
                  <p className="text-muted ">
                    {singleDefectData?.module_name
                      ? singleDefectData?.module_name
                      : "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Requirement Title</h6>
                  <p className="text-muted ">
                    {singleDefectData?.requirement_title
                      ? singleDefectData?.requirement_title
                      : "-"}
                  </p>
                </Col>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Priority</h6>
                  <p className="text-muted ">
                    {singleDefectData?.defect_priority
                      ? singleDefectData?.defect_priority
                      : "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Severity</h6>
                  <p className="text-muted ">
                    {singleDefectData?.severity
                      ? singleDefectData?.severity
                      : "-"}
                  </p>
                </Col>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Os</h6>
                  <p className="text-muted ">
                    {singleDefectData?.os ? singleDefectData?.os : "-"}
                  </p>
                </Col>
              </Row>
              <Row>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Browser</h6>
                  <p className="text-muted ">
                    {singleDefectData?.browsers
                      ? singleDefectData?.browsers
                      : "-"}
                  </p>
                </Col>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Device</h6>
                  <p className="text-muted ">
                    {singleDefectData?.devices
                      ? singleDefectData?.devices
                      : "-"}
                  </p>
                </Col>
              </Row>

              <Row>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Created At</h6>
                  <p className="text-muted ">
                    {singleDefectData?.created_at
                      ? moment(singleDefectData?.created_at).format(
                          "DD-MM-YYYY"
                        )
                      : "-"}
                  </p>
                </Col>
                <Col sm={6}>
                  <h6 style={{ fontWeight: "550" }}>Defect Viewers</h6>
                  <p className="text-muted ">
                    {singleDefectData?.defect_viewers_name
                      ? singleDefectData?.defect_viewers_name
                      : "-"}
                  </p>
                </Col>
              </Row>
              {singleDefectData?.jira_id && singleDefectData?.jira_url && (
                <Row>
                  <Col sm={6}>
                    <h6 style={{ fontWeight: "550" }}>Jira ID</h6>
                    <a
                      href={singleDefectData?.jira_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {singleDefectData?.jira_id || "-"}
                    </a>
                  </Col>
                  <Col sm={6}></Col>
                </Row>
              )}
              {singleDefectData?.plane_id && singleDefectData?.plane_url && (
                <Row>
                  <Col sm={6}>
                    <h6 style={{ fontWeight: "550" }}>Plane ID</h6>
                    <a
                      href={singleDefectData?.plane_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {singleDefectData?.plane_id || "-"}
                    </a>
                  </Col>
                  <Col sm={6}></Col>
                </Row>
              )}
            </div>
          </CardBody>
        </Card>{" "}
        <Card>
          <CardBody>
            <Row>
              <Col sm={12}>
                <h6 style={{ fontWeight: "550" }}>Assign To</h6>
                <p className="text-muted ">
                  {singleDefectData?.assign_to_name
                    ? singleDefectData?.assign_to_name
                    : "-"}
                </p>
              </Col>
              <Col sm={12}>
                <h6 style={{ fontWeight: "550" }}>Steps To Reproduce</h6>
                <p
                  className="text-muted mb-4"
                  dangerouslySetInnerHTML={{
                    __html: singleDefectData?.steps_to_reproduce
                      ? singleDefectData?.steps_to_reproduce
                      : "-",
                  }}
                ></p>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Row>
              <Col sm={12}>
                <h6 style={{ fontWeight: "550" }}>Actual Result Description</h6>
                <p
                  className="text-muted mb-4"
                  dangerouslySetInnerHTML={{
                    __html: singleDefectData?.actual_result_description
                      ? singleDefectData?.actual_result_description
                      : "-",
                  }}
                ></p>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Row>
              <Col sm={12}>
                <h6 style={{ fontWeight: "550" }}>
                  Expected Result Description
                </h6>
                <p
                  className="text-muted mb-4"
                  dangerouslySetInnerHTML={{
                    __html: singleDefectData?.expected_result_description
                      ? singleDefectData?.expected_result_description
                      : "-",
                  }}
                ></p>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Row>
              <Col sm={12}>
                <h6 style={{ fontWeight: "550" }}>Comments</h6>
                <p
                  className="text-muted mb-4"
                  dangerouslySetInnerHTML={{
                    __html: singleDefectData?.comments
                      ? singleDefectData?.comments
                      : "-",
                  }}
                ></p>
              </Col>
            </Row>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <Row style={{ color: isDarkMode ? "#c3cbe4" : "#333" }}>
              <Col sm={12}>
                <h6 style={{ fontWeight: "550" }}>Attachment</h6>
                {singleDefectData?.attachment &&
                singleDefectData.attachment.some(
                  attachment => attachment !== ""
                ) ? (
                  singleDefectData.attachment.map((attachment, index) => (
                    <a
                      key={index}
                      style={{
                        textDecoration: "none",
                        color: isDarkMode ? "#c3cbe4" : "#333",
                      }}
                      href={`${BASE_URL}/${formatFilePath(attachment.trim())}`} // Use trim() to remove any leading/trailing spaces
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {renderFilePreview(attachment.trim())}
                      <label
                        style={{
                          display: "block",
                          cursor: "pointer",
                          color: isDarkMode ? "#c3cbe4" : "#333",
                        }}
                      >
                        {attachment.trim().split("/").pop()}{" "}
                        {/* Display only the file name */}
                      </label>
                    </a>
                  ))
                ) : (
                  <span style={{ color: isDarkMode ? "#c3cbe4" : "#333" }}>
                    No attachment available
                  </span>
                )}
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </div>
  )
}

export default viewDefect
