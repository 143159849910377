export const TypeOptions = [
  {
    label: "--- Project Management ---",
    options: [
      { value: "Project Type", label: "Project Type" },
      { value: "Project Status", label: "Project Status" }, // This will need color coding based on the status
      { value: "Requirement Type", label: "Requirement Type" },
    ],
  },
  {
    label: "--- Test Management ---",
    options: [
      { value: "Testing Type", label: "Testing Type" },
      { value: "Priority", label: "Priority" },
      // { value: "Execution Type", label: "Execution Type" },
      // { value: "Execution Status", label: "Execution Status" }, // This will need color coding based on the status
    ],
  },
  {
    label: "--- Defect Management ---",
    options: [
      { value: "Defect Status", label: "Defect Status" }, // This will need color coding based on the status
      { value: "Defect Type", label: "Defect Type" },
      { value: "Defect Priority", label: "Defect Priority" },
      { value: "Severity", label: "Severity" },
      { value: "Browser", label: "Browsers" },
      { value: "Device", label: "Device" },
      { value: "OS", label: "OS" },
    ],
  },
]
