import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import {
  Row,
  Col,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"
import httpInjectorService from "services/http-Injector.Service"
import "./Login.scss"

// import images
import logodark from "../../assets/icons/title-logo.png"
// import logolight from "../../assets/icons/Froth_Logo.png";
// import logolight from "../../assets/images/companies/froth_logo_full.png";
import logolight from "../../assets/images/companies/froth_logo_full.png"

import Group from "../../assets/images/users/Group.png"
import CarouselPage from "./CarouselPage"
import adIcon from "../../assets/icons/microsoft.svg"

// redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import { Link, useNavigate } from "react-router-dom"

import { loginUser } from "../../store/auth/login/actions"
import withRouter from "components/Common/withRouter"
// Formik validation
import * as Yup from "yup"
import { useFormik } from "formik"
import Captcha from "components/Captcha/Captcha"
import { useMsal } from "@azure/msal-react"
import { loginRequest } from "../../helpers/ADConfig/msalConfig"
import { toast } from "react-toastify"
import { jwtDecode } from "jwt-decode"
import { setSelectedProject } from "store/Slices/RequirementSlice"
import {
  setProjectListForSelect,
  setSelectetProject,
} from "store/Slices/GlobalSlice"
import { setAllProjectList } from "store/Slices/ProjectsSlice"
import CryptoJS from "crypto-js"

const Login = props => {
  const dispatch = useDispatch()
  const { instance } = useMsal()
  const hostname = window.location.hostname
  // const { instance } = useMsal();
  const [passwordShow, setPasswordShow] = useState(false)
  const [rememberMe, setRememberMe] = useState(false)
  const [captchaRendered] = useState(hostname !== "uatrvrd.frothtestops.com")
  const [numbers, setNumbers] = useState([])
  const [isAscending, setIsAscending] = useState(true)
  const globalProject = useSelector(
    state => state.globalProjectSelect.selectetProject
  )
  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe)
  }
  const loading = useSelector(state => state?.Login?.loading)

  const SECRET_KEY = "froth-testops@rd.com"

  const encryptData = data => {
    return CryptoJS.AES.encrypt(data, SECRET_KEY).toString()
  }

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .test(
          "email-or-username",
          "Invalid email or username format",
          function (value) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
            const usernameRegex = /^[a-zA-Z0-9_.]+$/ // adjust this regex according to the allowed username format
            return emailRegex.test(value) || usernameRegex.test(value)
          }
        )
        .required("Email/Username required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(
          /[!@#$%^&*(),.?":{}|<>]/,
          "Password must contain at least one special character"
        )
        .required("Please Enter Your New Password"),
    }),
  })

  const handleLoginClick = e => {
    e.preventDefault()
    validation.handleSubmit()
    const values = validation.values
    const encryptedPassword = encryptData(values.password)
    console.log(encryptedPassword)
    const formData = new FormData()
    formData.append("email_or_username", values.email)
    formData.append("password", values.password)
    formData.append("remember_me", rememberMe)
    formData.append("is_ad_user", false)
    if (values.email && values.password) {
      dispatch(loginUser(formData, props.router.navigate))
    }
  }

  useEffect(() => {
    instance
      .handleRedirectPromise()
      .then(response => {
        if (response) {
          const decodedToken = jwtDecode(response.accessToken)
          const username = decodedToken?.unique_name
          handleAzure(username) // Process the AD login
          localStorage.clear()
        }
      })
      .catch(error => {
        console.error("Redirect login failed:", error)
      })
  }, [instance])

  const handleAdClick = event => {
    event.preventDefault()

    instance
      .loginRedirect(loginRequest)
      .then(response => {
        if (response?.accessToken) {
          localStorage.clear()
        } else {
          toast.warn("Failed to login, try again later", {
            position: "top-right",
            autoClose: 3000,
          })
        }
      })
      .catch(error => {
        console.error("Login error:", error)
        toast.error(`Login failed: ${error.message}`, {
          position: "top-right",
          autoClose: 3000,
        })
      })
  }

  const handleAzure = async username => {
    const password = "SlMzY3VyMyFQQHNzdzByZDIwMjQ="
    const encryptedPassword = encryptData(password)
    console.log(encryptedPassword)
    const formData = new FormData()
    formData.append("email_or_username", username)
    formData.append("password", process.env.REACT_APP_AZURE)
    formData.append("remember_me", false)
    formData.append("is_ad_user", true)

    // Dispatch the action with the correct payload
    try {
      const payload = {
        user: formData,
        history: props.router.navigate, // Passing the navigation function
      }

      const res = await dispatch({
        type: "AD_LOGIN_USER", // This triggers the saga
        payload,
      })

      console.log("Login response:", res)
    } catch (err) {
      console.log("Login error:", err)
    }
  }

  const LoginProperties = createSelector(
    state => state.Login,
    login => ({
      error: login.error,
    })
  )

  const { error } = useSelector(LoginProperties)
  useEffect(() => {
    dispatch(setSelectetProject(null))
    dispatch(setProjectListForSelect([]))
  }, [])

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col xl={8} className="carousel-hide">
              <CarouselPage />
            </Col>
            <Col xl={4}>
              <div className="auth-full-page-content p-md-4 p-2">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div
                      style={{
                        marginLeft: "-24px",
                        marginTop: "-18px",
                        marginBottom: "-15px",
                      }}
                    >
                      <Link to="/dashboard" className="d-block card-logo">
                        <img
                          src={logolight}
                          alt=""
                          height="50"
                          className="logo-dark-element"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="40"
                          className="logo-light-element"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div className="text-center">
                        {/* <img src={Group} alt="" height="90" className="float-center" /> */}
                        <i
                          className="mdi mdi-account-circle text-primary"
                          style={{ fontSize: "50px" }}
                        />

                        <h3>Welcome Back!</h3>
                        <h4>Good to see you again</h4>
                        <p className="text-muted">
                          Log in to continue with us.
                        </p>
                      </div>
                      <div className="mt-4">
                        <Form className="form-horizontal">
                          {error ? <Alert color="danger">{error}</Alert> : null}
                          <div className="mb-3">
                            <Label className="form-label">
                              Email/Username
                              <span className="text-danger">*</span>
                            </Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter Email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">
                              Password<span className="text-danger">*</span>
                            </Label>
                            <div className="input-group auth-pass-inputgroup">
                              <Input
                                name="password"
                                value={validation.values.password || ""}
                                type={passwordShow ? "text" : "password"}
                                placeholder="Enter Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.password &&
                                  validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                              <button
                                onClick={() => setPasswordShow(!passwordShow)}
                                className="btn btn-light"
                                type="button"
                                id="password-addon"
                                style={{ border: "1px solid lightgray" }}
                              >
                                {passwordShow ? (
                                  <i className="mdi mdi-eye-off-outline"></i>
                                ) : (
                                  <i className="mdi mdi-eye-outline"></i>
                                )}
                              </button>

                              {validation.touched.password &&
                              validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </div>
                          {captchaRendered && (
                            <Captcha
                              numbers={numbers}
                              setNumbers={setNumbers}
                              isAscending={isAscending}
                              setIsAscending={setIsAscending}
                            />
                          )}
                          <div className="form-check mt-1">
                            <Input
                              type="checkbox"
                              id="auth-remember-check"
                              checked={rememberMe}
                              onChange={handleRememberMeChange}
                              onClick={handleRememberMeChange}
                              className="form-check-input"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="auth-remember-check"
                            >
                              Keep me logged in
                            </label>
                          </div>
                          <div className="mt-2 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                              disabled={
                                loading ||
                                (captchaRendered ? !isAscending : false)
                              }
                              onClick={handleLoginClick}
                            >
                              {loading ? "Loading..." : "Submit"}
                            </button>
                          </div>
                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-outline-primary btn-block ad-btn"
                              disabled={
                                loading ||
                                (captchaRendered ? !isAscending : false)
                              }
                              onClick={handleAdClick}
                            >
                              <img
                                src={adIcon}
                                sizes="1"
                                style={{ height: "0.7rem" }}
                              />
                              Azure AD
                            </button>
                          </div>
                        </Form>
                        <div className="mt-4 mb-2 text-center">
                          <Link to="/forgot-password" className="text-muted">
                            <i className="mdi mdi-lock me-1" />
                            Forgot your password?
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
